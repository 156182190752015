import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PedidoService } from '@app/_services/pedido/pedido.service';
import { Router } from '@angular/router';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import Swal from 'sweetalert2';
import { NovoPedido } from '@app/_models/pedido/novo-pedido';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMensagemComponent } from '@app/_components/compartilhados/modal-mensagem/modal-mensagem.component';
import { error } from 'console';

@Component({
  selector: 'app-modal-pix',
  templateUrl: './modal-pix.component.html',
  styleUrls: ['./modal-pix.component.scss']
})
export class ModalPixComponent implements OnInit {

    @Input() pix: any;
    qrCode;
    tempoDecorrido = 100;
    expirado = false;
    displayTempoEspera: any;
    tempoExpiracaoFormatado: any;
    timerDisplay: any;
    timerProgressBar: any;
    intervalPoolingPayment: any;
    intervalPoolingOrder: any;
    pedido = new NovoPedido();

    constructor(
        private toastr: ToastrService,
        public activeModal: NgbActiveModal,
        public pedidoService: PedidoService,
        private router: Router,
        private sacolaService: SacolaService,
        private modalService: NgbModal,
    ) {
    }

    ngOnInit(): void {
        this.centralizaFuncoesIniciais();
    }

    centralizaFuncoesIniciais(){
        this.qrCode = "data:image/png;base64," + this.pix.QRCodeBase64;
        this.iniciaProgressBar(this.pix.ExpiracaoQRCode);
        this.iniciaTimer(this.pix.ExpiracaoQRCode);

        setTimeout(() => {
            this.verificarPagamento();
        }, 20000);
    }

    iniciaProgressBar(seconds: number){
        this.tempoDecorrido = 100;
        let porcentagem = 100/seconds
        this.timerProgressBar = setInterval(() => {
            if (this.tempoDecorrido > 0){
                this.tempoDecorrido = this.tempoDecorrido - porcentagem;
            } else {
                this.displayTempoEspera = this.tempoExpiracaoFormatado;
                this.limparIntervalos();

                Swal.fire({
                    title: 'Tempo expirado!',
                    text: 'O QR code de pagamento expirou.',
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonText: 'Regerar QR Code',
                    cancelButtonText: 'Cancelar',
                }).then((result) => {
                    if(result.isConfirmed) {
                        this.pedidoService.criarPagamento(this.pedido).subscribe(e => {
                            this.pix = e;
                            this.centralizaFuncoesIniciais();
                        })
                    } else {
                        this.activeModal.close();
                    }
                })
            }
        }, 1000);
    }

    iniciaTimer(seconds: number){
        let minute: number = Math.floor(seconds / 60);
        let textSec: any = '0';
        let statSec: number = 60;
        
        const prefix = minute < 10 ? '0' : '';
        this.tempoExpiracaoFormatado = `${prefix}${Math.floor(seconds / 60)}:00`;

        this.timerDisplay = setInterval(() => {
            seconds--;
            if (statSec != 0) statSec--;
            else statSec = 59;
        
            if (statSec < 10) {
                textSec = '0' + statSec;
            } else textSec = statSec;

            if(seconds == 0){
                clearInterval(this.timerDisplay);
            }
    
            this.displayTempoEspera = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

        }, 1000);
    }

    informarPagamento() {
        this.pedidoService.consultarPagamento(this.pix.CodTransacao).subscribe(e => {
            if(e.Estado == 'APPROVED'){
                this.activeModal.close();
                Swal.fire({
                    title: 'Pagamento aprovado!',
                    text: 'Pagamento realizado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    this.sacolaService.pedidoRealizado();
                    this.consultaPedido();
                })

                this.limparIntervalos();
            } else {
                this.toastr.warning("Pagamento não identificado.", "Não foi possível identificar a realização do pagamento.", {
                    positionClass: 'toast-bottom-left',
                });
            }
        });
    }

    verificarPagamento(){
        this.intervalPoolingPayment = setInterval(() => {
            this.pedidoService.consultarPagamento(this.pix.CodTransacao).subscribe(e => {
                if(e.Estado == 'APPROVED'){
                    this.activeModal.close();
                    Swal.fire({
                        title: 'Pagamento aprovado!',
                        text: 'Pagamento realizado com sucesso.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    }).then(() => {
                        this.sacolaService.pedidoRealizado();
                        this.consultaPedido();
                    })
                    
                    this.limparIntervalos();
                }
            });
        }, 10000);
    }

    consultaPedido() {
        var aguarde;

        document.body.style.position = 'fixed';
        aguarde = this.modalService.open(ModalMensagemComponent, {
            backdrop: 'static',
            keyboard: false
        });
        aguarde.componentInstance.titulo = 'Finalizando pedido';
        aguarde.componentInstance.centralizarTexto = true;
        aguarde.componentInstance.mensagem = 'Aguarde, estamos finalizando seu pedido!';
        aguarde.componentInstance.mostrarBotaoConfirmar = false;
        aguarde.componentInstance.imagem = "/assets/images/preparando-pedido.svg";

        this.intervalPoolingOrder = setInterval(() => {
            this.pedidoService.retornaInformacoesPedido(this.pedido.Codigo).subscribe(e => {
                if(e.Estado){
                    aguarde.close();
                    document.body.style.position = '';
                    this.router.navigate(['/acompanhamento/', this.pedido.Codigo]);
                    this.limparIntervalos();
                }
            });
        }, 5000);

    }

    copiado() {
        this.toastr.success("Copiado para área de transferencia.", "Success", {
            positionClass: 'toast-bottom-left',
        });
    }

    limparIntervalos(){
        clearInterval(this.timerDisplay);
        clearInterval(this.timerProgressBar);
        clearInterval(this.intervalPoolingOrder);
        clearInterval(this.intervalPoolingPayment);
    }   

    fecharModal() {
        this.activeModal.close();
        this.limparIntervalos();    
    }
}
