import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { LoginService as loginService } from '@app/_services/login/login.service';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { LogarCliente } from '@app/_models/cliente/logar-clients';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalAlertService } from '@app/_services/base/alert.service';
import { NomeValidator } from '@app/_validators/nome-validator';
import { CpfCnpjValidator } from '@app/_validators/cpfCnpj-validator';
import { CriptografaSenha } from '@app/_utills/criptografa-senha';
import { OTP } from '@app/_models/cliente/otp';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOtpComponent } from '../modal-otp/modal-otp.component';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss'],
})
export class CadastroComponent implements OnInit {
  form: UntypedFormGroup;
  loading = false;
  submitted = false;
  mascaraCelular = '(00) 0000-00009';
  senhaInvalida = undefined;
  identificacao: string;

  cliente;
  erro;
  cpfCnpjMask: string = '00.000.000/0000-00';
  mostrarSenha = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private clienteService: ClienteService,
    private LoginService: loginService,
    private modalAlertaService: ModalAlertService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) {
    if (
      this.route.snapshot.queryParams &&
      this.route.snapshot.queryParams.identificacao
    ) {
      this.identificacao = this.route.snapshot.queryParams.identificacao;
    }
  }

  ngOnInit() {
    this.criaForm();
    this.atualizaMascaraCelular();
    this.atualizaMascaraCnpj();

    if (this.identificacao) {
      this.identificacao.includes('@')
        ? this.form.get('Email').setValue(this.identificacao)
        : this.form.get('Celular').setValue(this.identificacao);
    }
  }

  private criaForm() {
    this.form = this.formBuilder.group({
      Nome: [undefined, [Validators.required, NomeValidator()]],
      Email: [undefined, [Validators.required, Validators.email]],
      Senha: [undefined, [Validators.required]],
      confirmarSenha: [undefined, [Validators.required]],
      Celular: [undefined],
      UsuarioCriacao: ['API Cardápio'],
      CPFCNPJ: ['', [CpfCnpjValidator.validate]],
    });
  }

  private atualizaMascaraCnpj() {
    this.form.get('CPFCNPJ').valueChanges.subscribe((e) => {
      this.cpfCnpjMask =
        e && e.length <= 11 ? '000.000.000-000' : '00.000.000/0000-00';
    });
  }

  atualizaMascaraCelular() {
    this.form
      .get('Celular')
      .valueChanges.subscribe(
        (e) =>
          (this.mascaraCelular =
            e.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000')
      );
  }

  validaSenha() {
    const senha = this.form.get('Senha');
    const confirmarSenha = this.form.get('confirmarSenha');

    if (senha.value && senha.value.length < 8) {
      senha.setErrors({ incorrect: true });
      this.senhaInvalida = 'A senha deve ter no mínimo 8 caracteres';
    } else if (senha.value !== confirmarSenha.value) {
      if (confirmarSenha.value) {
        confirmarSenha.setErrors({ incorrect: true });
        senha.setErrors({ incorrect: true });
        this.senhaInvalida = 'As senhas não são iguais!';
      }
    } else {
      if (confirmarSenha.value && senha.value) {
        this.senhaInvalida = undefined;
        senha.setErrors(null);
        confirmarSenha.setErrors(null);
      }
    }
  }

  cadastrar() {
    this.cliente = this.form.getRawValue();

    const senha = new CriptografaSenha().criptografaSenha(this.cliente.Senha);
    this.cliente.Senha = senha;

    this.cliente = {
      Nome: this.cliente.Nome,
      Email: this.cliente.Email,
      Senha: this.cliente.Senha,
      Celular: this.cliente.Celular,
      UsuarioCriacao: this.cliente.UsuarioCriacao,
      CPFCNPJ: this.cliente.CPFCNPJ,
    };

    this.clienteService
      .insere(this.cliente)
      .pipe(
        switchMap((e) => {
          var loginCliente = new LogarCliente(
            this.cliente.Email,
            this.cliente.Senha
          );
          return this.LoginService.loginCliente(loginCliente);
        })
      )
      .subscribe(
        (e) => {
          // Envia o SMS para verificação da conta.
          const dadosValidacaoCelular: OTP = {
            Numero: this.cliente.Celular,
            Token: '',
          };
          this.clienteService
            .enviarSMSParaVerificacaoIdentidade(dadosValidacaoCelular)
            .subscribe();

          // Redireciona para a home
          this.router.navigate([''], {});

          // Exibe a modal OTP
          document.body.style.position = 'fixed';

          const modalRef = this.modalService.open(ModalOtpComponent);
          modalRef.componentInstance.numeroCelular = this.cliente.Celular;

          modalRef.result.then((e) => {
            document.body.style.position = '';
          }).catch((e) => {
            document.body.style.position = '';
          });
          
        },
        (err) => (this.erro = err.replace('<Aviso>', ''))
      );
  }

  abrirTelaLogin() {
    this.router.navigate(['/login']);
  }

  alterarVisibildadeSenha() {
    this.mostrarSenha = !this.mostrarSenha;
  }

  get lojaLogada() {
    return this.LoginService.lojaLogada;
  }

  get logo() {
    if (this.lojaLogada && this.lojaLogada.ImagemLogo) {
      let imagem = this.lojaLogada.ImagemLogo;
      imagem = 'data:image/png;base64,' + encodeURI(imagem);
      return this.sanitizer.bypassSecurityTrustUrl(imagem);
    }
    return '';
  }

  get podeCadastrar() {
    return this.form.valid;
  }

  get nome() {
    return this.form.get('Nome');
  }
  get senha() {
    return this.form.get('Senha');
  }
  get email() {
    return this.form.get('Email');
  }
  get cpfCnpj() {
    return this.form.get('CPFCNPJ');
  }
}
