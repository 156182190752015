import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { LoginService } from '@app/_services/login/login.service';
import { Endereco } from '@app/_models/endereco/endereco';
import { ModalLoginComponent } from '../../modal-login/modal-login.component';
import { ModalAlertService } from '@app/_services/base/alert.service';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { Estado } from '@app/_models/endereco/estado';
import { DneService } from '@app/_services/dne/dne.service';
import { MapMarker  } from '@angular/google-maps';
import { Cidade } from '@app/_models/endereco/cidade';
import { TipoEndereco } from '@app/_models/endereco/tipo-endereco';
import { DetalhesCliente } from '@app/_models/cliente/detalhes-cliente';
import { MatStepper } from '@angular/material/stepper';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-modal-cadastro-endereco',
  templateUrl: './modal-cadastro-endereco.component.html',
  styleUrls: ['./modal-cadastro-endereco.component.scss']
})
export class ModalCadastroEnderecoComponent implements OnInit, AfterViewInit {
   
    firstFormGroup: UntypedFormGroup;
    secondFormGroup: UntypedFormGroup;
    thirdFormGroup: UntypedFormGroup;
    fourthFormGroup: UntypedFormGroup;
    isEditable = false;
    enderecosCadastrados: Endereco[];
    estaAtualizando = false;
    dataAtual: Date;
    restaurante: DetalhesLoja;
    coordenadas: google.maps.LatLngLiteral;
    endereco: Endereco;
    geocodeResult: any;
    codEstado: number;
    estado: string;
    estados: Estado[] = [];
    center: google.maps.LatLngLiteral;
    mapOptions: google.maps.MapOptions;
    markerOptions: google.maps.MarkerOptions;
    tipoSelecionado = false;
    cidades: Cidade[] = [];
    tipos: TipoEndereco[] = [];
    cliente: DetalhesCliente;
    formattedAdress: String;
    btn_voltar = true;
    favorito = false;
    enderecoEditar: Endereco;
    step: number;
    tituloModal = "Método de entrega";
    entregaSelecionada = false;
    removePadding = false;
    removePaddingMobile = false;
    enderecoSelecionado = false;

    @ViewChild("mapSearchField") searchField: ElementRef;
    @ViewChild('map') mapElement: ElementRef;
    @ViewChild('marker', { read: MapMarker }) marker: MapMarker;
    @ViewChild('stepper') stepper: MatStepper;

    constructor(
      private formBuilder: UntypedFormBuilder,
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private clienteService: ClienteService,
      private loginService: LoginService,
      private alertaService: ModalAlertService,
      private sacolaService: SacolaService,
      private dneEnderecoService: DneService,
      private changeDetectorRef: ChangeDetectorRef,
    ) {
      if(this.usuarioLogado){
        this.listaEnderecos();
      }

      this.loginService.cliente.subscribe(cliente => {
        this.cliente = cliente;
      });

      this.restaurante = loginService.lojaLogada;
    }

    ngOnInit(): void {
      this.firstFormGroup = this.formBuilder.group({
        firstCtrl: ['',]
      });
      this.secondFormGroup = this.formBuilder.group({
        secondCtrl: ['',]
      });
      this.thirdFormGroup = this.formBuilder.group({
        thirdCtrl: ['',]
      });
      this.fourthFormGroup = this.formBuilder.group({
        Complemento: [],
        Numero: [],
        Rua: [],
        Bairro: [],
        CodTipo: [""],
        Principal: []
      });

      this.buscarTodosEstados();

      this.mapOptions = {
        center: this.center,
        zoom: 18,
        mapTypeControl: true,
        streetViewControl: true,
      };
  
      const screenWidth = window.innerWidth;
  
      if (screenWidth < 600) {
        this.mapOptions.zoom = 16;
      }
      
      this.markerOptions = {
        draggable: true,
        position: this.center,
      }

      this.listaTiposEndereco();
    }

    ngAfterViewInit(): void {
      const autocomplete = new google.maps.places.Autocomplete(this.searchField.nativeElement);
      autocomplete.setComponentRestrictions({ country: 'BR' });

      this.searchField.nativeElement.addEventListener('focus', () => {
        window.scrollTo(0, this.searchField.nativeElement.offsetTop);
      });
      
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (!place || !place.geometry) {
          return;
        }
      
        this.geocodeResult = place;
        this.formattedAdress = place.formatted_address;
        this.endereco = new Endereco();
        this.converterEndereco();
        this.changeDetectorRef.detectChanges();
      });

      if (this.step == 1){
        this.stepper.selectedIndex = 1;
      }
    }

    converterEndereco(){
      const location = this.geocodeResult.geometry.location;

      this.center = {
        lat: location.lat(),
        lng: location.lng(),
      };

      this.geocodeResult.address_components?.forEach(component => {
        switch (component.types[0]){
          case 'street_number':
            this.endereco.Numero = component.long_name;
          break;
          case 'route':
            this.endereco.Rua = component.long_name;
          break;
          case 'sublocality_level_1':
          case 'political':
            this.endereco.Bairro = component.long_name;
          break;
          case 'administrative_area_level_1':
            this.estado = component.short_name;
            this.codEstado = this.estados.find(e => e.Nome == component.long_name).Codigo;
          break;
          case 'administrative_area_level_2':
            this.endereco.Municipio = component.long_name;
          break;
          case 'postal_code':
            this.endereco.Cep = component.long_name.slice(0, 5) + component.long_name.slice(6);
          break;
          default:
          break;
        }
      });
    }

    converterLocalizacao(posicao: google.maps.LatLng | google.maps.LatLngLiteral){
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
  
        var geocodeRequest = {
          location: posicao
        };
  
        geocoder.geocode(geocodeRequest, function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
  
              var retorno = results[0];
              resolve(retorno);
              
            } else {
              reject('Nenhum resultado encontrado');
            }
          } else {
            reject('Falha na geocodificação devido a: ' + status);
          }
        });
      });
    }

    fecharModal() {
      this.activeModal.close();
    }

    voltarModal(stepper: MatStepper){
      stepper.previous();
      switch( stepper.selectedIndex ){
        case 0:
          this.tituloModal = "Método de entrega";
        break;
        case 1:
          this.tituloModal = "Endereço de entrega";
          this.removePadding = false;
          this.removePaddingMobile = false;
        break;
        case 2:
          const screenWidth = window.innerWidth;

          if (screenWidth < 600) {
            this.removePaddingMobile = true;
            this.tituloModal = (this.endereco.Rua ? this.endereco.Rua : "") + ( this.endereco.Numero ? ", " + this.endereco.Numero : "") + (!this.endereco.Rua ? this.endereco.Municipio : "");
          } else {
            this.tituloModal = this.formattedAdress.toString();
          }
          this.removePadding = true;
        break;
      }
    }

    abrirModalLogin(): void {
      this.modalService.dismissAll();

      document.body.style.position = 'fixed';
      
      const modalRef = this.modalService.open(ModalLoginComponent);

      modalRef.result.then((e) => {
        document.body.style.position = '';
      }).catch((e) => {
        document.body.style.position = '';
      });
    }

    selecionarEntrega(stepper: MatStepper){
      this.entregaSelecionada = true;
      setTimeout(()=>{                           
        stepper.next();
      }, 250);
      this.tituloModal = "Endereço de entrega";
    }

    selecionarRetidada(){
      this.entregaSelecionada = false;
      this.sacolaService.alterarFormaRetirada(FormaRetiradaPedido.Balcao);
      this.sacolaService.taxaEntrega = 0;
      this.sacolaService.atualizarTaxaEntrega();
      this.sacolaService.pedidoSubject.value.entrega.Distancia = null
      this.sacolaService.pedidoSubject.value.entrega.DataEntrega = this.dataAtual;
      this.fecharModal();
    }

    selecionarEndereco(endereco: Endereco) {
      this.removeEnderecoSelecionado();
      this.estaAtualizando = true;
      document.getElementById('endereco_' + endereco.Codigo).classList.add("selecionado");

      setTimeout(()=>{                           
        this.salvarEndereco(endereco);
      }, 250);
    }

    selecionarNovoEndereco(stepper: MatStepper){
      this.enderecoSelecionado = true;     
      setTimeout(()=>{                           
        stepper.next();
        this.changeDetectorRef.detectChanges();
      }, 100);        
      
      const screenWidth = window.innerWidth;

      if (screenWidth < 600) {
        this.removePaddingMobile = true;
        this.tituloModal = (this.endereco.Rua ? this.endereco.Rua : "") + ( this.endereco.Numero ? ", " + this.endereco.Numero : "") + (!this.endereco.Rua ? this.endereco.Municipio : "");
      } else {
        this.tituloModal = this.formattedAdress.toString();
      }

      this.removePadding = true;
    }

    selecionarLocalizacao(stepper: MatStepper){
      
      this.endereco = new Endereco();

      this.center = {
        lat: this.localizacao.lat,
        lng: this.localizacao.lng,
      };

      this.enderecoSelecionado = true;     
      setTimeout(()=>{                           
        stepper.next();
        this.changeDetectorRef.detectChanges();
      }, 100);        
      
      const screenWidth = window.innerWidth;

      if (screenWidth < 600) {
        this.removePaddingMobile = true;
      }

      this.tituloModal = "Minha localização";

      this.removePadding = true;
    }

    removeEndereco(endereco: Endereco) {
      this.alertaService.mostraAlerta("Você tem certeza que deseja remover o endereço?", 'warning', 'Remover', () => {
        this.clienteService.removeEndereco(endereco.Codigo).subscribe(e => {

          if (endereco.Principal){
            this.clienteService.atualizarEnderecoSelecionadoSubject(null);
            this.clienteService.avisaAtualizacaoEndereco();
            this.sacolaService.selecionarEndereco(null);
          }
          this.listaEnderecos();
        });
      },
      () => 
        this.listaEnderecos(),true
      );
    }

    removeEnderecoSelecionado()
    {
      var selecionados = Array.from(document.getElementsByClassName("endereco selecionado"));

      selecionados.forEach(element => {
        element.classList.remove("selecionado");
      });
    }

    salvarEndereco(endereco: Endereco) {
      
      // Caso o consumidor esteja logado, atualizamos os dados diretamente na API
      if (this.usuarioLogado) {
        if (this.estaAtualizando) {
          this.clienteService.atualizaEndereco(endereco).subscribe(e => {
            this.clienteService.atualizarEnderecoSelecionadoSubject(endereco);
            this.clienteService.avisaAtualizacaoEndereco();
            this.clienteService.alterarFormaRetirada(FormaRetiradaPedido.Entrega);
            this.sacolaService.selecionarEndereco(endereco);
            this.fecharModal();
            this.alertaService.mostraAlerta("Endereço alterado com sucesso!", 'success');
          });
        } else {
          this.clienteService.insereEndereco(endereco).subscribe(e => {
            endereco.Codigo = e.value;
            this.clienteService.atualizarEnderecoSelecionadoSubject(endereco);
            this.clienteService.avisaAtualizacaoEndereco();
            this.clienteService.alterarFormaRetirada(FormaRetiradaPedido.Entrega);
            this.sacolaService.selecionarEndereco(endereco);
            this.fecharModal();
            this.alertaService.mostraAlerta("Endereço cadastrado com sucesso!", 'success');
          });
        }
      } else { // Caso contrário, atualizamos na base local
        if (this.estaAtualizando) {
          this.clienteService.atualizarEnderecoNoCache(endereco);
          this.clienteService.alterarFormaRetirada(FormaRetiradaPedido.Entrega);
          this.sacolaService.selecionarEndereco(endereco);
          this.fecharModal();
          this.alertaService.mostraAlerta("Endereço alterado com sucesso!", 'success');
        } else {
          this.clienteService.insereEnderecoNoCache(endereco);
          this.clienteService.alterarFormaRetirada(FormaRetiradaPedido.Entrega);
          this.sacolaService.selecionarEndereco(endereco);
          this.fecharModal();
          this.alertaService.mostraAlerta("Endereço cadastrado com sucesso!", 'success');
        }
      }
    }

    marcarEnderecoPrincipal(endereco: Endereco) {
      this.enderecosCadastrados.forEach(e => {
        if (e.Codigo != endereco.Codigo) {
          e.Principal = false;
        } else {
          endereco.Principal = true;
          this.clienteService.atualizaEndereco(endereco).subscribe(e => {
            this.listaEnderecos();
          });
        }
      });
    }

    confirmarLocalizacao(): void{
      const posicaoDoMarcador = this.marker.getPosition();

      this.converterLocalizacao(posicaoDoMarcador)
      .then(geocodeResult => {
        this.geocodeResult = geocodeResult;
        this.converterEndereco();
        this.preencherCampos();
      })

      this.tituloModal = "Meu endereço";
      this.removePadding = false;
      this.removePaddingMobile = false;
    }

    preencherCampos(){
      if(this.endereco.Codigo != 0 && this.endereco.Codigo != null ){
        this.estaAtualizando = true;
      }
  
      this.buscaCidade(this.codEstado);
  
      if(this.endereco.Numero){
        this.fourthFormGroup.get('Numero').setValue(this.endereco.Numero);
      }
      if(this.endereco.Rua){
        this.fourthFormGroup.get('Rua').setValue(this.endereco.Rua);
      }
      if(this.endereco.Bairro){
        this.fourthFormGroup.get('Bairro').setValue(this.endereco.Bairro);
      }
  
      this.fourthFormGroup.get('CodTipo').valueChanges
       .subscribe(cod => this.tipoSelecionado = cod > 0);
    }

    preencherChecked(){
      if (!this.enderecosCadastrados || this.enderecosCadastrados.length == 0) {
        this.fourthFormGroup.get("Principal").setValue('checked');
        this.fourthFormGroup.get("Principal").setValue(true);
        this.favorito = true;
      }
    }

    removerTipo() {
      this.fourthFormGroup.get('CodTipo').setValue("");
      this.tipoSelecionado = false;
    }  

    buscaCidade(codEstado: number) {
      this.dneEnderecoService.selecionaCidadesPorCodEstado(codEstado)
        .subscribe(cidades => {
          this.cidades = cidades.value
        });
    }

    salvar(){
      this.endereco.CodMunicipio = this.cidades.find(c => c.Nome == this.endereco.Municipio).Codigo;
      this.endereco.CodConsumidor = this.cliente?.Codigo;
      this.endereco.Numero = Number(this.fourthFormGroup.get('Numero').value);
      this.endereco.Rua = this.fourthFormGroup.get('Rua').value;
      this.endereco.Bairro = this.fourthFormGroup.get('Bairro').value;
      this.endereco.CodTipo = Number(this.fourthFormGroup.get('CodTipo').value);
      this.endereco.Complemento = this.fourthFormGroup.get('Complemento').value;
      this.endereco.Principal = this.fourthFormGroup.get('Principal').value;
      this.endereco.Latitude = String(this.center.lat);
      this.endereco.Longitude = String(this.center.lng);

      if(this.enderecoEditar){
        this.endereco.Codigo = this.enderecoEditar.Codigo;
        this.estaAtualizando = true;
      }

      this.salvarEndereco(this.endereco);
    }

    favoritar(){
      this.favorito = this.fourthFormGroup.get("Principal").value;
    }

    editar(endereco: Endereco){
      if(this.enderecoEditar?.Codigo == endereco.Codigo){
        this.enderecoEditar = null;
      } else {
        this.enderecoEditar = endereco;
      }
    }

    onStepChange(event: any): void{
      if(event.selectedIndex == 0 ){
        this.btn_voltar = true;
      } else {
        this.btn_voltar = false;
      }
    }

    private listaEnderecos() {
      this.clienteService.listaEnderecos().subscribe(enderecos => {
        this.enderecosCadastrados = enderecos.value;
        this.preencherChecked();
      })
    }

    private listaTiposEndereco() {
      this.dneEnderecoService.listaTipoEndereco(0).subscribe(tipos => {
        this.tipos = this.tipos.concat(tipos.value);
      });
    }

    private buscarTodosEstados() {
      this.dneEnderecoService.listarTodosEstados().subscribe(e => {
        this.estados = e.value;
      });
    }
    
    get usuarioLogado() {
      return this.loginService.consumidorLogadoToken ? this.loginService.clienteEstaLogado : null;
    }

    get localizacao() {
      return this.clienteService.localizacao;
    }
}
