import { EstadoPedido } from "@app/_enums/estado-pedido";
import { FormaRetiradaPedido } from "@app/_enums/forma-retirada-pedido";

export class NovoPedido {

    constructor() {
        this.Transacoes = [];
        this.Itens = [];
        this.DataCriacao = new Date();
    }

    Transacoes: Transacao[];
    DataPrevisao: any;
    DataCriacao: any;
    TaxaEntrega: number = 0;
    Agendado: boolean;
    CodEnderecoConsumidor: number;
    Codigo: string;
    Desconto: number;
    DetalheEntregaRetira: string;
    ModoEntregaRetira: FormaRetiradaPedido;
    Observacao: string;
    Retira: boolean;
    SubTotal: number;
    TempoPreparo: number;
    Total: number;
    Itens: ItemPedido[];
    Estado: EstadoPedido;
    DataCancelamento: Date;
    DataEmPreparo: Date;
    DataPronto: Date;
    DataEnviado: Date;
    DataRetornado: Date;
    CodAntiFraude: string;
    Referencia: string;
    SenhaPedido: string;
    IndiceSeq: number;
}


export class Transacao {
    constructor(codCartao: string, valor: number) {
        this.CodCartao = codCartao;
        this.Valor = valor;
        this.CodTipoPagamento = 23;
        this.Troco = 0;
        this.NumeroDoc = null;
    }

    CodCartao: string;
    CodTipoPagamento: number;
    NumeroDoc: string;
    Troco: number;
    Valor: number;
    Online: boolean;
}

export class ItemPedido {
    CodBarras: string;
    CodBasico: string;
    Contem: number;
    ContemPreparo: number;
    Desconto: number;
    DescontoValor: number;
    NumeroItem: number;
    Preparo: string;
    Quantidade: number;
    Total: number;
    Valor: number;
}
