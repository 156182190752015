<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10">
      <div class="box_order_form" *ngIf="pedidos && pedidos.length > 0">
        <div class="head">
          <h3>Últimos pedidos</h3>
        </div>
        <div class="main" style="color: black;">
          <div class="row">
            <div class="col-md-6 col-sm-12" *ngFor="let pedido of pedidos">
              <app-pedido-anterior [pedido]="pedido" [routerLink]="['/acompanhamento', pedido.Codigo]">
              </app-pedido-anterior>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="pedidos && pedidos.length <= 0">
        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/sem-pedidos.svg" alt="Pessoa observando uma cesta vazia.">
        <div (click)="irParaHome()" style="cursor: pointer; font-weight: bold; text-align: center; font-size: 16px;">
          <div>Você ainda não realizou nenhum pedido.</div>
          <div> Clique aqui para começar</div>
        </div>
      </div>

    </div>
  </div>
</div>
