<div class="menu">
  <ul>
    <li *ngFor="let item of itensCarrinho">
      <figure style="margin: 0; position: relative; width: 50%;">
        <img [src]="imagemURL(item) ? imagemURL(item) : 'https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/menu-thumb-placeholder.jpg'" data-src="img/menu-thumb-1.jpg" alt="" width="50" height="50" class="lazy">
      </figure>
      <div class="infos-itens">
        <h5 style="margin: 0;">
          <h5 style="margin: 0;">
            {{ item.quantidade }}x
            {{item.mercadoria.NomeCurto}}
          </h5>
          <span style="font-weight: bold;">
            {{ (item.mercadoria.PrecoVenda | currency:'BRL') }}
          </span>
        </h5>
      </div>
      <div class="quantidade">
        <mat-icon *ngIf="item.quantidade == 1" class="icons-quantidade material-icons-outlined" (click)="removerItemCarrinho(item.codigoItem)">delete</mat-icon>
        <mat-icon *ngIf="item.quantidade != 1" class="icons-quantidade material-icons-outlined" (click)="diminuirQuantidadeItemCarrinho(item)">remove</mat-icon>
        <span>{{ item.quantidade }}</span>
        <mat-icon class="icons-quantidade material-icons-outlined" (click)="aumentarQuantidadeItemCarrinho(item)">add</mat-icon>
      </div>
    </li>
  </ul>
  <div class="total_drop">
    <div class="clearfix add_bottom_15" style="color: black;">
      <h4>Total</h4>
      <h4 style="justify-content: end;">{{ (valorTotal | currency:'BRL') }}</h4>
    </div>
    <button (click)="abrirCarrinho()" class="btn_1 full-width mb_5">
      Ver sacola
    </button>
  </div>
</div>
