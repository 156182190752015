
    <div class="hero_single version_1">
        <div class="opacity-mask">
            <div class="container">
                <div class="row justify-content-lg-start justify-content-md-center">
                    <div class="col-xl-6 col-lg-8">
                        <h1>Delivery or Takeaway Food</h1>
                        <p>The best restaurants at the best price</p>

                        <div method="post" action="grid-listing-filterscol.html">
                            <div class="row no-gutters custom-search-input">
                                <div class="col-lg-10">
                                    <div class="form-group">
                                        <input class="form-control no_border_r" type="text" id="autocomplete" placeholder="Address, neighborhood...">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <button class="btn_1" type="submit">Search</button>
                                </div>
                            </div>
                            <!-- /row -->
                            <div class="search_trends">
                                <h5>Trending:</h5>
                                <ul>
                                    <li><a href="#0">Sushi</a></li>
                                    <li><a href="#0">Burgher</a></li>
                                    <li><a href="#0">Chinese</a></li>
                                    <li><a href="#0">Pizza</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /row -->
            </div>
        </div>
        <div class="wave hero"></div>
    </div>
    <!-- /hero_single -->

    <div class="container margin_30_60">
        <div class="main_title center">
            <span><em></em></span>
            <h2>Popular Categories</h2>
            <p>Cum doctus civibus efficiantur in imperdiet deterruisset</p>
        </div>
        <!-- /main_title -->

        <div class="owl-carousel owl-theme categories_carousel">
            <div class="item_version_2">
                <a href="grid-listing-filterscol.html">
                    <figure>
                        <span>98</span>
                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/home_cat_placeholder.jpg" alt="" class="owl-lazy" width="350" height="450">
                        <div class="info">
                            <h3>Pizza</h3>
                            <small>Avg price $40</small>
                        </div>
                    </figure>
                </a>
            </div>
            <div class="item_version_2">
                <a href="grid-listing-filterscol.html">
                    <figure>
                        <span>87</span>
                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/home_cat_placeholder.jpg" alt="" class="owl-lazy" width="350" height="450">
                        <div class="info">
                            <h3>Japanese</h3>
                            <small>Avg price $50</small>
                        </div>
                    </figure>
                </a>
            </div>
            <div class="item_version_2">
                <a href="grid-listing-filterscol.html">
                    <figure>
                        <span>55</span>
                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/home_cat_placeholder.jpg" alt="" class="owl-lazy" width="350" height="450">
                        <div class="info">
                            <h3>Burghers</h3>
                            <small>Avg price $55</small>
                        </div>
                    </figure>
                </a>
            </div>
            <div class="item_version_2">
                <a href="grid-listing-filterscol.html">
                    <figure>
                        <span>55</span>
                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/home_cat_placeholder.jpg" alt="" class="owl-lazy" width="350" height="450">
                        <div class="info">
                            <h3>Vegetarian</h3>
                            <small>Avg price $40</small>
                        </div>
                    </figure>
                </a>
            </div>
            <div class="item_version_2">
                <a href="grid-listing-filterscol.html">
                    <figure>
                        <span>65</span>
                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/home_cat_placeholder.jpg" alt="" class="owl-lazy" width="350" height="450">
                        <div class="info">
                            <h3>Bakery</h3>
                            <small>Avg price $60</small>
                        </div>
                    </figure>
                </a>
            </div>
            <div class="item_version_2">
                <a href="grid-listing-filterscol.html">
                    <figure>
                        <span>25</span>
                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/home_cat_placeholder.jpg" alt="" class="owl-lazy" width="350" height="450">
                        <div class="info">
                            <h3>Chinese</h3>
                            <small>Avg price $40</small>
                        </div>
                    </figure>
                </a>
            </div>
            <div class="item_version_2">
                <a href="grid-listing-filterscol.html">
                    <figure>
                        <span>35</span>
                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/home_cat_placeholder.jpg" alt="" class="owl-lazy" width="350" height="450">
                        <div class="info">
                            <h3>Mexican</h3>
                            <small>Avg price $35</small>
                        </div>
                    </figure>
                </a>
            </div>
        </div>
        <!-- /carousel -->
    </div>
    <!-- /container -->

    <div class="bg_gray">
        <div class="container margin_60_40">
            <div class="main_title">
                <span><em></em></span>
                <h2>Top Rated Restaurants</h2>
                <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
                <a href="#0">View All &rarr;</a>
            </div>
            <div class="row add_bottom_25">
                <div class="col-lg-6">
                    <div class="list_home">
                        <ul>
                            <li>
                                <a href="detail-restaurant.html">
                                    <figure>
                                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/location_list_placeholder.png" data-src="img/location_list_1.jpg" alt="" class="lazy" width="350" height="233">
                                    </figure>
                                    <div class="score"><strong>9.5</strong></div>
                                    <em>Italian</em>
                                    <h3>La Monnalisa</h3>
                                    <small>8 Patriot Square E2 9NF</small>
                                    <ul>
                                        <li><span class="ribbon off">-30%</span></li>
                                        <li>Average price $35</li>
                                    </ul>
                                </a>
                            </li>
                            <li>
                                <a href="detail-restaurant.html">
                                    <figure>
                                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/location_list_placeholder.png" data-src="img/location_list_2.jpg" alt="" class="lazy" width="350" height="233">
                                    </figure>
                                    <div class="score"><strong>8.0</strong></div>
                                    <em>Mexican</em>
                                    <h3>Alliance</h3>
                                    <small>27 Old Gloucester St, 4563</small>
                                    <ul>
                                        <li><span class="ribbon off">-40%</span></li>
                                        <li>Average price $30</li>
                                    </ul>
                                </a>
                            </li>
                            <li>
                                <a href="detail-restaurant.html">
                                    <figure>
                                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/location_list_placeholder.png" data-src="img/location_list_3.jpg" alt="" class="lazy" width="350" height="233">
                                    </figure>
                                    <div class="score"><strong>9.0</strong></div>
                                    <em>Sushi - Japanese</em>
                                    <h3>Sushi Gold</h3>
                                    <small>Old Shire Ln EN9 3RX</small>
                                    <ul>
                                        <li><span class="ribbon off">-25%</span></li>
                                        <li>Average price $20</li>
                                    </ul>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list_home">
                        <ul>
                            <li>
                                <a href="detail-restaurant.html">
                                    <figure>
                                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/location_list_placeholder.png" data-src="img/location_list_3.jpg" alt="" class="lazy" width="350" height="233">
                                    </figure>
                                    <div class="score"><strong>9.5</strong></div>
                                    <em>Vegetarian</em>
                                    <h3>Mr. Pepper</h3>
                                    <small>27 Old Gloucester St, 4563</small>
                                    <ul>
                                        <li><span class="ribbon off">-30%</span></li>
                                        <li>Average price $20</li>
                                    </ul>
                                </a>
                            </li>
                            <li>
                                <a href="detail-restaurant.html">
                                    <figure>
                                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/location_list_placeholder.png" data-src="img/location_list_3.jpg" alt="" class="lazy" width="350" height="233">
                                    </figure>
                                    <div class="score"><strong>8.0</strong></div>
                                    <em>Chinese</em>
                                    <h3>Dragon Tower</h3>
                                    <small>22 Hertsmere Rd E14 4ED</small>
                                    <ul>
                                        <li><span class="ribbon off">-50%</span></li>
                                        <li>Average price $35</li>
                                    </ul>
                                </a>
                            </li>
                            <li>
                                <a href="detail-restaurant.html">
                                    <figure>
                                        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/location_list_placeholder.png" data-src="img/location_list_3.jpg" alt="" class="lazy" width="350" height="233">
                                    </figure>
                                    <div class="score"><strong>8.5</strong></div>
                                    <em>Pizza - Italian</em>
                                    <h3>Bella Napoli</h3>
                                    <small>135 Newtownards Road BT4</small>
                                    <ul>
                                        <li><span class="ribbon off">-45%</span></li>
                                        <li>Average price $25</li>
                                    </ul>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- /row -->
            <div class="banner lazy" data-bg="url(img/banner_bg_desktop.jpg)">
                <div class="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                    <div>
                        <small>FooYes Delivery</small>
                        <h3>We Deliver to your Office</h3>
                        <p>Enjoy a tasty food in minutes!</p>
                        <a href="grid-listing-filterscol.html" class="btn_1">Start Now!</a>
                    </div>
                </div>
                <!-- /wrapper -->
            </div>
            <!-- /banner -->
        </div>
    </div>
    <!-- /bg_gray -->

    <div class="shape_element_2">
        <div class="container margin_60_0">
            <div class="row">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="box_how">
                                <figure><img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/lazy-placeholder-100-100-white.png" data-src="img/how_1.svg" alt="" width="150" height="167" class="lazy"></figure>
                                <h3>Easly Order</h3>
                                <p>Faucibus ante, in porttitor tellus blandit et. Phasellus tincidunt metus lectus sollicitudin.</p>
                            </div>
                            <div class="box_how">
                                <figure><img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/lazy-placeholder-100-100-white.png" data-src="img/how_2.svg" alt="" width="130" height="145" class="lazy"></figure>
                                <h3>Quick Delivery</h3>
                                <p>Maecenas pulvinar, risus in facilisis dignissim, quam nisi hendrerit nulla, id vestibulum.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 align-self-center">
                            <div class="box_how">
                                <figure><img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/lazy-placeholder-100-100-white.png" data-src="img/how_3.svg" alt="" width="150" height="132" class="lazy"></figure>
                                <h3>Enjoy Food</h3>
                                <p>Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros.</p>
                            </div>
                        </div>
                    </div>
                    <p class="text-center mt-3 d-block d-lg-none"><a href="#0" class="btn_1 medium pulse_bt mt-2">Register Now!</a></p>
                </div>
                <div class="col-lg-5 offset-lg-1 align-self-center">
                    <div class="intro_txt">
                        <div class="main_title">
                            <span><em></em></span>
                            <h2>Start Ordering Now</h2>
                        </div>
                        <p class="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed imperdiet libero id nisi euismod, sed porta est consectetur deserunt.</p>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        <p><a href="#0" class="btn_1 medium pulse_bt mt-2">Register</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /shape_element_2 -->
