import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { DetalhesCliente } from '@app/_models/cliente/detalhes-cliente';
import { BaseService } from '../base/base.service';
import { Endereco } from '@app/_models/endereco/endereco';
import { LoginConsumidorRedeSocial } from '@app/_models/usuario/login-rede-social';
import { CartaoCredito } from '@app/_models/cliente/cartao/create-cartao-credito';
import { AlterarSenha } from '@app/_models/cliente/alterar-senha';
import { ConfirmacaoEmail } from '@app/_models/cliente/confirmacao-email';
import { OTP } from '@app/_models/cliente/otp';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject } from 'rxjs';
import { DneService } from '../dne/dne.service';
import { ResultadoDistanciaEnderecos } from '@app/_models/endereco/resultado-distancia-enderecos';
import { CacheService } from '../base/cache.service';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { DetalhesCartaoCredito } from '@app/_models/cliente/cartao/detalhes-cartao-credito';

const endpointUrl = 'consumidor/';

@Injectable()
export class ClienteService extends BaseService {

  enderecos = null;
  enderecoAtualizado = new EventEmitter<void>();

  public enderecoSelecionado = new BehaviorSubject<Endereco>(null);
  public permiteEntregaNoEnderecoSelecionadoSubject = new BehaviorSubject<boolean>(false);
  public formaRetirada: BehaviorSubject<FormaRetiradaPedido> = new BehaviorSubject(FormaRetiradaPedido.Entrega);
  public localizacao = null;

  constructor(
    httpClient: HttpClient,
    private dneService: DneService,
    private cacheService: CacheService,
    private dbService: NgxIndexedDBService
  ) {
    super(httpClient);

    this.dbService.getAll('endereco')
      .subscribe((itens: any[]) => {
        this.enderecos = itens.map(e => {
          this.enderecoSelecionado.next(JSON.parse(e.item));
        })
      });
  }

  insere(cliente) {
    return this.http.post<any>(this.API_URL + endpointUrl, cliente);
  }

  atualiza(cliente: DetalhesCliente) {
    return this.http.put<any>(this.API_URL + endpointUrl, cliente);
  }

  logar(Senha: string, Identificacao: string) {
    return this.http.put<any>(`${this.API_URL + endpointUrl}logar`, { Senha, Identificacao });
  }

  loginRedeSocial(logarCliente: LoginConsumidorRedeSocial) {
    return this.http.post<any>(`${this.API_URL + endpointUrl}logar`, logarCliente);
  }

  verificaCadastroExiste(identificacao: string) {
    return this.http.get<any>(`${this.API_URL + endpointUrl}login/${identificacao}`);
  }

  alterarSenha(alterarSenha: AlterarSenha) {
    return this.http.put<any>(`${this.API_URL + endpointUrl}/alterarsenha`, alterarSenha);
  }

  confirmarEmail() {
    return this.http.post<any>(`${this.API_URL + endpointUrl}/confirmarEmail`, {});
  }

  verificaEmailConfirmado(CodConsumidor: number, Token: string) {
    const confirmacao: ConfirmacaoEmail = { CodConsumidor: Number.parseInt(CodConsumidor.toString()), Token };
    return this.http.put<any>(`${this.API_URL + endpointUrl}ConfirmarEmail`, confirmacao);
  }

  enviaEmailResetaSenha(Email: string) {
    return this.http.post<any>(`${this.API_URL + endpointUrl}/ResetarSenha`, { Email });
  }

  resetaSenha(CodConsumidor: string, Token: string, SenhaNova: string) {
    const resetaSenha = { CodConsumidor: Number.parseInt(CodConsumidor.toString()), Token, SenhaNova }
    return this.http.put<any>(`${this.API_URL + endpointUrl}/ResetarSenha`, resetaSenha);
  }

  enviarSMSParaVerificacaoIdentidade(dadosParaValidacao: OTP) {
    return this.http.post<any>(`${this.API_URL + endpointUrl}/ConfirmarSMS`, dadosParaValidacao);
  }

  validarSMSIdentidade(dadosParaValidacao: OTP) {
    return this.http.put<any>(`${this.API_URL + endpointUrl}/ConfirmarSMS`, dadosParaValidacao);
  }

  // ENDEREÇO
  alterarFormaRetirada(forma: FormaRetiradaPedido) {
    this.formaRetirada.next(forma);
  }

  get selecionarFormaRetirada(){
    return this.formaRetirada.value;
  }

  get permiteEntregaNoEnderecoSelecionado() {
    return this.permiteEntregaNoEnderecoSelecionadoSubject.value;
  }

  public atualizarPermiteEntregaNoEnderecoSelecionado(estado: boolean) {
    this.permiteEntregaNoEnderecoSelecionadoSubject.next(estado);
  }

  get enderecoSelecionadoSubject() {
    return this.enderecoSelecionado.value;
  }

  public atualizarStatusPermiteEntrega(endereco: Endereco) {
    const enderecoUsuario = `${endereco.Rua}, ${endereco.Numero}, ${endereco.Bairro}, ${endereco.Municipio}, ${endereco.Cep}`;
    const restaurante = this.cacheService.load('loja');
    const enderecoRestaurante = `${restaurante.EnderecoCompleto} ${restaurante.CEP}`;

    this.dneService.calculaDistanciaEntreEnderecos(enderecoRestaurante, enderecoUsuario, false)
      .subscribe((e: ResultadoDistanciaEnderecos) => {
        this.atualizarPermiteEntregaNoEnderecoSelecionado(e.Distancia < (restaurante.RaioEntregaKM * 1000));
      })
  }

  public atualizarEnderecoSelecionadoSubject(endereco?: Endereco) {
    this.atualizarEnderecoNoCache(endereco);
    if (endereco)
      this.atualizarStatusPermiteEntrega(endereco);
  }

  avisaAtualizacaoEndereco() {
    this.enderecoAtualizado.emit();
  }

  limparEnderecoSelecionadoSubject() {
    this.enderecoSelecionado.next(null);
    this.dbService.clear("endereco").subscribe();
  }

  insereEndereco(endereco: Endereco) {
    return this.http.post<any>(this.API_URL + endpointUrl + `endereco`, endereco);
  }

  insereEnderecoNoCache(endereco: Endereco) {
    this.dbService
      .add('endereco', { item: JSON.stringify(endereco) })
      .subscribe(key => {
        endereco.Codigo = Number(key);
        endereco.Principal = true;
        this.enderecoSelecionado.next(endereco);

        // Altera o status se permite entrega ou não
        this.atualizarStatusPermiteEntrega(endereco);
      });

    return this.enderecoSelecionado;
  }

  atualizarEnderecoNoCache(enderecoAtualizado: Endereco) {
    // Caso o endereço venha vazio, apagamos os dados do mesmo do cache.
    if (enderecoAtualizado?.Rua == null) {
      this.limparEnderecoSelecionadoSubject();
    }
    else {
      this.dbService.getByKey('endereco', 1)
        .subscribe(() => {
          const _endereco = this.enderecoSelecionado?.value != null ? this.enderecoSelecionado.value : new Endereco();
          _endereco.Codigo = enderecoAtualizado.Codigo;
          _endereco.Rua = enderecoAtualizado.Rua;
          _endereco.Bairro = enderecoAtualizado.Bairro;
          _endereco.Cep = enderecoAtualizado.Cep;
          _endereco.CodConsumidor = enderecoAtualizado.CodConsumidor;
          _endereco.CodMunicipio = enderecoAtualizado.CodMunicipio;
          _endereco.CodTipo = enderecoAtualizado.CodTipo;
          _endereco.Complemento = enderecoAtualizado.Complemento;
          _endereco.Municipio = enderecoAtualizado.Municipio;
          _endereco.Numero = enderecoAtualizado.Numero;
          _endereco.Principal = enderecoAtualizado.Principal != null ? enderecoAtualizado.Principal : true;
          _endereco.Tipo = enderecoAtualizado.Tipo;

          var pedidoString = { item: JSON.stringify(_endereco), codigoEndereco: _endereco.Codigo }
          this.dbService.update('endereco', pedidoString).subscribe(() => {
            this.enderecoSelecionado.next(enderecoAtualizado);

            // Altera o status se permite entrega ou não
            this.atualizarStatusPermiteEntrega(enderecoAtualizado);
          })
        });
    }
  }

  atualizaEndereco(endereco: Endereco) {
    this.atualizarEnderecoSelecionadoSubject(endereco);
    return this.http.put<any>(this.API_URL + endpointUrl + `endereco`, endereco);
  }

  listaEnderecos() {
    return this.http.get<any>(`${this.API_URL + endpointUrl}endereco`);
  }

  removeEndereco(codigo: number) {
    return this.http.delete<any>(`${this.API_URL + endpointUrl}endereco/${codigo}`,);
  }

  // CARTÃO DE CRÉDITO
  atualizaCartao(cartao: DetalhesCartaoCredito) {
    return this.http.put<any>(`${this.API_URL + endpointUrl}cartao`, cartao);
  }

  listaCartaoCredito() {
    return this.http.get<any>(`${this.API_URL + endpointUrl}cartao`);
  }

  criaCartaoCredito(cartao: CartaoCredito) {
    return this.http.post<any>(`${this.API_URL + endpointUrl}cartao`, cartao);
  }

  removeCartaoCredito(cartaoToken: string) {
    return this.http.request('DELETE', `${this.API_URL + endpointUrl}cartao`,
      { body: { Token: cartaoToken } }
    );
  }
}
