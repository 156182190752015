	<div id="modal-dialog" ngbAutofocus>

	  <div class="modal_header">
	    <h3>Selecionar cartão de crédito</h3>
		<button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
			<mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
		</button>
	  </div>

	  <div class="modal_body">

		<div style="text-align: center;">
			<img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/cartao-credito.svg" width="250px">
		</div>

		<div *ngFor="let cartao of cartoesCadastrados" class="cartao">
			<mat-icon class="material-icons-outlined icon-favorito" *ngIf="cartao.Principal">favorite</mat-icon>
            <mat-icon class="material-icons-outlined icon-favorito" *ngIf="!cartao.Principal" (click)="marcarCartaoPrincipal(cartao)">favorite_border</mat-icon>
      
			<div class="informacoes-cartao" (click)="selecionarCartao(cartao)">
				<div>{{ cartao.Apelido }}</div>
				<div>{{ cartao.Bandeira }}</div>
				<div>{{ cartao.NumeroCartao }}</div>
				<div>{{ cartao.ExpiracaoMes }} / {{ cartao.ExpiracaoAno }}</div>
			</div>

			<div class="icon_remover" (click)="removerCartao(cartao)">
				<span class="box1">
				<mat-icon class="material-icons-outlined">delete</mat-icon>
				</span>
			</div>
		</div>

	  </div>
	</div>
