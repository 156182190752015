	<div id="modal-dialog" style="padding-right: 0;" ngbAutofocus>

		<div class="modal_header">
			<h3>Selecionar endereço</h3>
			<button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
				<mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
			</button>
		</div>
		<div class="modal_body">
			<div style="text-align: center;">
				<img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/enderecos.svg" alt="Pessoa de óculos segurando um mapa, ao fundo vários prédios, sendo um deles marcado com um pin indicando o endereço de destino." width="150px">
			</div>

			<div *ngFor="let endereco of enderecosCadastrados" class="endereco">
				<mat-icon class="icon material-icons-outlined" *ngIf="endereco.Principal">favorite</mat-icon>
				<mat-icon class="icon material-icons-outlined" *ngIf="!endereco.Principal" (click)="marcarEnderecoPrincipal(endereco)">favorite_border</mat-icon>
		
				<div style="display: inline-block; width: 100%;" (click)="selecionarEndereco(endereco)">
				<div *ngIf="endereco.Tipo">
					{{ endereco.Tipo }}
				</div>
				<div style="font-weight: 500; font-size: 15px;">
					{{ endereco.Rua.trim() }}, {{ endereco.Numero }},
					{{ endereco.Bairro }}, {{ endereco.Municipio }}
				</div>
				<div>
					{{ endereco.Complemento }}
				</div>
				</div>

				<div style="display: inline-flex; float: right;">
				<div mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
					<mat-icon class="material-icons-outlined">more_vert</mat-icon>
				</div>
				<mat-menu #menu="matMenu">
					<div mat-menu-item class="menu-item" (click)="abrirModalAdicionarEndereco(endereco)">
						<mat-icon class="material-icons-outlined icon-opcoes" style="margin-right: 5px;">edit_note</mat-icon>
						<span class="box1" style="margin-right: 10px; cursor: pointer;">
							Editar
						</span>
					</div>
					<div mat-menu-item class="menu-item" style="padding-left: 12px;" (click)="removeEndereco(endereco)">
						<mat-icon class="material-icons-outlined icon-opcoes" style="margin-right: 5px;">delete</mat-icon>
						<span class="box1" style="cursor: pointer; margin-right: 10px;">
							Excluir
						</span>
					</div>
				</mat-menu>
				</div>
			</div>
		</div>
	</div>
