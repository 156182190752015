<footer>
  <div class="wave footer"></div>
  <div class="container">
    <div class="row">
      <div class="col-12 container-footer">
        <h4 class="desenvolvido-por">Powered by 
          <a href="https://nox.com.br/sistema-para-cardapio-digital/" target="_blank" title="Sistema para Bares e Restaurantes">
            Nox Automação
          </a> 
          ©
        </h4>
      </div>
    </div>
    <!-- /row-->
  </div>
</footer>
<!--/footer-->
