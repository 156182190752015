const CryptoJS = require('crypto-js');


export class CriptografaSenha {

    public criptografaSenha(senha: string) : string {

        let hash = CryptoJS.SHA256(senha);
        const senhaCriptografada = hash.toString(CryptoJS.enc.Base64);

        return senhaCriptografada;
    }
}