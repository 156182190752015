import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StringHelper {

    constructor() { }

    public formataMensagemErro(str: string) {
        return str.replace("<Aviso>", "")
    }

}
