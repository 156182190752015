import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { DetalhesPrecoComposta } from '@app/_models/mercadoria/detalhes-preco-composta';

@Injectable()
export class MercadoriaCompostaService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  calculaPrecoComposta(detalhesPrecoComposta: DetalhesPrecoComposta){
    return this.http.post<any>(this.API_URL + 'mercadoriacomposta/calcularpreco', detalhesPrecoComposta);
  }

  listaDetalhesMercadoriaComposta(codigoBasicoContem: string) {
    return this.http.get<any>(this.API_URL + 'mercadoriacomposta/' + codigoBasicoContem);
  }

}
