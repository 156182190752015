import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlterarSenha } from '@app/_models/cliente/alterar-senha';
import { ModalAlertService } from '@app/_services/base/alert.service';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { CriptografaSenha } from '@app/_utills/criptografa-senha';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {

  form: UntypedFormGroup;
  senhaInvalida: string = "";
  mensagemErro: string;
  telaResetarSenha = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private clienteService: ClienteService,
    private router: Router,
    private route: ActivatedRoute,
    private alertaService: ModalAlertService,
  ) {
    this.criaForm();
  }

  ngOnInit(): void {
    this.telaResetarSenha = this.router.url.includes('Reconfigurar/Consumidor');

    if(!this.telaResetarSenha) {
      this.form.get('SenhaAtual').setValidators([Validators.required, Validators.minLength(8)]);
      this.form.get('SenhaAtual').updateValueAndValidity();
    } 
  }

  private criaForm() {
    this.form = this.formBuilder.group({
      SenhaAtual: [undefined],
      SenhaNova: [undefined, [Validators.required, Validators.minLength(8)]],
      confirmarSenha: [undefined, [Validators.required, Validators.minLength(8)]],
    });
  }

  validaNovaSenha() {
    const senha = this.form.get('SenhaNova');
    let confirmarSenha = this.form.get('confirmarSenha');
    let senhaAtual = this.form.get('SenhaAtual');

    if (!senha.value || (!this.telaResetarSenha && !senhaAtual.value)) {
      this.senhaInvalida = 'Todos os campos devem ser preenchidos';
    }

    else if (senha.value.length < 8 || (confirmarSenha.value && confirmarSenha.value.length < 8)) {
      senha.setErrors({ 'incorrect': true });
      this.senhaInvalida = 'A senha deve ter no mínimo 8 caracteres';
    }
    else if (senha.value !== confirmarSenha.value) {
      if (confirmarSenha.value) {
        confirmarSenha.setErrors({ 'incorrect': true });
        senha.setErrors({ 'incorrect': true });
        this.senhaInvalida = 'As senhas não são iguais!';
      }
    }
    else {
      if (confirmarSenha.value && senha.value) {
        this.senhaInvalida = undefined;
        senha.setErrors(null);
        confirmarSenha.setErrors(null);
      }
    }
  }

  salvarNovaSenha() {
    const alterarSenha: AlterarSenha = this.form.getRawValue();
    const senha = new CriptografaSenha().criptografaSenha(alterarSenha.SenhaNova);
    const senhaAntiga = new CriptografaSenha().criptografaSenha(alterarSenha.SenhaAtual);
    alterarSenha.SenhaNova = senha;
    alterarSenha.SenhaAtual = senhaAntiga;
    
    if (this.telaResetarSenha) {
      const codConsumidor = this.route.snapshot.params.codigo;
      const token = this.route.snapshot.params.token;

      this.clienteService.resetaSenha(codConsumidor, token, alterarSenha.SenhaNova).subscribe(e => {
        this.alertaService.mostraAlerta('Senha confirmada com sucesso', 'success');
        this.router.navigate(['/']);
      })
    } else {
      this.clienteService.alterarSenha(alterarSenha)
      .subscribe(
        (e) => {
          Swal.fire({
            title: 'Sucesso',
            text: 'A senha foi alterada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.router.navigate(['/']);
          })
        },
        (err) => this.senhaInvalida = err.replace("<Aviso>", "")
      );
    }
  }

  get podeSalvar() {
    return this.form.valid;
  }

}
