import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../base/base.service";

@Injectable()
export class TaxaEntregaService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  calculaValorEntrega(Distancia: number) {
    return this.http.get<any>(this.API_URL + 'taxaentrega?Distancia=' + Distancia );
  }

}
