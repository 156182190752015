import { Component, OnInit } from '@angular/core';
import { DetalhesCartaoCredito } from '@app/_models/cliente/cartao/detalhes-cartao-credito';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-selecao-cartao',
  templateUrl: './modal-selecao-cartao.component.html',
  styleUrls: ['./modal-selecao-cartao.component.scss']
})
export class ModalSelecaoCartaoComponent implements OnInit {

  cartoesCadastrados: DetalhesCartaoCredito[];
  cartaoRemovido: BehaviorSubject<DetalhesCartaoCredito>;

  constructor(
    public activeModal: NgbActiveModal,
    private clienteService: ClienteService
  ) {
    if (!this.cartoesCadastrados) {
      this.listaCartoes();
    }
  }

  ngOnInit(): void {
  }

  listaCartoes() {
    this.clienteService.listaCartaoCredito().subscribe(e => {
      this.cartoesCadastrados = e.value;
    });
  }

  marcarCartaoPrincipal(cartao: DetalhesCartaoCredito) {
    this.cartoesCadastrados.forEach(e => {
      if (e.Codigo != cartao.Codigo) {
        e.Principal = false;
      } else {
        cartao.Principal = true;
        this.clienteService.atualizaCartao(cartao).subscribe(e => {
          this.listaCartoes();
        });
      }
    });
  }

  selecionarCartao(cartao: DetalhesCartaoCredito) {
    this.fecharModal(cartao);
  }

  removerCartao(cartao: DetalhesCartaoCredito) {
    Swal.fire({
      title: 'Remover',
      text: 'Você tem certeza que deseja remover o cartão?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.clienteService.removeCartaoCredito(cartao.Codigo).subscribe(e => {
          this.cartoesCadastrados = this.cartoesCadastrados.filter(c => c.Codigo != cartao.Codigo);
          this.cartaoRemovido.next(cartao);
          this.fecharModal();
        });
      }
    })
  }

  fecharModal(cartaoCredito?: DetalhesCartaoCredito) {
    this.activeModal.close(cartaoCredito);
  }
}
