import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { LoginService } from '@app/_services/login/login.service';
import { PedidoService } from '@app/_services/pedido/pedido.service';

@Component({
  selector: 'app-meus-pedidos',
  templateUrl: './meus-pedidos.component.html',
  styleUrls: ['./meus-pedidos.component.scss']
})
export class MeusPedidosComponent implements OnInit {

  restaurante: DetalhesLoja;
  pedidos;

  constructor(
    private pedidoService: PedidoService,
    private router: Router,
    private loginService: LoginService,
  ) {
    this.restaurante = loginService.lojaLogada;

    this.pedidoService.listaPedidosResumidos(this.restaurante.CodLoja, 10)
      .subscribe(e => this.pedidos = e.value);

  }

  ngOnInit(): void {
  }

  abrirPedido() {
    this.router.navigate(['acompanhamento']);
  }

  irParaHome() {
    this.router.navigate(['/']);
  }
}
