import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { IniciarConsumoMesa } from '@app/_models/mesa/inicia-consumo-mesa';
import { CriaPedidoMesa } from '@app/_models/mesa/cria-pedido-mesa';

@Injectable()
export class MesaService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public selecionarMesa(codMesaCartao: number){
    return this.http.post<any>(this.API_URL + 'MesaCartao/selecionar', { CodMesaCartao: codMesaCartao });
  }

  public iniciarConsumoMesa(mesaCliente: IniciarConsumoMesa){
    return this.http.post<any>(this.API_URL + 'MesaCartao/iniciarconsumo', mesaCliente);
  }

  public criarPedidoMesa(pedidoMesa: CriaPedidoMesa){
    return this.http.post<any>(this.API_URL + 'MesaCartao/criar', pedidoMesa);
  }

  public bloquearMesa(codMesaCartao: number){
    return this.http.post<any>(this.API_URL + 'MesaCartao/bloquear', { CodMesaCartao: codMesaCartao });
  }

  public desbloquearMesa(codMesaCartao: number){
    return this.http.post<any>(this.API_URL + 'MesaCartao/Desbloquear', { CodMesaCartao: codMesaCartao });
  }
}
