import { Component, Input, OnInit } from '@angular/core';
import { ItemHistoricoPedido } from '@app/_models/pedido/item-historico-pedido';

@Component({
  selector: 'app-pedido-anterior',
  templateUrl: './pedido-anterior.component.html',
  styleUrls: ['./pedido-anterior.component.scss']
})
export class PedidoAnteriorComponent implements OnInit {

  @Input() pedido: ItemHistoricoPedido;

  constructor() { }

  ngOnInit(): void {}

  getDiaSemana(dataPedido: Date) {
    const data = new Date(dataPedido);
    const dia = data.getDay();

    switch (dia) {
      case 0: return `Domingo - ${data.getHours()}:${data.getMinutes().toString().padStart(2, '0')}`;
      case 1: return `Segunda-feira - ${data.getHours()}:${data.getMinutes().toString().padStart(2, '0')}`;
      case 2: return `Terça-feira - ${data.getHours()}:${data.getMinutes().toString().padStart(2, '0')}`;
      case 3: return `Quarta-feira - ${data.getHours()}:${data.getMinutes().toString().padStart(2, '0')}`;
      case 4: return `Quinta-feira - ${data.getHours()}:${data.getMinutes().toString().padStart(2, '0').toString().padStart(2, '0')}`;
      case 5: return `Sexta-feira - ${data.getHours()}:${data.getMinutes().toString().padStart(2, '0')}`;
      case 6: return `Sábado - ${data.getHours()}:${data.getMinutes().toString().padStart(2, '0')}`;
    }
  }

  getDataFormatada(dataPedido: Date) {
    const data = new Date(dataPedido);

    return `${data.getDate()} ${this.getMes(data.getMonth())} ${data.getFullYear()}`;

  }

  getMes(mes: number) {
    switch (mes + 1) {
      case 1: return "Janeiro"
      case 2: return "Fevereiro"
      case 3: return "Março"
      case 4: return "Abril"
      case 5: return "Maio";
      case 6: return "Junho";
      case 7: return "Julho";
      case 8: return "Agosto";
      case 9: return "Setembro";
      case 10: return "Outubro";
      case 11: return "Novembro";
      case 12: return "Dezembro";
    }
  }
}
