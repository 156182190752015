import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal-resumo-pedido',
  templateUrl: './modal-resumo-pedido.component.html',
  styleUrls: ['./modal-resumo-pedido.component.scss']
})
export class ModalResumoPedidoComponent implements OnInit {

  pedidoRealizado = new BehaviorSubject<boolean>(false);

  constructor(public activeModal: NgbActiveModal) { 
    this.pedidoRealizado.subscribe(e => {
      if(e) {
        this.pedidoRealizado.next(false);
        this.fecharModal();
      }
    })
  }

  ngOnInit(): void {
  }

  fecharModal() {
    this.activeModal.close();
  }

}
