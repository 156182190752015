import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalMensagemComponent } from '@app/_components/compartilhados/modal-mensagem/modal-mensagem.component';
import { TipoRedeSocial } from '@app/_enums/tipo-rede-social';
import { Cliente } from '@app/_models/cliente/cliente';
import { ClienteLogado } from '@app/_models/cliente/cliente-logado';
import { LogarCliente } from '@app/_models/cliente/logar-clients';
import { OTP } from '@app/_models/cliente/otp';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { ModalAlertService } from '@app/_services/base/alert.service';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { LoginService } from '@app/_services/login/login.service';
import { CriptografaSenha } from '@app/_utills/criptografa-senha';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { ModalOtpComponent } from '../modal-otp/modal-otp.component';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {

  form: UntypedFormGroup;
  formRedeSocial: UntypedFormGroup;

  mostrarCampoSenha = false;
  mostrarCelular = false;
  usuario: Cliente;
  erro: string;
  redeSocial: TipoRedeSocial;

  entrarComEmailCelular = false;
  emailPendenteConfirmacao = false;

  mostrarSenha = false;
  emailCelularValido = true;

  @ViewChild("senha") campoSenha: ElementRef;
  @ViewChild("identificacao") campoIdentificacao: ElementRef;
  restaurante: DetalhesLoja;
  private authService: SocialAuthService;
  mascaraCelular = '(00) 0000-00009';

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private clienteService: ClienteService,
    private alertaService: ModalAlertService,
    private modalService: NgbModal,
    private toastr: ToastrService,
  ) {
    this.restaurante = this.loginService.lojaLogada;
    this.criaForm();
    var providers = [];
    if (this.restaurante.CodAppGoogle) {
      providers.push({
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(this.restaurante.CodAppGoogle)
      });
    }

    if (this.restaurante.CodAppFacebook) {
      providers.push({
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(this.restaurante.CodAppFacebook)
      });
    }

    this.authService = new SocialAuthService({ providers: providers });
  }

  ngOnInit(): void { }

  criaForm() {
    this.form = this.formBuilder.group({
      Identificacao: [],
      Senha: []
    });

    this.formRedeSocial = this.formBuilder.group({
      celular: []
    });
  }

  fecharModal() {
    this.activeModal.close();
  }

  abrirTelaCadastro() {
    this.fecharModal();
    this.router.navigate(['/cadastro']);
  }

  atualizaMascaraCelular() {
    this.formRedeSocial.get('celular').valueChanges
      .subscribe(e => this.mascaraCelular = e.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000');
  }


  enviarEmailRecuperarSenha() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalMensagemComponent);
    modalRef.componentInstance.titulo = 'Atenção';
    modalRef.componentInstance.mensagem = "Deseja solicitar o cadastro de uma nova senha?";
    modalRef.componentInstance.textoBotaoConfirmar = "Sim";
    modalRef.componentInstance.imagem = "/assets/images/esqueci-senha.svg";
    modalRef.componentInstance.mostrarBotaoCancelar = true;

    modalRef.result.then((confirmar: boolean) => {
      if (confirmar) {
        this.clienteService.enviaEmailResetaSenha(this.form.get('Identificacao').value).subscribe(e => {
          this.alertaService.mostraAlerta('Você receberá em seu e-mail as instruções para redefinir a senha', 'success');
        })
      }
      document.body.style.position = '';
    }).catch((err) => { 
      document.body.style.position = '';
    });
  }

  logarComGoogle(): void {
    const ref = this;

    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(response => {
      ref.usuario = new Cliente(response.email, response.name, response.idToken, TipoRedeSocial.Google);
      ref.clienteService.verificaCadastroExiste(response.email)
        .subscribe(cadastrado => {
          if (cadastrado.value) {
            ref.fecharModal();

            ref.clienteService
              .loginRedeSocial({ RedeSocial: TipoRedeSocial.Google, Token: response.idToken, Email: ref.usuario.Email })
              .subscribe(usuarioLogado => {
                ref.loginService.salvaConsumidorCache(usuarioLogado, usuarioLogado.JWTTokenExpiracao);
                ref.loginService.salvarTokenConsumidor(usuarioLogado.JWTToken, usuarioLogado.JWTTokenExpiracao);
              }, (err) => this.erro = err);
          } else {
            ref.mostrarCelular = true;
            this.redeSocial = TipoRedeSocial.Google;
          }
        });
    });
  }

  logarComFacebook(): void {
    const ref = this;
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(response => {

      // Caso não retorne o e-mail do facebook, não podemos prosseguir com o login.
      if (!response.email){
        this.erro = "Não conseguimos identificar o seu e-mail! Você não deu permissão para lermos seu e-mail ou seu e-mail não está verificado com o facebook.";
        return;
      }

      ref.usuario = new Cliente(response.email, response.name, response.id, TipoRedeSocial.Facebook);
      ref.clienteService.verificaCadastroExiste(response.email)
        .subscribe(cadastrado => {
          if (cadastrado.value) {
            ref.fecharModal();

            ref.clienteService
              .loginRedeSocial({ RedeSocial: TipoRedeSocial.Facebook, Token: response.id, Email: ref.usuario.Email })
              .subscribe(usuarioLogado => {
                ref.loginService.salvaConsumidorCache(usuarioLogado, usuarioLogado.JWTTokenExpiracao);
                ref.loginService.salvarTokenConsumidor(usuarioLogado.JWTToken, usuarioLogado.JWTTokenExpiracao);

              }, (err) => this.erro = err);
          } else {
            ref.mostrarCelular = true;
            this.redeSocial = TipoRedeSocial.Facebook;
          }
        });
    });
  }

  sincronizarEndereco(cliente: ClienteLogado) {
    // Caso exista um endereço salvo no navegador, devemos atualizar
    // o mesmo na conta do cliente ou inseri-lo. Caso necessário.
    if (this.clienteService.enderecoSelecionadoSubject?.Rua != null && this.clienteService.enderecoSelecionadoSubject?.Rua != undefined) {
      // Adiciona o código do consumidor no endereço
      this.clienteService.enderecoSelecionadoSubject.CodConsumidor = cliente.Codigo;
      this.clienteService.listaEnderecos().subscribe(enderecos => {
        this.clienteService.enderecos = enderecos.value;

        // Caso exista endereços, verificamos se o endereço do cache existe no banco de dados,
        // se existir, apenas atualizamos o endereco do service.
        if (this.clienteService.enderecos && this.clienteService.enderecos.length > 0) {
          const _enderecoSelecionado = this.clienteService.enderecos.filter(e => e.Rua == this.clienteService.enderecoSelecionadoSubject.Rua
            && e.Cep == this.clienteService.enderecoSelecionadoSubject.Cep
            && e.Numero == this.clienteService.enderecoSelecionadoSubject.Numero);

          // Atualizamos as demais informações
          if (_enderecoSelecionado[0] && _enderecoSelecionado.length > 0) {
            _enderecoSelecionado[0].Bairro = this.clienteService.enderecoSelecionadoSubject.Bairro != null ? this.clienteService.enderecoSelecionadoSubject.Bairro : _enderecoSelecionado.Bairro;
            _enderecoSelecionado[0].Complemento = this.clienteService.enderecoSelecionadoSubject.Complemento != null ? this.clienteService.enderecoSelecionadoSubject.Complemento : _enderecoSelecionado.Complemento;
            _enderecoSelecionado[0].Tipo = this.clienteService.enderecoSelecionadoSubject.Tipo != null ? this.clienteService.enderecoSelecionadoSubject.Tipo : _enderecoSelecionado.Tipo;
            _enderecoSelecionado[0].Municipio = this.clienteService.enderecoSelecionadoSubject.Municipio != null ? this.clienteService.enderecoSelecionadoSubject.Municipio : _enderecoSelecionado.Municipio;
            _enderecoSelecionado[0].Principal = this.clienteService.enderecoSelecionadoSubject.Principal != null ? this.clienteService.enderecoSelecionadoSubject.Principal : _enderecoSelecionado.Principal;

            this.clienteService.atualizaEndereco(_enderecoSelecionado[0]).subscribe((endereco) => {
              this.clienteService.atualizarEnderecoSelecionadoSubject(_enderecoSelecionado[0]);
            });
          }
          // Caso o endereço do cache não exista no banco de dados. Devemos inseri-lo
          else {
            this.clienteService.insereEndereco(this.clienteService.enderecoSelecionadoSubject).subscribe((endereco) => {
              this.clienteService.enderecoSelecionadoSubject.Codigo = endereco.value;
              this.clienteService.atualizarEnderecoSelecionadoSubject(this.clienteService.enderecoSelecionadoSubject);
            });
          }
        }
        // Se não existe nenhum endereço, cadastramos o mesmo
        else {
          this.clienteService.insereEndereco(this.clienteService.enderecoSelecionadoSubject).subscribe((endereco) => {
            this.clienteService.enderecoSelecionadoSubject.Codigo = endereco.value;
            this.clienteService.atualizarEnderecoSelecionadoSubject(this.clienteService.enderecoSelecionadoSubject);
          });
        }
      })
    }
    // Caso não tenha sido informado endereço antes de logar, tentamos obter o endereçod a api
    else {
      this.clienteService.listaEnderecos().subscribe(enderecos => {
        this.clienteService.enderecos = enderecos.value;

        if (this.clienteService.enderecos && this.clienteService.enderecos.length > 0) {
          const _endereco = this.clienteService.enderecos.filter(e => e.Principal)[0];
          this.clienteService.atualizarEnderecoSelecionadoSubject(_endereco);
        }
      })
    }
  }

  entrar() {
    const cliente = this.form.getRawValue() as LogarCliente;
    const senha = new CriptografaSenha().criptografaSenha(cliente.Senha);
    cliente.Senha = senha;

    this.loginService.loginCliente(cliente).subscribe((logado: ClienteLogado) => {
      if (logado && !logado.EmailConfirmado && !this.restaurante.PermitirLoginViaSMS){

        const otp: OTP = {
          Numero: logado.Celular,
          Token: '',
        };

        this.clienteService
          .enviarSMSParaVerificacaoIdentidade(otp)
          .subscribe(
            () => {
              document.body.style.position = 'fixed';

              const modalRef = this.modalService.open(ModalOtpComponent);
              modalRef.componentInstance.numeroCelular = logado.Celular;
              this.fecharModal();

              modalRef.result.then((e) => {
                document.body.style.position = '';
              }).catch((e) => {
                document.body.style.position = '';
              });   
            },
            (err) => {
              this.erro = 'Falha ao enviar OTP para o número informado.';
            }
          );
      } else if (logado && logado.EmailConfirmado) {
        this.sincronizarEndereco(logado);
        this.fecharModal();
      } else {
        this.emailPendenteConfirmacao = true;
      }
    }, (err) => this.erro = err);
  }

  enviarEmailConfirmacao() {
    this.toastr.error("Você receberá um e-mail para ativar sua conta", "", {
      positionClass: 'toast-bottom-left',
    });
    this.clienteService.confirmarEmail().subscribe();
    this.loginService.removerTokenConsumidor();
    this.fecharModal();
  }

  inserirEmailCelular() {
    this.entrarComEmailCelular = true;
    setTimeout(() => this.campoIdentificacao.nativeElement.focus());
  }

  voltar() {
    this.entrarComEmailCelular = false;
    this.form.get('Identificacao').markAsUntouched();
  }

  alterarVisibildadeSenha() {
    this.mostrarSenha = !this.mostrarSenha;
  }

  habilitarBotao(){
    this.emailCelularValido = true;
  }

  realizaLoginCadastro() {
    const ref = this;

    ref.usuario.Celular = ref.formRedeSocial.get('celular').value;
    ref.clienteService.insere(ref.usuario).subscribe(e => {
      ref.clienteService
        .loginRedeSocial({
          RedeSocial: ref.redeSocial,
          Token: ref.redeSocial == TipoRedeSocial.Facebook ?
            ref.usuario.TokenFacebook :
            ref.usuario.TokenGoogle,
          Email: ref.usuario.Email
        }).subscribe(e => {
          ref.loginService.salvaConsumidorCache(e, e.JWTTokenExpiracao);
          ref.loginService.salvarTokenConsumidor(e.JWTToken, e.JWTTokenExpiracao);

          ref.fecharModal();
        });
    });
  }

  validarEmail() {
    this.validaEmailCelular();
    if (this.emailCelularValido) {
      const identificacao = this.form.get('Identificacao').value;
      this.clienteService.verificaCadastroExiste(identificacao)
        .subscribe(existe => {
          if (existe.value) {

            const telefoneRegex = new RegExp('^[0-9]+$');
            if (telefoneRegex.test(identificacao) && this.restaurante.PermitirLoginViaSMS) {
              const otp: OTP = {
                Numero: identificacao,
                Token: '',
              };

              this.clienteService
                .enviarSMSParaVerificacaoIdentidade(otp)
                .subscribe(
                  () => {
                    document.body.style.position = 'fixed';

                    const modalRef = this.modalService.open(ModalOtpComponent);
                    modalRef.componentInstance.numeroCelular = identificacao;
                    this.fecharModal();
                    
                    modalRef.result.then((e) => {
                      document.body.style.position = '';
                    }).catch((e) => {
                      document.body.style.position = '';
                    });
                  },
                  (err) => {
                    this.erro = 'Falha ao enviar OTP para o número informado.';
                  }
                );
            }
            else {
              this.mostrarCampoSenha = true;
              setTimeout(() => this.campoSenha.nativeElement.focus(), 100);
            }
          } else {
            this.router.navigate(['cadastro'], { queryParams: { identificacao } });
            this.fecharModal();
          }
        });
    }
  }

  validaEmailCelular() {
    const emailRegex = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
    const telefoneRegex = new RegExp("^[0-9]+$");
    const emailCelular = this.form.get('Identificacao').value;

    this.emailCelularValido = emailCelular &&
      (emailRegex.test(emailCelular) || (emailCelular.length == 11 && telefoneRegex.test(emailCelular)));
  }

  get senhaInvalida() {
    return !this.form.get('Senha').value || this.form.get('Senha').value.length < 0;
  }

}
