<div id="modal-dialog" ngbAutofocus>

    <div class="modal_header">
        <h3>Realizar pagamento</h3>
        <button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
            <mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
        </button>
    </div>

    <div class="modal_body">
        <div>
            <h2>Escaneie o QRCode para pagar</h2>
            <div class="intrucoes">
                <h5>1. Acesse seu Internet Banking ou app de pagamentos</h5>
                <h5>2. Escolha pagar via pix.</h5>
                <h5>3. Aponte a câmera do seu celular para o QR Code abaixo.</h5>
            </div>
        </div>
        
        <div style="text-align: center;">
            <img [src]="qrCode" width="200px">
        </div>

        <div>
            <h5 style="margin-bottom: 0; margin-top: 1rem;">Ou copie o código abaixo:</h5>
            
            <div class="form-group" style="position: relative;">
                <input ngbAutofocus type="text" maxlength="20" disabled class="form-control" [value]="pix.QRCodeTexto">
                <div class="div-copiar-codigo">
                    <mat-icon class="material-icons-outlined mat-icon-button" [cdkCopyToClipboard]="pix.QRCodeTexto" (click)="copiado()">content_copy</mat-icon>
                </div>
            </div>
        </div>

        <div>
            <div style="display: flex;">
                <h5 class="col-8">Tempo restante para pagamento:</h5>
                <h3 class="col-4 timer">
                    {{ displayTempoEspera }}
                </h3>
            </div>
            
            <mat-progress-bar
                mode="buffer"
                [value]="tempoDecorrido"
            >
            </mat-progress-bar>
        </div>

        <div>
            <button class="btn_1 full-width" (click)="informarPagamento()">Informar pagamento</button>
        </div>
    </div>
</div>