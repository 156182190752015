import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from '../_services/login/login.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private loginService: LoginService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const loja = this.loginService.lojaLogadaToken;
    const isLoggedIn = loja && loja.AccessToken;

    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.loginService.tokenConsumidor ?
            this.loginService.tokenConsumidor.Token :
            loja.AccessToken}`
        }
      });
    }

    return next.handle(request);
  }
}