import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

const endpointUrl = 'DNE/';


@Injectable()
export class DneService extends BaseService {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public buscaDadosCep(cep: string) {
        return this.http.get<any>(this.API_URL + endpointUrl + `endereco/${cep}`);
    }

    public calculaDistanciaEntreEnderecos(codEnderecoOrigem: string, codEnderecoDestino: string, considerarTrafego: boolean) {
        return this.http.post<any>(this.API_URL + endpointUrl + `endereco/distancia`,
            {
                Origem: codEnderecoOrigem,
                Destino: codEnderecoDestino,
                ConsiderarTrafego: considerarTrafego
            });
    }


    public selecionaMunicipios(codigo: number, nome: string) {
        return this.http.get<any>(this.API_URL + endpointUrl + `municipio/${codigo}/${nome}`);
    }

    public selecionaEstados(codUf: number, nome: string) {
        return this.http.get<any>(this.API_URL + endpointUrl + `estado/${codUf}/${nome}`);
    }

    public listarTodosEstados() {
        return this.selecionaEstados(0, "%2520");
    }

    public selecionaCidadesPorCodEstado(codEstado: number) {
        return this.selecionaMunicipios(codEstado, "%2520");
    }

    public listaTipoEndereco(codigo: number) {
        return this.http.get<any>(`${this.API_URL + endpointUrl}tipo/${codigo}`);
    }
}
