import { Injectable } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

@Injectable()
export class SplashScreenStateService {
  private loadingText = "Carregando";
  private textSubject = new Subject<string>();
  private stopSubject = new Subject();

  subscribeText(onNext): Subscription {
    return this.textSubject.subscribe(onNext);
  }

  subscribeStop(onNext): Subscription {
    return this.stopSubject.subscribe(onNext);
  }

  stop() {
    this.stopSubject.next(false);
  }

  setTextLoading(text: string) {
    this.loadingText = text;
    this.textSubject.next(this.loadingText);
  }

  getTextLoading() {
    return this.loadingText;
  }
}
