import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioRoutingModule } from './usuario-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from 'ngx-mask';
import { DadosCadastraisComponent } from './dados-cadastrais/dados-cadastrais.component';
import { DneService } from '@app/_services/dne/dne.service';
import { ModalSelecaoCartaoComponent } from './cartao-credito/modal-selecao-cartao/modal-selecao-cartao.component';
import { ModalEditCartaoComponent } from './cartao-credito/modal-edit-cartao/modal-edit-cartao.component';
import { ModalSelecaoEnderecoEntregaComponent } from './endereco-entrega/modal-selecao-endereco-entrega/modal-selecao-endereco-entrega.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { MatIconModule } from '@angular/material/icon';
import { MeusPedidosComponent } from './meus-pedidos/meus-pedidos.component';
import { PedidoAnteriorComponent } from './meus-pedidos/pedido-anterior/pedido-anterior.component';
import { TipoPagamentoService } from '@app/_services/tipo-pagamento.service';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { MatMenuModule } from '@angular/material/menu';
import { ModalOtpComponent } from './modal-otp/modal-otp.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';
import { ModalCadastroEnderecoComponent } from './endereco-entrega/modal-cadastro-endereco/modal-cadastro-endereco.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImportTypeNode } from 'typescript'; 

@NgModule({
  declarations: [
    LayoutComponent,
    CadastroComponent,
    LoginComponent,
    DadosCadastraisComponent,
    ModalSelecaoEnderecoEntregaComponent,
    ModalEditCartaoComponent,
    ModalSelecaoCartaoComponent,
    AlterarSenhaComponent,
    MeusPedidosComponent,
    PedidoAnteriorComponent,
    ModalOtpComponent,
    ModalCadastroEnderecoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxMaskModule,
    MatIconModule,
    MatMenuModule,
    UsuarioRoutingModule,
    CreditCardDirectivesModule,
    NgOtpInputModule,
    CountdownModule,
    GoogleMapsModule,
    MatStepperModule,
    MatFormFieldModule
  ],
  providers: [
    DneService,
    TipoPagamentoService
  ]
})
export class UsuarioModule { }
