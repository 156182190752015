<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10">
      <div class="box_order_form">
        <form [formGroup]="form">
          <div class="head">
            <div class="title">
              <h3>Alterar senha</h3>
            </div>
          </div>
          <!-- /head -->
          <div style="text-align: center;">
            <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/esqueci-senha.svg" alt="Pessoa preocupada, sentada com a mão esquerda na cabeça e segurando um celular com a direita. À direita da imagem, um celular com um cadeado na tela, inscrições 'forgot password' e um botão para reset de senha." height="250px">
          </div>
          <div class="main" style="color: black;">
            <div class="row" style="place-content: center;" *ngIf="!telaResetarSenha">
              <div class="col-md-10 col-sm-10">
                <div class="form-group">
                  <label>Insira sua senha</label>
                  <input type="password" class="form-control" placeholder="Senha" formControlName="SenhaAtual">
                </div>
              </div>
            </div>
            <div class="row" style="place-content: center;">
              <div class="col-md-10 col-sm-10">
                <div class="form-group">
                  <label>Insira sua nova senha</label>
                  <input type="password" class="form-control" placeholder="Nova senha" formControlName="SenhaNova"
                    (blur)="validaNovaSenha()">
                </div>
              </div>
            </div>
            <div class="row" style="place-content: center;">
              <div class="col-md-10 col-sm-10">
                <div class="form-group">
                  <label>Confirmar nova senha</label>
                  <input type="password" class="form-control" placeholder="Confirmar senha"
                    formControlName="confirmarSenha" (blur)="validaNovaSenha()">
                  <div class="erro"> {{ senhaInvalida }}</div>
                </div>
              </div>
            </div>
            <div class="row" style="place-content: center;">
              <div class="col-md-10 col-sm-10">
                <div class="btn_1_mobile">
                  <button [disabled]="!podeSalvar" class="btn_1 full-width mb_5" (click)="salvarNovaSenha()">Salvar
                    senha</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="erro" *ngIf="mensagemErro">{{  mensagemErro }}</div>
      </div>

      <!-- /box_order_form -->
    </div>
    <!-- /col -->

  </div>
  <!-- /row -->
