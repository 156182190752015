<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10">
      <div class="box_order_form">
        <form [formGroup]="form">
          <div class="head">
            <div class="title">
              <h3>Detalhes pessoais</h3>
            </div>
          </div>
          <!-- /head -->
          <div class="main" style="color: black;">
            <div class="form-group">
              <label>Nome completo</label>
              <input class="form-control" placeholder="Nome completo" formControlName="Nome">
              <small *ngIf="nome.errors && nome.errors.nomeInvalido && (nome.dirty || nome.touched)">
                O nome está inválido
              </small>
            </div>
            <div class="form-group">
              <label>CPF/CNPJ</label>
              <input class="form-control" placeholder="Apenas números" formControlName="CPFCNPJ" [mask]="cpfCnpjMask" [validation]="false">
              <small *ngIf="cpfCnpj.errors && (cpfCnpj.dirty || cpfCnpj.touched)">
                O {{ cpfCnpj.value.length > 11 ? 'CNPJ' : 'CPF' }} está inválido
              </small>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>E-mail</label>
                  <input class="form-control" placeholder="E-mail" formControlName="Email">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Telefone</label>
                  <input class="form-control" placeholder="Telefone" formControlName="Celular" [mask]="mascaraCelular">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="btn_1_mobile">
                <button [disabled]="!podeSalvar" class="btn_1 full-width mb_5" (click)="atualizarCadastro()">
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="box_order_form">
        <div class="head">
          <div class="title">
            <h3>Endereço de entrega</h3>
          </div>
        </div>

        <div class="main" style="color: black;" class="endereco">
          <div *ngIf="enderecoSelecionado" style="display: flex;">
            <mat-icon class="icon material-icons-outlined">place</mat-icon>
            <div>{{ enderecoSelecionado.Tipo }}</div>
            <div class="endereco-formatado">
              {{ enderecoSelecionado.Rua.trim() }}, {{ enderecoSelecionado.Numero }}, {{ enderecoSelecionado.Bairro }}, {{ enderecoSelecionado.Municipio }}
            </div>
            <div>{{ enderecoSelecionado.Complemento }}</div>
          </div>

          <div *ngIf="!enderecoSelecionado" style="margin: 15px;">
            Selecione um endereço para concluir o pedido
          </div>
        </div>
        <div class="botoes">
          <button class="btn_1 small botao" 
            *ngIf="enderecosCadastrados && enderecosCadastrados.length > 0"
            (click)="abrirModalSelecaoEndereco()">
            Selecionar
          </button>
          <button class="btn_1 small botao" (click)="abrirModalAdicionarEndereco()">
            Adicionar
          </button>
        </div>
      </div>

      <div class="box_order_form">
        <div class="head">
          <div class="title">
            <h3>Cartão de crédito</h3>
          </div>
        </div>

        <div class="main" style="color: black;" class="endereco">
          <div *ngIf="cartaoSelecionado" style="position: relative;">
            <div class="endereco" style="display: flex; height: 70px;">
              <div>
                <img *ngIf="bandeiraCartaoSelecionado" class="imagem-pagamento" [src]="bandeiraCartaoSelecionado" alt="Ícone de forma de pagamento" />
                <mat-icon *ngIf="!bandeiraCartaoSelecionado" class="material-icons-outlined icon">credit_card</mat-icon>
              </div>
              <div>
                <h4>{{ cartaoSelecionado.Apelido ? cartaoSelecionado.Apelido : cartaoSelecionado.NumeroCartao }}</h4>  
              </div>
            </div>
          </div>

          <div *ngIf="!cartaoSelecionado" style="margin: 15px;">
            Selecione um cartão de crédito para concluir o pedido
          </div>
        </div>
        <div class="botoes">
          <button *ngIf="cartoesCadastrados && cartoesCadastrados.length > 0" class="btn_1 small botao"
            (click)="abrirModalSelecaoCartao()">
            Selecionar
          </button>
          <button class="btn_1 small botao" (click)="abrirModalAdicionarCartao()">
            Adicionar
          </button>
        </div>
      </div>

      <!-- /box_order_form -->
    </div>

    <!-- /box_order_form -->
  </div>
  <!-- /col -->

</div>
<!-- /row -->
