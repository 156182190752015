import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable()
export class MercadoriaService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  listaMercadoriasPorCodGrupo(codGrupo: number, codPraca: number, codTabelaPreco: number) {
    return this.http.get<any>(this.API_URL + 'mercadoria/grupo/' + codGrupo  + '/' + codPraca + '/' + codTabelaPreco);
  }

  buscaImagemMercadoria(codBasico: string = " ", codBarras: string = " ", tipoImagem: string = " ") {
    return this.http.get<any>(this.API_URL + `mercadoria/imagem/${codBasico}/${codBarras}/${tipoImagem}/`);
  }

  listaTodasMercadorias(codTabelaPreco: number) {
    return this.listaMercadoriasPorCodGrupo(0, 0, codTabelaPreco);
  }

  buscaMercadoriaPorCodigoBarras(codBarras: string) {
    return this.http.get<any>(this.API_URL + 'mercadoria/codigo/' + codBarras);
  }

  listaVariacoesMercadoria(codMercadoria: string, codBarras: string = "%20",  codTabelaPreco: number) {
    return this.http.get<any>(this.API_URL + `mercadoria/variacao/${codMercadoria}/${codBarras}/${codTabelaPreco}`);
  }

  listaPreparosPorCodigoMercadoria(codMercadoria: string) {
    return this.http.get<any>(this.API_URL + 'mercadoria/preparo/' + codMercadoria);
  }

  listaGrupoPreparosPorCodigoMercadoria(codMercadoria: string) {
    return this.http.get<any>(this.API_URL + 'GrupoPreparo/GrupoPreparo/' + codMercadoria);
  }

  listaImagemPreparo(codBasico: string) {
    return this.http.get<any>(this.API_URL + 'mercadoria/preparo/imagem' + codBasico);
  }

  listaComposicoesPorCodigoMercadoria(codMercadoria: string) {

  }
}
