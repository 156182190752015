import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { filter } from 'rxjs/operators';
import { PedidoItem } from '@app/_models/pedido/pedido';

@Component({
  selector: 'app-dropdown-itens-carrinho',
  templateUrl: './dropdown-itens-carrinho.component.html',
  styleUrls: ['./dropdown-itens-carrinho.component.scss']
})
export class DropdownItensCarrinhoComponent   {

  telaConclusaoPedido: boolean;

  constructor(
    private sacolaService: SacolaService, 
    private router: Router,
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.telaConclusaoPedido = event.url == '/pedido';
      });
  }

  abrirCarrinho() {
    this.router.navigate(['/pedido']);
  }

  imagemURL(item: any) {
    if (item.mercadoria.Imagens && item.mercadoria.Imagens.length > 0) {
      const imagem = item.mercadoria.Imagens.find((i) => i.Tipo === 'Principal');
      if (imagem && imagem.ImagemBase64) {
        return `data:image/png;base64,${imagem.ImagemBase64}`;
      } else if (imagem && imagem.Imagem) {
        return imagem.Imagem;
      }
    }

    return false;
  }

  removerItemCarrinho(codigoItemCarrinho: number) {
    this.sacolaService.removerMercadoria(codigoItemCarrinho);
    this.sacolaService.atualizarTaxaEntrega();
  }

  aumentarQuantidadeItemCarrinho(item: PedidoItem){
    var quantidade = item.quantidade + 1;
    this.sacolaService.editarMercadoriaPedido(item.codigoItem, item.mercadoria, quantidade, item.preparos, item.variacao, item.composicoes, item.observacao);
    this.sacolaService.atualizarTaxaEntrega();
  }

  diminuirQuantidadeItemCarrinho(item: PedidoItem){
    var quantidade = item.quantidade - 1;
    this.sacolaService.editarMercadoriaPedido(item.codigoItem, item.mercadoria, quantidade, item.preparos, item.variacao, item.composicoes, item.observacao);
    this.sacolaService.atualizarTaxaEntrega();
  }

  get itensCarrinho() {
    return this.sacolaService.listarMercadorias();
  }

  get valorTotal() {
    return this.sacolaService.valorTotal;
  }

  get totalItensSacola() {
    return this.sacolaService.totalItensSacola;
  }
}
