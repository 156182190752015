import { ImagemMercadoria } from './imagem-mercadoria';
import { AtributoMercadoria } from './atributo-mercadoria';

export class DetalhesMercadoria {
    
    Codigo: string;
    CodBarras: string;
    CodBarrasFornec: string;
    Estado: string;
    Tipo: string;
    TipoItemVenda: string;
    TipoComposta: string;
    NomeCurto: string;
    NomeLongo: string;
    Unidade: string;
    CodVariacao1: number;
    Variacao1: string;
    CodVariacao2: number;
    Variacao2: string;
    PrecoVenda: number;
    DescontoVenda: number;
    CobrarTaxaServico: number;
    AcionaBalanca: number;
    Arredondamento: string;
    Atributos: AtributoMercadoria [];
    ImprimeFicha: number;
    CampoLivre1: string;
    CampoLivre2: string;
    CampoLivre3: string;
    CampoLivre4: string;
    CampoLivre5: string;
    CodGrupo: number;
    Grupo: string;
    CodSubgrupo: number;
    Subgrupo: string;
    CodLoja: number;
    CodImpressora: number;
    DataCriacao: Date;
    DataAlteracao: Date;
    Fracionario: boolean;
    UsuarioCriacao: string;
    UsuarioAlteracao: string;
    Imagens: ImagemMercadoria [];
    ImagemTipo: string;
    Observacao: string;
    Estoque: number;
    PossuiCampanha: boolean;
    VenderInternet: boolean;
    VenderAutoAtendimento: boolean;
    VenderEntregaOnline: boolean;
    RefCount: number
}