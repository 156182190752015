import { Injectable } from "@angular/core";
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class ModalAlertService {

    constructor() { }


    public mostraAlerta(texto: string, tipo: 'success' | 'warning', titulo: string = "Sucesso",
        onConfirmedCallback: Function = () => { },
        onDismissedCallback: Function = () => { },
        mostrarBotaoCancelar = false,
        textoBotaoCancelar = 'Cancelar', textoBotaoConfirmar = 'Ok') {
        Swal.fire({
            title: titulo,
            text: texto,
            icon: tipo,
            showCancelButton: mostrarBotaoCancelar,
            confirmButtonText: textoBotaoConfirmar,
            cancelButtonText: textoBotaoCancelar,
        }).then((confirmado) => {
            if(confirmado.isDismissed && !mostrarBotaoCancelar) {
                onConfirmedCallback();
            }
            
            confirmado.value ?
                onConfirmedCallback() : onDismissedCallback();
        });
    }
}
