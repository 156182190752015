<div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-10">
        <div class="box_order_form">
          <div class="head">
            <h3>Fila de Espera</h3>
          </div>
          <div class="main" style="color: black;">
            <div *ngIf="filaEspera.Estado == 'Aguardando'" class="row">

              <div class="col-12">
                <div class="card nome">
                  <mat-icon *ngIf="filaEspera.TempoMedioEspera <= 0" class="icon-update material-icons-outlined">
                    update
                  </mat-icon>
                  <mat-spinner *ngIf="filaEspera.TempoMedioEspera > 0">
                  </mat-spinner>
                  <h3 *ngIf="filaEspera.TempoMedioEspera > 0" class="tempo-espera">
                    {{ displayTempoEspera }}
                  </h3>
                  <h3 class="nome-cliente">
                    {{ filaEspera.Cliente }}
                  </h3>
                </div>  
              </div>

              <div class="col-md-6">
                <div class="card">
                  <mat-icon class="material-icons-outlined">
                    local_activity
                  </mat-icon>
                  <h3>
                    {{ "Posição na fila: " + filaEspera.Posicao + "°" }}
                  </h3> 
                </div>
              </div>

              <div class="col-md-6">
                <div class="card">
                  <mat-icon class="material-icons-outlined">
                    groups
                  </mat-icon>
                  <h3>
                    {{ "Pessoas na mesa: " + filaEspera.Pessoas }}
                  </h3>
                </div>
              </div>

            </div>

            <div *ngIf="filaEspera.Estado == 'Aguardando cliente'">
              <div class="col-12">
                <div class="card nome">
                  <mat-icon class="icon-large material-icons-outlined" style="color: #f8bb86;">
                    error_outline
                  </mat-icon>
                  <h3>
                    Sua mesa já está pronta!
                  </h3>
                  <h3>
                    Por favor, dirija-se ao balcão/atendimento.
                  </h3>
                </div>  
              </div>
            </div>

            <div *ngIf="filaEspera.Estado == 'Removido'">
              <div class="col-12">
                <div class="card nome">
                  <mat-icon class="icon-large material-icons-outlined" style="color: red;">
                    cancel
                  </mat-icon>
                  <h3>
                    Atendimento cancelado!
                  </h3>
                  <h3>
                    Algo errado? Entre em contato com o restaurante.
                  </h3>
                </div>  
              </div>
            </div>

            <div *ngIf="filaEspera.Estado == 'Atendido'">
              <div class="col-12">
                <div class="card nome">
                  <mat-icon class="icon-large material-icons-outlined" style="color: green;">
                    check_circle
                  </mat-icon>
                  <h3>
                    Atendimento concluído!
                  </h3>
                  <h3>
                    Esperamos recebê-lo novamente em breve.
                  </h3>
                </div>  
              </div>
            </div>
            
            <div>
              <div class="row botoes">
                <div *ngIf="filaEspera.Estado == 'Aguardando' || filaEspera.Estado == 'Aguardando cliente'" class="col-6">
                  <button class="btn_1 botao" (click)="sairFila()">
                    {{
                      filaEspera.Estado == 'Aguardando' ? 'Sair da fila' : 'Cancelar'
                    }}
                  </button>
                </div>
                
                <div [ngClass]="filaEspera.Estado == 'Aguardando' || filaEspera.Estado == 'Aguardando cliente' ? 'col-6': 'col-12'">
                  <button class="btn_1 botao" (click)="voltarCardapio()">
                    Cardápio
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>