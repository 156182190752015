import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor() { }
  getTenantForHostname(hostname: string): string {
    return hostname.split(".")[0];
  }

  getTenant(): string {
    return window.location.href;
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    return headers.append("X-Tenant-ID", this.getTenant());
  }
}

