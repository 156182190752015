import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable()
export class TurnoService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  listaTurno(codigoTurno: number) {
    return this.http.get<any>(this.API_URL + '/Turno/Dias/' + codigoTurno);
  }
}