import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoginService } from '../_services/login/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError(err => {
        const error = err.error.error?.message || err.error.message || err.message || err.statusText;

        if (err.status === 401 || error.includes('The specified session has expired due to inactivity or was an invalid Session ID')) {
          return this.loginService.autenticaLoja()
            .pipe(switchMap(e => {
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${this.loginService.tokenConsumidor ?
                    this.loginService.tokenConsumidor.Token :
                    this.loginService.lojaLogadaToken.AccessToken}`
                }
              });

              return next.handle(request);
            }))
        } else {
          return throwError(error);
        }
      }))
  }
}
