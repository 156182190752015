export class DetalhesContida {
    CodBarras: string;
    DescontoPorcentagem: number;
    DescontoValor: number;
    Quantidade: number;
    RefCount: number;
    TipoItemVenda: string;
    Total: number;   
    Valor: number;
    ValorComDesconto: number;
}