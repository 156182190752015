import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-carregando',
  templateUrl: './carregando.component.html',
  styleUrls: ['./carregando.component.css']
})
export class CarregandoComponent implements OnInit {

  @Input() carregandoSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  carregando: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.carregandoSubject.subscribe(carregando => this.carregando = carregando);
  }

}
