<div id="modal-dialog" >

	<div class="modal_header">
		<h3>Adicionar cartão de crédito</h3>
		<button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
			<mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
		</button>
	</div>

	<div class="modal_body">
		<div style="text-align: center;">
			<img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/cartao-credito.svg" alt="Pessoa segurando uma carteira e retirando um cartão de crédito." width="250px">
		</div>

		<div class="bandeiras">
			<div class="cobranca">
				Faremos uma pequena cobrança com devolução automática
			</div>
			<div>
				<img width="40" height="30" *ngFor="let bandeira of bandeiras" [src]="bandeira.Imagem"
				[class.bandeira-selecionada]="!bandeira.Selecionado" [alt]="'Bandeira de cartão' + bandeira.Nome">
			</div>
		</div>

		<form [formGroup]="form" style="color: black;">
			
			<div class="form-group">
				<label>Apelido do cartão</label>
				<input ngbAutofocus type="text" class="form-control" id="apelido" name="apelido"
					placeholder="Como você quer chamar este cartão?" formControlName="Apelido">
			</div>

			<div class="form-group">
				<label>Nome no cartão</label>
				<input type="text" class="form-control" id="name_card_order" name="name_card_order"
					placeholder="Como está no cartão" formControlName="Nome">
			</div>

			<div class="form-group">
				<label>Número do cartão</label>
				<input id="cc-number" type="tel" autocomplete="cc-number" ccNumber class="form-control"
					placeholder="Apenas números" formControlName="Numero" (blur)="buscarBandeira()">
			</div>

			<div class="row">
				<div class="col-md-6">
					<label>Data de expiração</label>
					<div class="row">
						<div class="col-md-6 col-6" style="padding-right: 5px;">
							<div class="form-group">
								<input type="tel" id="expire_month" name="mesDataExpiracao" class="form-control" placeholder="mm"
									formControlName="Mes" mask="M0" [leadZeroDateTime]="true">
							</div>
						</div>
						<div class="col-md-6 col-6" style="padding-left: 5px;">
							<div class="form-group">
								<input type="tel" id="expire_year" name="anoDataExpiracao" class="form-control" placeholder="aaaa"
									formControlName="Ano" mask="0000" [leadZeroDateTime]="true" #ano>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-8 col-sm-12">
					<div class="form-group">
						<label>CVV</label>
						<div class="row">
							<div class="col-md-4 col-6">
								<div>
									<input type="tel" id="ccv" formControlName="Seguranca" name="ccv" class="form-control"
									placeholder="CCV" ccCVC>
								</div>
							</div>
							<div class="col-md-8 col-8">
								<img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/icon_ccv.gif" width="50" height="29" alt="Cartão de crédito com destaque para o Código de Verificação (CVV) envolto por um quadrado vermelho." style="margin-right: 1rem;">
								<small>Últimos 3 digitos</small>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-12 col-md-6">
					<label class="container_check">Usar esse cartão por padrão
						<input type="checkbox" formControlName="Principal" (change)="favoritar()">
						<mat-icon class="icon_favorito material-icons-outlined" [hidden]="favorito">favorite_border</mat-icon>
						<mat-icon class="icon_favorito material-icons-outlined" [hidden]="!favorito">favorite</mat-icon>
					</label>
				</div>
			</div>

			<div *ngIf="mensagemErro">{{ mensagemErro }}</div>
		</form>
		<button [disabled]="!cartaoValido" (click)="salvarCartao()" class="btn_1 full-width">Salvar</button>
	</div>
</div>
