import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/_services/login/login.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
  }

  expandir(titulo: HTMLElement, panel: HTMLElement) {
    if (titulo.className.includes("opened")) {
      titulo.className = "";
      panel.className = "dont-collapse-sm links collapse";
    } else {
      panel.className = "dont-collapse-sm links collapse show";
      titulo.className = "opened collapsed";
    }
  }

  get restaurante() {
    return this.loginService.lojaLogada;
  }
}
