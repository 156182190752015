import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NovoPedido } from '@app/_models/pedido/novo-pedido';
import { BaseService } from '../base/base.service';

@Injectable()
export class PedidoService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  realizarPedido(pedido: NovoPedido) {
    return this.http.post<any>(this.API_URL + 'entrega', pedido);
  }

  criarPagamento(pedido: NovoPedido ) {
    return this.http.post<any>(this.API_URL + 'Entrega/transacao', pedido);
  }

  consultarPagamento( CodTransacao: string) {
    return this.http.get<any>(this.API_URL + 'Entrega/transacao/' + CodTransacao);
  }

  listaPedidosResumidos(codLoja: number, dias: number) {
    return this.http.post<any>(this.API_URL + 'entrega/resumido', {CodLoja: codLoja, Dias: dias});
  }

  retornaInformacoesPedido(codigoPedido: string) {
    return this.http.get<any>(this.API_URL + 'entrega?CodEntrega=' + codigoPedido);
  }
}
