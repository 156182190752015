import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { Endereco } from '../endereco/endereco';
import { DetalhesMercadoria } from '../mercadoria/detalhes-mercadoria';
import { PreparoMercadoria } from '../mercadoria/preparo-mercadoria';
import { Variacao } from '../variacao/variacao';
import { v4 as uuidv4 } from 'uuid';
import { Entrega } from '../endereco/entrega';

export class Pedido {

    constructor() {
        this.mercadorias = [];
        this.DataCriacao = new Date();
        this.Codigo = uuidv4();
    }

    mercadorias: PedidoItem[];
    DataPrevisao: any;
    DataCriacao: any;
    Subtotal: any;

    endereco: Endereco;
    formaPagamento;

    TaxaEntrega: number = 0;
    Agendado: boolean;
    CodEnderecoConsumidor: number;
    Codigo: string;
    Desconto: number;
    DetalheEntregaRetira: string;
    ModoEntregaRetira: FormaRetiradaPedido;
    Observacao: string;
    Retira: boolean;
    SubTotal: number;
    TempoPreparo: number;
    Total: number;
    entrega: Entrega;
}

export class PedidoItem {

    constructor(mercadoria: DetalhesMercadoria, quantidade: number, preparos?: PreparoMercadoria[], variacao?: Variacao, composicoes?: any[], observacao?: string) {
        this.mercadoria = mercadoria;
        this.quantidade = quantidade;
        this.preparos = preparos;
        this.variacao = variacao;
        this.composicoes = composicoes;
        this.observacao = observacao;
    }

    codigoItem: number;
    mercadoria: DetalhesMercadoria;
    quantidade: number;
    variacao: Variacao;
    composicoes: any[];
    preparos: PreparoMercadoria[] = [];
    observacao: string;
}

