import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@app/_services/login/login.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  constructor(
    private router: Router,
    private usuarioService: LoginService
  ) {
    if (this.usuarioService.lojaLogada) {
      this.router.navigate(['/']);
    }
  }
}