import { Component, Input, OnInit } from '@angular/core';
import { Endereco } from '@app/_models/endereco/endereco';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-entrar-contato-restaurante',
  templateUrl: './modal-entrar-contato-restaurante.component.html',
  styleUrls: ['./modal-entrar-contato-restaurante.component.scss']
})
export class ModalEntrarContatoRestauranteComponent implements OnInit {

  @Input() restaurante: DetalhesLoja;

  constructor(public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }
  fecharModal() {
    this.activeModal.close();
  }

}
