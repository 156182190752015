import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalEditCartaoComponent } from '@app/_components/cliente/cartao-credito/modal-edit-cartao/modal-edit-cartao.component';
import { ModalSelecaoCartaoComponent } from '@app/_components/cliente/cartao-credito/modal-selecao-cartao/modal-selecao-cartao.component';
import { ModalSelecaoEnderecoEntregaComponent } from '@app/_components/cliente/endereco-entrega/modal-selecao-endereco-entrega/modal-selecao-endereco-entrega.component';
import { ModalMensagemComponent } from '@app/_components/compartilhados/modal-mensagem/modal-mensagem.component';
import { FormaPagamento } from '@app/_enums/forma-pagamento';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { DetalhesCartaoCredito } from '@app/_models/cliente/cartao/detalhes-cartao-credito';
import { Endereco } from '@app/_models/endereco/endereco';
import { TipoPagamento } from '@app/_models/tipo-pagamento/tipo-pagamento';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { IndoorService } from '@app/_services/indoor.service';
import { LoginService } from '@app/_services/login/login.service';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { TipoPagamentoService } from '@app/_services/tipo-pagamento.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ModalTrocoComponent } from './modal-troco/modal-troco.component';
import { ModalCadastroEnderecoComponent } from '@app/_components/cliente/endereco-entrega/modal-cadastro-endereco/modal-cadastro-endereco.component';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.scss']
})
export class PedidoComponent implements OnInit {

  form: UntypedFormGroup;
  enderecoSelecionado: Endereco;
  cartaoSelecionado: DetalhesCartaoCredito;
  bandeiraCartaoSelecionado: string;

  cliente;

  enderecosCadastrados: Endereco[] = [];
  cartoesCadastrados: DetalhesCartaoCredito[];

  enderecoLoja: string;

  tituloEndereco: string = "Endereço de entrega";
  retirarNaLoja: boolean = false;

  cartaoRemovido = new BehaviorSubject<DetalhesCartaoCredito>(null);
  enderecoRemovido = new BehaviorSubject<Endereco>(null);
  atualizaListaCartoes = new BehaviorSubject<DetalhesCartaoCredito>(null);
  atualizaListaEnderecos = new BehaviorSubject<Endereco>(null);

  tiposPagamento: TipoPagamento[];
  pagarNaEntrega = true;
  troco = 0;

  restaurantePermitePagamentoEntrega: boolean;
  restaurantePermitePagamentoOnline: boolean;
  pagamentoViaCartao: boolean;

  tipoPix: TipoPagamento;
  tipoCartao: TipoPagamento;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public loginService: LoginService,
    private clienteService: ClienteService,
    public indoorService: IndoorService,
    private tipoPagamentoService: TipoPagamentoService,
    public sacolaService: SacolaService,
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.enderecoLoja = loginService.lojaLogada.EnderecoCompleto;

    this.criaForm();
    this.listaCartoes();
    this.listaEnderecos();
    this.removerCartao();
    this.removeEndereco();

    switch (loginService.lojaLogada.ModoPagamento) {
      case "Pagamento na entrega":
        this.sacolaService.formaPagamento = FormaPagamento.Entrega;
        this.restaurantePermitePagamentoEntrega = true;
        if(loginService.lojaLogada.HabilitarPix){
          this.restaurantePermitePagamentoOnline = true;
        }
        break;
      case "Pagamento online e na entrega":
        this.pagamentoViaCartao = true;
        this.restaurantePermitePagamentoOnline = true;
        this.restaurantePermitePagamentoEntrega = true;
        break;
      default:
        this.pagamentoViaCartao = true;
        this.restaurantePermitePagamentoOnline = true;
        this.sacolaService.formaPagamento = FormaPagamento.Loja;
        break;
    }

    if(loginService.lojaLogada.HabilitarPix){
      this.tipoPix = {
        Codigo: 26,
        Nome: "Pix",
        Descricao: "Pague com Pix",
        PermitirTroco: false,
        ConsultarCredito: false,
        RefCount: 0,
        Imagem: null,
        Online: true
      }
    }

    if(this.restaurantePermitePagamentoOnline){
      this.tipoCartao = {
        Codigo: 23,
        Nome: "Cartão de crédito",
        Descricao: "Pague com cartão de crédito",
        PermitirTroco: false,
        ConsultarCredito: true,
        RefCount: 0,
        Imagem: null,
        Online: true
      }
    }

    this.atualizaListaEnderecos.subscribe((e: Endereco) => {
      if (e) {
        this.enderecoSelecionado = e;
        this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
        this.listaEnderecos();
      }
    })

    this.atualizaListaCartoes.subscribe(e => {
      if (e) {
        this.cartaoSelecionado = e;
        this.bandeiraCartao(this.cartaoSelecionado.Bandeira);
        this.listaCartoes();
      }
    })

    if (this.indoor && this.indoorService.numeroMesa) {
      this.tituloEndereco = "Receber pedido";
    } else if (this.indoor && !this.indoorService.numeroMesa) {
      this.tituloEndereco = "Endereço da loja";
    } else {
      this.clienteService.formaRetirada.subscribe(forma => {
        this.retirarNaLoja = forma == FormaRetiradaPedido.Balcao;

        if (forma == FormaRetiradaPedido.Balcao) {
          this.tituloEndereco = "Endereço de retirada";
        } else {
          this.tituloEndereco = "Endereço de entrega";
          if (this.enderecosCadastrados && this.enderecosCadastrados.length > 0) {
            this.clienteService.enderecoSelecionado.subscribe(e => {
              if (!this.enderecoSelecionado) {
                this.enderecoSelecionado = e ? e : this.enderecosCadastrados.filter(e => e.Principal)[0] || this.enderecosCadastrados[0];
              } else if (e.Codigo != this.enderecoSelecionado.Codigo) {
                this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
              }
            });
          }
        }
      });
    }

    this.tipoPagamentoService.listaFormaPagamento().subscribe(e => {
      this.tiposPagamento = e.value;
      this.tipoPagamentoService.listaImagensFormaPagamento().subscribe(result => {
        let imagens = result.value;
        this.tiposPagamento.forEach(tipo => {
          let imagem = imagens.find(i => i.Codigo == tipo.Codigo && i.Imagem);
          if (imagem) {
            tipo.Imagem = "data:image/png;base64," + imagem.Imagem;
          }
        });
      });
    });
  }

  ngOnInit(): void {
    this.loginService.cliente.subscribe((cliente: any) => {
      if (cliente) {
        this.cliente = cliente;
      }
    });

    this.clienteService.enderecoAtualizado.subscribe(() => {
      this.listaEnderecos();
      this.changeDetectorRef.detectChanges();
    });
  }

  criaForm() {
    this.form = this.formBuilder.group({
      formaPagamento: [],
      nomeCartao: [],
      numeroCartao: [],
      mesDataExpiracao: [],
      anoDataExpiracao: [],
      cvv: []
    });
  }

  abrirModalSelecaoEndereco() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalSelecaoEnderecoEntregaComponent, { size: 'xl', scrollable: true });
    modalRef.componentInstance.enderecosCadastrados = this.enderecosCadastrados;
    modalRef.componentInstance.enderecoRemovido = this.enderecoRemovido;

    modalRef.result.then((endereco: Endereco) => {
      this.listaEnderecos();

      if (endereco) {
        this.enderecoSelecionado = endereco;
        this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
      } else {
        this.enderecoSelecionado = null;
      }
      document.body.style.position = '';
    }).catch((err) => { 
      document.body.style.position = '';
    });
  }

  abrirModalAdicionarEndereco() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalCadastroEnderecoComponent, { size: 'xl' });
    modalRef.componentInstance.step = 1;

    modalRef.result.then((endereco: Endereco) => {
      this.listaEnderecos();

      if (endereco) {
        this.enderecoSelecionado = endereco;
        this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
      } else {
        this.enderecoSelecionado = null;
      }
      document.body.style.position = '';
    }).catch((err) => { 
      document.body.style.position = '';
    });
  }

  abrirModalSelecaoCartao() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalSelecaoCartaoComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.cartoesCadastrados = this.cartoesCadastrados;
    modalRef.componentInstance.cartaoRemovido = this.cartaoRemovido;

    modalRef.result.then((cartao: DetalhesCartaoCredito) => {
      this.listaCartoes();
      if (cartao) {
        this.cartaoSelecionado = cartao;
        this.bandeiraCartao(this.cartaoSelecionado.Bandeira);
        this.sacolaService.cartaoSelecionado = this.cartaoSelecionado;
      }
      document.body.style.position = '';
    }).catch((err) => { 
      document.body.style.position = '';
    });
  }

  abrirModalAdicionarCartao() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalEditCartaoComponent, { size: 'lg', scrollable: true });

    modalRef.result.then((cartao: DetalhesCartaoCredito) => {
      this.listaCartoes();

      if (cartao) {
        this.cartaoSelecionado = cartao;
        this.bandeiraCartao(this.cartaoSelecionado.Bandeira);
        this.sacolaService.cartaoSelecionado = this.cartaoSelecionado;
      }
      
      document.body.style.position = '';
    }).catch((err) => {
      document.body.style.position = '';
    });
  }

  private removerCartao() {
    this.cartaoRemovido.subscribe(cartaoRemovido => {
      if (cartaoRemovido) {
        if (this.cartaoSelecionado.Codigo == cartaoRemovido.Codigo) {
          this.cartaoSelecionado = null;
          this.bandeiraCartaoSelecionado = null;
          this.sacolaService.cartaoSelecionado = null;
        }
        this.listaCartoes();
      }
    });
  }

  removeEndereco() {
    this.enderecoRemovido.subscribe(endereco => {
      if (endereco) {
        if (this.enderecoSelecionado.Codigo == endereco.Codigo) {
          this.enderecoSelecionado = null;
          this.sacolaService.selecionarEndereco(null);
        }
        this.listaEnderecos();
      }
    })
  }

  private listaEnderecos() {
    this.clienteService.listaEnderecos().subscribe(enderecos => {
      this.enderecosCadastrados = enderecos.value;
      if (this.enderecosCadastrados && this.enderecosCadastrados.length > 0 && !this.enderecoSelecionado) {
        this.enderecoSelecionado = this.enderecosCadastrados.filter(e => e.Principal)[0]
          || this.enderecosCadastrados[0];

        this.sacolaService.selecionarEndereco(this.enderecoSelecionado);
      }
      this.changeDetectorRef.detectChanges();
    })
  }

  public formaPagamentoAlterada(aba) {
    this.troco = 0;
    this.sacolaService.valorTroco = 0;
    if (aba.tab.textLabel == "Pague na entrega") {
      this.pagarNaEntrega = true;
      this.sacolaService.formaPagamento = FormaPagamento.Entrega;
    } else {
      this.pagarNaEntrega = false;
      this.sacolaService.formaPagamento = FormaPagamento.Loja;
    }
  }

  private listaCartoes() {
    this.clienteService.listaCartaoCredito().subscribe(cartoes => {
      this.cartoesCadastrados = cartoes.value;
      if (this.cartoesCadastrados && this.cartoesCadastrados.length > 0 && !this.cartaoSelecionado) {
        this.cartaoSelecionado
          = this.cartoesCadastrados.filter(e => e.Principal)[0] || this.cartoesCadastrados[0];

        this.bandeiraCartao(this.cartaoSelecionado.Bandeira);
        this.sacolaService.cartaoSelecionado = this.cartaoSelecionado;
      }
    });
  }

  formaPagamentoSelecionada(tipo) {
    this.sacolaService.tipoPagamentoSelecionado = tipo;
    this.troco = 0;
    this.sacolaService.valorTroco = 0;

    if (tipo.PermitirTroco) {
      document.body.style.position = 'fixed';
      
      const modalRef = this.modalService.open(ModalMensagemComponent);
      modalRef.componentInstance.titulo = tipo.Nome;
      modalRef.componentInstance.centralizarTexto = true;
      modalRef.componentInstance.mensagem = "Você vai precisar de troco?";
      modalRef.componentInstance.textoBotaoConfirmar = "Sim";
      modalRef.componentInstance.mostrarBotaoCancelar = true;
      modalRef.componentInstance.textoBotaoCancelar = "Não";

      modalRef.result.then((precisaTroco: boolean) => {
        if (precisaTroco) {
          const trocoModalRef = this.modalService.open(ModalTrocoComponent);
          trocoModalRef.result.then((troco: number) => {
            if (troco) {
              this.troco = troco;
              this.sacolaService.valorTroco = this.troco;
            }
          }).catch((err) => {});
        }
        document.body.style.position = '';
      }).catch((err) => {
        document.body.style.position = '';
      });
    }
  }

  bandeiraCartao(bandeira) {
    this.tipoPagamentoService.listaBandeiras().subscribe(e => {
      this.bandeiraCartaoSelecionado = e.value.find(b => b.Nome == bandeira).Imagem;
    });
  }

  get indoor() {
    return this.indoorService.indoor;
  }

  get numeroMesa() {
    if (this.indoorService.numeroMesa) {
      return this.indoorService.numeroMesa;
    }
    return null;
  }

}
