import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/_services/login/login.service';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IndoorService } from '@app/_services/indoor.service';

@Component({
  selector: 'app-menu-indoor',
  templateUrl: './menu-indoor.component.html',
  styleUrls: ['./menu-indoor.component.scss']
})
export class MenuIndoorComponent implements OnInit {

  constructor(
    private loginService: LoginService,
    private sacolaService: SacolaService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private indoorService: IndoorService
  ) { }

  ngOnInit(): void {
    
  }

  verSacola(){
    if(this.quantidadeItensSacola > 0){
      this.router.navigate(['/pedido']);
    } else {
      this.toastr.error("A sacola está vazia.", "Não foi possível concluir o pedido", {
        positionClass: 'toast-bottom-left',
      });
    }
  }

  get quantidadeItensSacola() {
    return this.sacolaService.totalItensSacola;
  }

  get modalAberto() {
    return this.modalService.hasOpenModals();
  }

  get numeroMesa() {
    return this.indoorService.numeroMesa;
  }
}