import { Injectable } from '@angular/core';

import { LoginService } from '@app/_services/login/login.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RestauranteResolver  {
    constructor(private loginService: LoginService) { }

    resolve(): Observable<any> | Observable<never> {

        return !this.loginService.lojaEstaAutenticada ?
            this.loginService.autenticaLoja() :
            of(this.loginService.lojaLogada);
    }
}
