import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartaoCredito } from '@app/_models/cliente/cartao/create-cartao-credito';
import { Bandeira } from '@app/_models/tipo-pagamento/bandeira';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { TipoPagamentoService } from '@app/_services/tipo-pagamento.service';
import { AnoValidator } from '@app/_validators/ano-validator';
import { MesValidator } from '@app/_validators/mes-validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardValidators } from 'angular-cc-library';

@Component({
  selector: 'app-modal-edit-cartao',
  templateUrl: './modal-edit-cartao.component.html',
  styleUrls: ['./modal-edit-cartao.component.scss']
})
export class ModalEditCartaoComponent implements OnInit {

  form: UntypedFormGroup;

  cartaoCredito: CartaoCredito;
  estaAtualizando = false;
  favorito = false;

  mensagemErro;
  bandeiras: Bandeira[];
  @ViewChild('ano') public campoAno: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private clienteService: ClienteService,
    public activeModal: NgbActiveModal,
    private tipoPagamentoService: TipoPagamentoService
  ) {
    this.listaBandeiras();

    this.form = this.formBuilder.group({
      Apelido: ['', []],
      Nome: ['', [Validators.required]],
      Numero: ['', [CreditCardValidators.validateCCNumber]],
      Mes: ['', [Validators.required, Validators.maxLength(2), MesValidator()]],
      Ano: ['', [Validators.required, Validators.maxLength(4), AnoValidator()]],
      Seguranca: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      Principal: [false]
    });

    this.form.get('Mes').valueChanges.subscribe(e => {
      if(e.length == 2) {
        this.campoAno.nativeElement.focus();
      }
    })
  }

  ngOnInit(): void { }

  listaBandeiras() {
    this.tipoPagamentoService
      .listaBandeiras()
      .subscribe(e => {
        this.bandeiras = e.value;
      });
  }

  buscarBandeira() {
    let numeroCartao = this.form.get('Numero').value;
    numeroCartao = numeroCartao.replace(/\s/g, '');

    if (numeroCartao && numeroCartao.length == 16) {
      this.tipoPagamentoService
        .buscaBandeiraPorNumeroCartao(numeroCartao)
        .subscribe(e => {
          this.bandeiras.forEach((b, i) =>
            this.bandeiras[i].Selecionado = b.Nome == e.Nome
          )
        });
    }
  }

  fecharModal(codigoCartao?: string) {
    this.clienteService.listaCartaoCredito().subscribe(cartoes => {
      const cartaoCriado = cartoes.value.filter(e => e.Codigo == codigoCartao);
      this.activeModal.close(cartaoCriado[0]);
    });
  }

  salvarCartao() {
    let cartao: CartaoCredito = this.form.getRawValue();
    cartao.Numero = cartao.Numero.replace(/\s/g, '');
    cartao.Mes = cartao.Mes.padStart(2, '0');

    this.clienteService.criaCartaoCredito(cartao).subscribe(
      (codigoCartao) => this.fecharModal(codigoCartao.value),
      (err) => this.mensagemErro = err
    );
  }

  favoritar(){
    this.favorito = this.form.get("Principal").value;
  }

  get cartaoValido() {
    return this.form.valid;
  }
}
