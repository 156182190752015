import { AbstractControl, ValidatorFn } from "@angular/forms";

export function NomeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

        if (control && control.value) {
            const palavras: string[] = control.value.split(" ");
            return palavras.length > 1 && palavras.every(palavra => palavra.length >= 1) ?
                null : { nomeInvalido: control.value };
        }

        return null;
    };
}