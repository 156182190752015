import { TipoRedeSocial } from "@app/_enums/tipo-rede-social";

export class Cliente {

    constructor(Email: string, Nome: string, TokenRedeSocial: string, tipoRedeSocial: TipoRedeSocial) {
        this.Nome = Nome;
        this.Email = Email;
        
        tipoRedeSocial == TipoRedeSocial.Facebook ?
            this.TokenFacebook = TokenRedeSocial :
            this.TokenGoogle = TokenRedeSocial;

        this.UsuarioCriacao = 'API Cardápio';
    }


    Codigo: number;
    Nome: string;
    Email: string;
    Celular: string;
    Senha: string;
    TokenFacebook: string;
    TokenGoogle: string;
    UsuarioCriacao: string;
    DataCriacao: Date;
    CodClienteOrigem: number;
    JWTToken: string;
    RefCount: number
}