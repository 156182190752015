<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-md-10">
            <div class="box_order_form">
                <form [formGroup]="form">
                    <div class="head">
                        <div class="title">
                            <h3>Chamar Garçom</h3>
                        </div>
                    </div>
                    <div class="main" style="color: black;">
                        
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>