export class Endereco {
    Codigo?: number;
    Rua?: string;
    Numero?: number;
    Complemento?: string;
    Bairro?: string;
    Cep?: string;
    CodConsumidor?: number;
    CodMunicipio?: number;
    RefCount?: number;
    Principal?: boolean;
    Municipio?: string;
    CodTipo?: number;
    Tipo?: string;
    Latitude: string;
    Longitude: string;
}
