export class FilaEspera {
    Codigo: number;
    CodFila: number;
    Cliente: string;
    ClienteTelefone: string;
    Crianca: boolean;
    Cadeirante: boolean;
    DataCriacao: Date;
    DataAlteracao: Date;
    DataRemovido: Date;
    DataAtendido: Date;
    Estado: string;
    NotificacaoQuantidade: number;
    NotificacaoTempo: number;
    Observacao: string;
    Pessoas: number;
    Posicao: number;
    TempoDecorrido: string;
    TempoMedioEspera: number;
    UsuarioCriacao: string;
    UsuarioAlteracao: string;
    UsuarioRemovido: string;
    UsuarioAtendido: string;
    RefCount: number;
}