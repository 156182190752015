import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable()
export class LojaService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  buscaDetalhesLoja() {
    return this.http.get<any>(this.API_URL + 'opcao/');
  }
}
