import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilaEsperaService } from 'src/app/_services/fila-espera/fila-espera.service';
import { FilaEspera } from 'src/app/_models/fila-espera/fila-espera';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { set } from 'lodash';

@Component({
    selector: 'app-fila-espera',
    templateUrl: './fila-espera.component.html',
    styleUrls: ['./fila-espera.component.scss']
})
export class FilaEsperaComponent implements OnInit {
    codigo: string;
    filaEspera: FilaEspera = new FilaEspera();
    tempoEspera: number;
    displayTempoEspera: any;

    constructor(
        private router: Router,
        private filaEsperaService: FilaEsperaService,
        route: ActivatedRoute,
    ){
        route.params.subscribe(e => {
            this.codigo = e.codigo;
        });
        
        this.filaEsperaService.filaEspera(this.codigo).subscribe(e => {
            this.filaEspera = e;
            this.tempoEspera = e.TempoMedioEspera;
            
            if(this.filaEspera.TempoMedioEspera > 0){
                this.atualizaPagina();
            }
        });

        this.iniciaTimer(this.tempoEspera);
    }
    
    ngOnInit(): void {}

    iniciaTimer(minute: number){
        let seconds: number = minute * 60;
        let textSec: any = '0';
        let statSec: number = 60;
    
        const prefix = minute < 10 ? '0' : '';
    
        const timer = setInterval(() => {
          seconds--;
          if (statSec != 0) statSec--;
          else statSec = 59;
    
          if (statSec < 10) {
            textSec = '0' + statSec;
          } else textSec = statSec;
    
          this.displayTempoEspera = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

        }, 1000);
    }

    atualizaPagina(){
        if(this.filaEspera.Estado != undefined && this.filaEspera.Estado != 'Removido' && this.filaEspera.Estado != 'Atendido'){
            const atualizar = setInterval(() => {
                this.filaEsperaService.filaEspera(this.codigo).subscribe(e => {
                    this.filaEspera = e;
                });
            }, 60000);
        }
    }

    voltarCardapio(){
        this.router.navigate(['/']);
    }

    sairFila(){
        Swal.fire({
            title: 'Sair',
            text: 'Você tem certeza que deseja sair da fila de espera?',
            icon: 'warning', 
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.value) {
                this.filaEsperaService.cancelarFilaEspera(this.codigo).subscribe(
                    (e) => {
                      Swal.fire({
                        title: 'Sucesso',
                        text: 'Removido da fila de espera com sucesso',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                      }).then((result) => {
                        this.router.navigate(['/']);
                      })
                    }
                );
            }
        })
    }
}