<div id="modal-dialog" ngbAutofocus >

  <div class="modal_header">
    <h3>Contatos</h3>
    <button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
			<mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
		</button>
  </div>

  <div class="modal_body">
	  <div style="text-align: center;">
	    <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/suporte.svg" alt="Pessoa usando um fone de ouvido em um notebook, em uma chamada de vídeo. Ao fundo, há um relógio e uma chave de boca." width="250px">
	  </div>

    <ul>
      <li class="vertical-align-center">
        <mat-icon class="material-icons-outlined icones-contato icon-home" style="align-self: center;">
          home
        </mat-icon>
        <h4 class="vertical-align-center" style="width: 90%;">
          {{ restaurante.EnderecoCompleto }}
        </h4>
      </li>
      <li class="vertical-align-center">
        <a href="https://wa.me/{{restaurante.Fone}}" style="display: flex;">
          <mat-icon class="material-icons-outlined icones-contato">
            phone_android
          </mat-icon>
          <h4 class="vertical-align-center">
            {{ restaurante.Fone | mask: '(00) 00000-0000'}}
          </h4>
        </a>
      </li>
    </ul>
  </div>


</div>
