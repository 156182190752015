import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
//import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiService  {

  protected API_URL =  environment.apiUrl;

  constructor(protected http: HttpClient) { }
}
