<div id="error_page">
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-xl-7 col-lg-9">
        <figure style="cursor: pointer;" (click)="voltar()">
          <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/404.svg" alt="Mulher segurando uma lupa observando um grande '404' em vermelho acima do texto 'Page Not Found'." class="img-fluid" width="550" height="234">
        </figure>

        <!-- /row -->
      </div>
    </div>
    <!-- /row -->
  </div>
  <!-- /container -->
</div>
