<div id="modal-dialog" ngbAutofocus>

  <div class="modal_header">
    <h3>Confirmação por SMS</h3>
    <button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
			<mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
		</button>
  </div>

  <div class="modal_body">
    <div style="text-align: center">
      <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/cadastro.svg" alt="Pessoa parada segurando um celular, apoiada em um celular grande. Na tela, um ícone de foto com a palavra 'welcome', campos de login e senha, e abaixo um botão de login." width="250px" />
    </div>
  
    <form>
      <p>
        Enviamos um SMS com o código de verificação para o número
        {{ this.numeroCelular }}
      </p>
      <div style="text-align: center;">
        <div style="margin-top: 20px;">
          <ng-otp-input
          (keyup.enter)="validarOTP()"
          (onInputChange)="onOtpChange($event)"
          [config]="{
            length: 4,
            allowNumbersOnly: true,
            disableAutoFocus: true
          }"></ng-otp-input>
        </div>
  
        <div class="erro" *ngIf="erro">{{ erro }}</div>
  
        <button [disabled]="!podeContinuar" style="margin-top: 20px;" class="btn_1 full-width" (click)="validarOTP()">
          Verificar código
        </button>
  
        <div [hidden]="this.mostrarTimer" class="text-center mt-2">
          <a>Você poderá reenviar o código de confirmação em
            <countdown #timerOTP [config]="configTimer" (event)="statusOTPTimer($event)"></countdown>
          </a>
        </div>
  
        <div [hidden]="!this.mostrarTimer" class="text-center mt-2">
          <strong><a (click)="reenviarOTP()">Não recebeu? Clique aqui para reenviar.</a></strong>
        </div>
  
      </div>
    </form>
  
  </div>
</div>
