import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable()
export class FilaEsperaService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  filaEspera(codcliente: string) {
    return this.http.get<any>(this.API_URL + 'FilaEspera?Codigo=' + codcliente);
  }

  cancelarFilaEspera(codcliente: string) {
    return this.http.delete<any>(this.API_URL + 'FilaEspera?Codigo=' + codcliente);
  }
}
