import { Component } from '@angular/core';

@Component({
  selector: 'app-voltar-ao-topo',
  templateUrl: './voltar-ao-topo.component.html',
  styleUrls: ['./voltar-ao-topo.component.scss']
})
export class VoltarAoTopo {

  constructor(){}

  voltarAoTopo() {
    window.scrollTo(0, 0);
  }

}
