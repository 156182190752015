<div *ngIf="showSplash" class="app-splash-screen">

  <div class="splash-screen">
    <div class="lottie-container">
      <ng-lottie [options]="options" (animationCreated)="animationCreated($event)" (animationComplete)="animationComplete($event)" #lottieAnimation></ng-lottie>
    </div>
    <div class="loading-text">
      <span>{{ currentTextLoading }}</span>
    </div>
  </div>

</div>
