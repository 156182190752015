import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DetalhesCartaoCredito } from '@app/_models/cliente/cartao/detalhes-cartao-credito';
import { Cliente } from '@app/_models/cliente/cliente';
import { DetalhesCliente } from '@app/_models/cliente/detalhes-cliente';
import { Endereco } from '@app/_models/endereco/endereco';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { LoginService } from '@app/_services/login/login.service';
import { CpfCnpjValidator } from '@app/_validators/cpfCnpj-validator';
import { NomeValidator } from '@app/_validators/nome-validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ModalEditCartaoComponent } from '../cartao-credito/modal-edit-cartao/modal-edit-cartao.component';
import { ModalSelecaoCartaoComponent } from '../cartao-credito/modal-selecao-cartao/modal-selecao-cartao.component';
import { ModalSelecaoEnderecoEntregaComponent } from '../endereco-entrega/modal-selecao-endereco-entrega/modal-selecao-endereco-entrega.component';
import { ModalCadastroEnderecoComponent } from '../endereco-entrega/modal-cadastro-endereco/modal-cadastro-endereco.component';
import { TipoPagamentoService } from '@app/_services/tipo-pagamento.service';

@Component({
  selector: 'app-dados-cadastrais',
  templateUrl: './dados-cadastrais.component.html',
  styleUrls: ['./dados-cadastrais.component.scss']
})
export class DadosCadastraisComponent implements OnInit {

  form: UntypedFormGroup;
  formPagamento: UntypedFormGroup;
  formEndereco: UntypedFormGroup;
  senhaValida = true;

  loading = false;
  submitted = false;
  mascaraCelular = '(00) 0000-00009';
  identificacao: string;

  enderecoSelecionado: Endereco;
  cartaoSelecionado: DetalhesCartaoCredito;

  cliente: DetalhesCliente;

  enderecosCadastrados: Endereco[] = [];
  cartoesCadastrados: DetalhesCartaoCredito[];

  cartaoRemovido = new BehaviorSubject<DetalhesCartaoCredito>(null);
  enderecoRemovido = new BehaviorSubject<Endereco>(null);
  cpfCnpjMask: string = '00.000.000/0000-00';
  bandeiraCartaoSelecionado: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private clienteService: ClienteService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private tipoPagamentoService: TipoPagamentoService,
  ) {
    this.criaForm();
    this.atualizaMascaraCelular();
    this.buscaDadosCliente();

    this.removerCartao();
    this.removerEndereco();
    this.loginService.cliente.subscribe(cliente => {
      this.cliente = cliente;
      this.form.patchValue(this.cliente);
      this.cpfCnpjMask = this.cliente && this.cliente.CPFCNPJ && this.cliente.CPFCNPJ.length <= 11 ? '000.000.000-00' : '00.000.000/0000-00'
    });

    this.atualizaMascaraCnpj();
  }

  ngOnInit(): void { }

  atualizarCadastro() {
    const cliente = this.form.getRawValue();
    this.clienteService.atualiza(cliente).subscribe(() => {
      this.toastr.success('Dados alterados com sucesso', 'Sucesso', {
        positionClass: 'toast-bottom-left',
      });
      this.loginService.atualizaClienteLogado(cliente);
    });
  }

  private buscaDadosCliente() {
    this.listaEnderecos();
    this.listaCartoes();
  }

  private atualizaMascaraCnpj() {
    this.form.get('CPFCNPJ').valueChanges.subscribe(e => {
      this.cpfCnpjMask = e && e.length <= 11 ? '000.000.000-000' : '00.000.000/0000-00';
    });
  }

  atualizaMascaraCelular() {
    this.form.get('Celular').valueChanges
      .subscribe(e => this.mascaraCelular = e.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000');
  }

  validaSenha() {
    const senha = this.form.get('Senha');

    if (!senha.value || senha.value.length < 8) {
      senha.setErrors({ 'incorrect': true });
      this.senhaValida = false;
    }
  }

  abrirModalSelecaoEndereco() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalSelecaoEnderecoEntregaComponent, { size: 'xl', scrollable: true });
    modalRef.componentInstance.enderecosCadastrados = this.enderecosCadastrados;
    modalRef.componentInstance.enderecoRemovido = this.enderecoRemovido;

    modalRef.result.then((endereco: Endereco) => {
      this.listaEnderecos();
      if (endereco) {
        this.enderecoSelecionado = endereco;
      }
      document.body.style.position = '';
    }).catch((err) => {
      document.body.style.position = '';
    });
  }

  abrirModalAdicionarEndereco() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalCadastroEnderecoComponent, { size: 'xl' });
    modalRef.componentInstance.step = 1;

    modalRef.result.then((endereco: Endereco) => {
      this.listaEnderecos();
      if (endereco) {
        this.enderecoSelecionado = endereco;
      }
      document.body.style.position = '';
    }).catch((err) => {
      document.body.style.position = '';
    });
  }

  abrirModalSelecaoCartao() {
    document.body.style.position = 'fixed';
    const modalRef = this.modalService.open(ModalSelecaoCartaoComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.cartoesCadastrados = this.cartoesCadastrados;
    modalRef.componentInstance.cartaoRemovido = this.cartaoRemovido;

    modalRef.result.then((cartao: DetalhesCartaoCredito) => {
      this.listaCartoes();
      if (cartao) {
        this.cartaoSelecionado = cartao;
      }
      document.body.style.position = '';
    }).catch((err) => { 
      document.body.style.position = '';
    });
  }

  abrirModalAdicionarCartao() {
    document.body.style.position = 'fixed';
    const modalRef = this.modalService.open(ModalEditCartaoComponent, { size: 'lg', scrollable: true });
    modalRef.result.then((cartao: DetalhesCartaoCredito) => {
      this.listaCartoes();
      if (cartao) {
        this.cartaoSelecionado = cartao;
      }
      document.body.style.position = '';
    }).catch((err) => {
      document.body.style.position = '';
    });
  }

  bandeiraCartao(bandeira) {
    this.tipoPagamentoService.listaBandeiras().subscribe(e => {
      this.bandeiraCartaoSelecionado = e.value.find(b => b.Nome == bandeira).Imagem;
    });
  }

  private criaForm() {
    this.form = this.formBuilder.group({
      Codigo: [undefined],
      Nome: [undefined, [Validators.required, NomeValidator()]],
      Email: [{ value: undefined, disabled: true }, [Validators.required, Validators.email]],
      Celular: [undefined, Validators.required],
      UsuarioCriacao: ['API Cardápio'],
      CPFCNPJ: ['', [CpfCnpjValidator.validate]]
    });

    this.formPagamento = this.formBuilder.group({
      formaPagamento: [],
      nomeCartao: [],
      numeroCartao: [],
      mesDataExpiracao: [],
      anoDataExpiracao: [],
      cvv: []
    });
  }

  private listaEnderecos() {
    this.clienteService.listaEnderecos().subscribe(enderecos => {
      this.enderecosCadastrados = enderecos.value;
      if (this.enderecosCadastrados && this.enderecosCadastrados.length > 0) {
        this.enderecoSelecionado = this.enderecosCadastrados.filter(e => e.Principal)[0]
          || this.enderecosCadastrados[0];
      }
    })
  }

  private listaCartoes() {
    this.clienteService.listaCartaoCredito().subscribe(cartoes => {
      this.cartoesCadastrados = cartoes.value;
      if (this.cartoesCadastrados && this.cartoesCadastrados.length > 0) {
        this.cartaoSelecionado
          = this.cartoesCadastrados.filter(e => e.Principal)[0] || this.cartoesCadastrados[0];

        this.bandeiraCartao(this.cartaoSelecionado.Bandeira);
      }
    });
  }

  private removerCartao() {
    this.cartaoRemovido.subscribe(cartaoRemovido => {
      if (cartaoRemovido) {
        if (this.cartaoSelecionado.Codigo == cartaoRemovido.Codigo) {
          this.cartaoSelecionado = null;
        }
        this.listaCartoes();
      }
    });
  }

  private removerEndereco() {
    this.enderecoRemovido.subscribe(enderecoRemovido => {
      if (enderecoRemovido) {
        if (this.enderecoSelecionado.Codigo == enderecoRemovido.Codigo) {
          this.enderecoSelecionado = null;
        }
        this.listaEnderecos();
      }
    });
  }

  get podeSalvar() {
    return this.form.valid;
  }

  get nome() { return this.form.get('Nome'); }
  get senha() { return this.form.get('Senha'); }
  get email() { return this.form.get('Email'); }
  get cpfCnpj() { return this.form.get('CPFCNPJ'); }
}


