<div id="modal-dialog">
  <div class="modal_header">
    <h3>Troco para quanto?</h3>
  </div>
  <div class="modal_body" style="text-align: center;">
    <div style="margin-bottom: 15px;">Seu pedido deu {{valorPedido | currency:'BRL'}}</div>
    <div class="texto">Digite quanto vai pagar em dinheiro para que o entregador leve seu troco.</div>

    <div class="row mb-5" style="justify-content: center;">
      <label class="col-sm-1 col-form-label">R$</label>
      <div class="col-sm-4">
        <input currencyMask class="form-control" maxlength="6" [(ngModel)]="troco" type="tel"
          [options]="{ prefix: '', thousands: '.', decimal: ',' }" />
      </div>

      <div *ngIf="mensagemErro" class="erro">{{ mensagemErro }}</div>
    </div>

    <div class="botoes">
      <button class="btn_1" (click)="fecharModal()">Confirmar</button>
    </div>
  </div>
</div>
