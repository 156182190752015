import { Injectable } from '@angular/core';
import { FormaPagamento } from '@app/_enums/forma-pagamento';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { DetalhesCartaoCredito } from '@app/_models/cliente/cartao/detalhes-cartao-credito';
import { Endereco } from '@app/_models/endereco/endereco';
import { Entrega } from '@app/_models/endereco/entrega';
import { ResultadoDistanciaEnderecos } from '@app/_models/endereco/resultado-distancia-enderecos';
import { DetalhesMercadoria } from '@app/_models/mercadoria/detalhes-mercadoria';
import { PreparoMercadoria } from '@app/_models/mercadoria/preparo-mercadoria';
import { Pedido, PedidoItem } from '@app/_models/pedido/pedido';
import { TipoPagamento } from '@app/_models/tipo-pagamento/tipo-pagamento';
import { Variacao } from '@app/_models/variacao/variacao';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, Subject } from 'rxjs';
import { CacheService, LocalStorageSaveOptions } from '../base/cache.service';
import { ClienteService } from '../cliente/cliente.service';
import { DneService } from '../dne/dne.service';
import { TaxaEntregaService } from '../taxa-entrega/taxa-entrega.service';

@Injectable({
  providedIn: 'root'
})
export class SacolaService {

  pedidoSubject: BehaviorSubject<Pedido> = new BehaviorSubject<Pedido>(null);
  produtoAdicionado = new Subject<boolean>();
  cartaoSelecionado: DetalhesCartaoCredito;
  formaPagamento: FormaPagamento = FormaPagamento.Loja;
  valorTroco: number = 0;
  tipoPagamentoSelecionado: TipoPagamento;
  mercadorias = null;

  constructor(
    private cacheService: CacheService,
    private clienteService: ClienteService,
    private taxaEntregaService: TaxaEntregaService,
    private dneService: DneService,
    private dbService: NgxIndexedDBService
  ) {

    const pedidoCacheado = this.cacheService.load("sacola");

    this.dbService.getAll('itensPedido')
      .subscribe((itens: any[]) => {
        this.mercadorias = itens.map(e => {
          var item = JSON.parse(e.item);
          item.codigoItem = e.codigoItem;
          return item;
        })
      });

    this.pedidoSubject.next(pedidoCacheado ? pedidoCacheado : new Pedido());
    this.pedidoSubject.value.entrega = pedidoCacheado ? pedidoCacheado : new Entrega();
  }

  adicionarMercadoriaPedido(mercadoria: DetalhesMercadoria, quantidade: number, preparos?: PreparoMercadoria[], variacao?: Variacao, composicoes?: any[], observacao?: string) {
    var novoPedido = new PedidoItem(mercadoria, quantidade, preparos, variacao, composicoes, observacao);
    this.dbService
      .add('itensPedido', { item: JSON.stringify(novoPedido) })
      .subscribe((pedido: any) => {
        novoPedido.codigoItem = pedido.codigoItem;
        this.mercadorias.push(novoPedido);
        this.produtoAdicionado.next(true);
      });
  }

  editarMercadoriaPedido(codigoItem, mercadoria: DetalhesMercadoria, quantidade: number, preparos?: PreparoMercadoria[], variacao?: Variacao, composicoes?: any[], observacao?: string) {
    this.dbService.getByKey('itensPedido', codigoItem)
      .subscribe((pedido: any) => {
        pedido.mercadoria = mercadoria;
        pedido.quantidade = quantidade;
        pedido.preparos = preparos;
        pedido.variacao = variacao;
        pedido.composicoes = composicoes;
        pedido.observacao = observacao;

        var pedidoString = { item: JSON.stringify(pedido), codigoItem: pedido.codigoItem }
        this.dbService
          .update('itensPedido', pedidoString)
          .subscribe((storeData) => {
            this.dbService.getAll('itensPedido')
              .subscribe((itens: any[]) => {
                this.mercadorias = itens.map(e => {
                  var item = JSON.parse(e.item);
                  item.codigoItem = e.codigoItem;
                  return item;
                })
                this.produtoAdicionado.next(true);
              });
          });
      });
  }

  removeVariacao(item: any, variacao: any) {
    this.pedidoSubject.value.mercadorias = this.pedidoSubject.value.mercadorias.filter(e => e.codigoItem != item);
    this.cacheService.save(new LocalStorageSaveOptions("sacola", this.pedidoSubject.value));
  }

  removerPreparo(codigoItem, preparo: PreparoMercadoria) {
    const mercadoriaIndex = this.pedidoSubject.value.mercadorias.findIndex(e => e.codigoItem == codigoItem);
    this.pedidoSubject.value.mercadorias[mercadoriaIndex].preparos = this.pedidoSubject.value.mercadorias[mercadoriaIndex].preparos
      .filter(p => p.Codigo != preparo.Codigo);
  }

  removerMercadoria(codigoItemCarrinho: number) {
    this.dbService.delete('itensPedido', codigoItemCarrinho)
      .subscribe((itens) => this.produtoAdicionado.next(true));
    this.mercadorias = this.mercadorias.filter(e => e.codigoItem != codigoItemCarrinho);
  }

  esvaziarCarrinho(){
    this.mercadorias.forEach(mercadoria => {
      this.dbService.delete('itensPedido', mercadoria.codigoItem)
      .subscribe((itens) => {
          this.produtoAdicionado.next(true);
          this.mercadorias = this.mercadorias.filter(e => e.codigoItem != mercadoria.codigoItem);
        });
    });
  };

  aumentarQuantidade(codigoMercadoria: string) {
    const indiceMercadoriaParaAlterar =
      this.pedidoSubject.value.mercadorias.findIndex(e => e.mercadoria.Codigo == codigoMercadoria);

    this.pedidoSubject.value.mercadorias[indiceMercadoriaParaAlterar].quantidade++;
  }

  alterarFormaRetirada(forma: FormaRetiradaPedido) {
    this.clienteService.alterarFormaRetirada(forma);
    this.pedidoSubject.value.ModoEntregaRetira = forma;
  }

  alteraQuantidade(mercadoria: PedidoItem) {
    const indiceMercadoriaParaAlterar =
      this.pedidoSubject.value.mercadorias.findIndex(e => e.mercadoria.Codigo == mercadoria.mercadoria.Codigo);

    this.pedidoSubject.value.mercadorias[indiceMercadoriaParaAlterar].quantidade = mercadoria.quantidade;
    this.cacheService.save(new LocalStorageSaveOptions("sacola", this.pedidoSubject.value));
  }

  listarMercadorias() {
    return this.mercadorias;
  }

  selecionarEndereco(endereco: Endereco) {
    this.pedidoSubject.value.endereco = endereco;
  }

  pedidoRealizado() {
    const pedido = new Pedido();
    pedido.entrega = new Entrega();
    this.tipoPagamentoSelecionado = null;
    this.pedidoSubject.next(pedido);

    this.dbService.clear("itensPedido").subscribe(e => {
      this.mercadorias = [];
    });
    this.cacheService.save(new LocalStorageSaveOptions("sacola", this.pedidoSubject.value));
  }

  public atualizarTaxaEntrega() {
    const restaurante = this.cacheService.load('loja');
    this.clienteService.enderecoSelecionado.subscribe(e => {
      if (e != null) {
        const enderecoUsuario = `${e.Rua}, ${e.Numero}, ${e.Bairro}, ${e.Municipio}, ${e.Cep}`;
        const enderecoRestaurante = `${restaurante.EnderecoCompleto} ${restaurante.CEP}`;
        this.dneService.calculaDistanciaEntreEnderecos(enderecoRestaurante, enderecoUsuario, false)
          .subscribe((e: ResultadoDistanciaEnderecos) => {
            this.pedidoSubject.value.entrega.Distancia = e.DistanciaFormatada;
            this.pedidoSubject.value.entrega.DataEntrega = new Date(new Date(new Date().getTime() + restaurante.TempoPreparo * 60000));
            this.pedidoSubject.value.entrega.MostrarAmanha = this.pedidoSubject.value.entrega.DataEntrega.getDate() - new Date().getDate() > 0;
            this.pedidoSubject.value.TempoPreparo = e.Duracao;
            this.clienteService.atualizarPermiteEntregaNoEnderecoSelecionado(e.Distancia < (restaurante.RaioEntregaKM * 1000));

            if (this.clienteService.permiteEntregaNoEnderecoSelecionado) {
              this.taxaEntregaService.calculaValorEntrega(e.Distancia)
                .subscribe(e => {
                  this.pedidoSubject.value.TaxaEntrega = e.value;
                });
            }

            //this.taxaEntrega = this.pedidoSubject.value.TaxaEntrega;
          })
      }
    });
  }

  get taxaEntrega() {
    return this.pedidoSubject.value.TaxaEntrega;
  }

  set taxaEntrega(taxaEntrega: number) {
    this.pedidoSubject.value.TaxaEntrega = taxaEntrega;
  }

  get totalItensSacola() {
    let totalItens = 0;
    if (this.mercadorias) {
      this.mercadorias.map(item => totalItens += item.quantidade);
    }
    return totalItens;
  }

  get valorTotal() {
    let valorTotal = 0;
    if (this.mercadorias) {
      this.mercadorias.forEach(e => {
        if (e.variacao) {
          valorTotal += (e.variacao.PrecoVenda != e.variacao.PrecoVendaComDesconto ? e.variacao.PrecoVendaComDesconto : e.variacao.PrecoVenda) * e.quantidade;
        } else {
          valorTotal += this.calculaDesconto(e.mercadoria.PrecoVenda, e.mercadoria.DescontoVenda, e.quantidade);
        }

        valorTotal += this.calculaPrecosPreparos(e, valorTotal);
      });
    }

    return valorTotal;
  }

  private calculaDesconto(precoAtual, porcentagemDesconto, quantidade = 1) {
    return porcentagemDesconto ?
      (precoAtual - precoAtual * (porcentagemDesconto / 100)) * quantidade :
      precoAtual * quantidade;
  }

  private calculaPrecosPreparos(e: PedidoItem, valorTotal: number) {
    let precoPreparos = 0;
    if (e.preparos && e.preparos.length > 0) {
      precoPreparos = e.preparos
        .reduce((valorTotal, preparo) => valorTotal + (preparo.PrecoVenda * e.quantidade * preparo.Quantidade), 0);
    }

    return precoPreparos;
  }
}
