<div>
  <div id="modal-dialog" ngbAutofocus>
    <div class="modal-content"
      id="info"
      [ngClass]="{ 'pedido-info': mercadoria.Imagens }"
    >

      <div class="modal_header">
        <h3>{{ mercadoria.NomeCurto }}</h3>
        <button class="button-close" title="Fechar" type="button" (click)="fecharModal()">
          <mat-icon id="icon-fechar" class="icon_header material-icons-outlined">close</mat-icon>
        </button>
      </div>

      <div class="modal_body">
        <div
          id="imagem-mobile"
          *ngIf="mercadoria.Imagens"
          style="height: 100%; width: 100%; display: none"
        >
          <ngb-carousel
            [showNavigationArrows]="mercadoria.Imagens.length > 1"
            [showNavigationIndicators]="mercadoria.Imagens.length > 1"
            style="height: 30%; outline: blanchedalmond"
          >
            <ng-template
              ngbSlide
              *ngFor="let imagem of mercadoria.Imagens; let i = index"
            >
              <div
                class="picsum-img-wrapper background-image imagem"
                [style.background-image]="mostrarImagem(i)"
                (click)="abrirModalImagem(i)"
              ></div>
            </ng-template>
          </ngb-carousel>
        </div>

        <div
          id="imagem-web"
          *ngIf="mercadoria.Imagens"
          style="float: left; height: 90%; width: 40%; padding: 1rem;"
        >
          <ngb-carousel
            [showNavigationArrows]="mercadoria.Imagens.length > 1"
            [showNavigationIndicators]="mercadoria.Imagens.length > 1"
            style="height: 100%; outline: blanchedalmond"
          >
            <ng-template
              ngbSlide
              *ngFor="let imagem of mercadoria.Imagens; let i = index"
            >
              <div
                class="picsum-img-wrapper background-image imagem"
                [style.background-image]="mostrarImagem(i)"
              ></div>
            </ng-template>
          </ngb-carousel>
        </div>

        <div class="content" style="color: black">
          <div class="carregando" *ngIf="carregando">
            <mat-spinner></mat-spinner>
          </div>

          <div class="detalhes" *ngIf="!carregando">
            <p *ngIf="mercadoria.NomeLongo" class="descricao">
              {{ mercadoria.NomeLongo }}
            </p>

            <div *ngIf="mercadoria.Atributos.length > 0" style="display: flex;">
              <mat-chip-set class="col-11" aria-label="Atributos">
                <mat-chip *ngFor="let atributo of mercadoria.Atributos">
                  <img matChipAvatar [src]="atributo.Imagem" width="20px"/>
                  {{ atributo.Atributo }}
                </mat-chip>
              </mat-chip-set>
  
              <div class="col-1 help">
                <button mat-button
                  #tooltip="matTooltip"
                  matTooltip="Info about the action"
                  matTooltipPosition="right"
                  (click)="tooltip.show()"
                  aria-label="Show tooltip on the button at the end of this section"
                  class="example-action-button"
                >   
                  <mat-icon class="material-icons-outlined" width="20" heigth="20">help</mat-icon>
                </button>
              </div>
            </div>

            <h5 *ngIf="variacoes && variacoes.length > 0">Escolha uma opção:</h5>
            <ul *ngIf="variacoes && variacoes.length > 0" class="clearfix">
              <li *ngFor="let variacao of variacoes">
                <label class="container_radio">
                  <input
                    type="radio"
                    [value]="variacao"
                    name="variacao"
                    [checked]="
                      variacaoSelecionada && 
                      variacao.CodMercVar == variacaoSelecionada.CodMercVar
                    "
                    (change)="
                      selecionaVariacao(variacao);
                      !irParaProximoItem('composicao', -1, null, null)
                      ? irParaProximoItem('preparo', -1, null, null)
                      : irParaProximoItem('observacao', 0, null, null)
                    "
                  />
                  <span class="checkmark"></span>

                  <h4>
                    {{ variacao.NomeComponente1 + " " + variacao.NomeComponente2 }}
                  </h4>
                  
                  <span>
                    <span
                      [ngClass]="{
                        'preco-desconto': variacao.PrecoVendaComDesconto != variacao.PrecoVenda
                      }"
                    >
                      {{ variacao.PrecoVenda | currency: "BRL" }}
                    </span>

                    <span *ngIf="variacao.PrecoVendaComDesconto != variacao.PrecoVenda">
                      {{ variacao.PrecoVendaComDesconto | currency }}
                    </span>
                  </span>
                </label>

                <div *ngIf="variacao.DescricaoComponente1" style="font-size: 12px; margin-left: 5px;">
                  <div style="width: 75%; display: inline-block;">
                    {{
                      variacao.DescricaoComponente1
                    }}
                  </div>
                </div>
    
                <div *ngIf="variacao.DescricaoComponente2" style="font-size: 12px; margin-left: 5px;">
                  <div style="width: 75%; display: inline-block;">
                    {{
                      variacao.DescricaoComponente2
                    }}
                  </div>
                </div>
    
              </li>
            </ul>

            <ul
              *ngIf="composicoes && composicoes.length > 0"
              class="clearfix composicoes"
            >
              <li
                class="composicao"
                *ngFor="let composicao of composicoes; let ic = index"
                [id]="'composicao_' + ic"
              >
                <label
                  class="itens-contidos"
                  [class.itens-contidos-selecionados]="
                    itemComposicaoPossuiVariacoes(composicao.CodBasicoContida) ||
                    composicao.TipoItemVenda != 'Com variação'
                  "
                >
                  {{
                    composicao.TipoItemVenda != "Com variação"
                    ? "Passo " + (ic + 1) + ": " + composicao.NomeCurto
                    : "Passo " + (ic + 1) + ": " + composicao.NomeCurto + " " + composicao.CompVar1
                  }}
                </label>

                <div>
                  <h5
                    *ngIf="
                      itemComposicaoPossuiVariacoes(composicao.CodBasicoContida)
                    "
                  >
                    Escolha uma opção:
                  </h5>
                  <ul
                    *ngIf="
                      itemComposicaoPossuiVariacoes(composicao.CodBasicoContida)
                    "
                    class="clearfix"
                  >
                    <li
                      *ngFor="
                        let variacao of mostraVariacoesDaComposicao(
                          composicao.CodBasicoContida
                        );
                        let i = index;
                        let last = last
                      "
                      [id]="'variacoesContidas_' + i"
                    >
                      <label class="container_radio">

                        <input
                          type="radio"
                          [value]="variacao"
                          [name]="'composicao_' + composicao.Codigo"
                          [checked]="
                            variacaoDoComposicaoFoiSelecionada(
                              composicao.Codigo,
                              variacao.CodMercVar
                            )
                          "
                          (change)="
                            selecionaVariacaoItemContido(composicao, variacao);
                            irParaProximoItem(
                              'preparosContidos' + composicao.CodBasicoContida,
                              ic - 1,
                              null,
                              null
                            )
                            ? null
                            : !irParaProximoItem('composicao', ic, null, null)
                            ? irParaProximoItem('observacao', 0, null, null)
                            : ''
                          "
                        />

                        <span class="checkmark"></span>
                        
                        <div style="width: 100%;">
                          <div style="display: flex;">
                            <h4>
                              {{
                                variacao.NomeComponente1 +
                                  " " +
                                  variacao.NomeComponente2
                              }}
                            </h4>

                            <span *ngIf="restaurante.CalculoPreco != 'Composta'">
                              <span
                                [ngClass]="{
                                  'preco-desconto': variacao.PrecoVendaComDesconto != variacao.PrecoVenda
                                }"
                              >
                                {{ variacao.PrecoVenda | currency: "BRL" }}
                              </span>
          
                              <span *ngIf="variacao.PrecoVendaComDesconto != variacao.PrecoVenda">
                                {{ variacao.PrecoVendaComDesconto | currency }}
                              </span>
                            </span>
                          </div>
                        
                          <div *ngIf="variacao.DescricaoComponente1" style="font-size: 12px; padding-left: 10px;">
                            <div style="width: 75%; display: inline-block;">
                              {{
                                variacao.DescricaoComponente1
                              }}
                            </div>
                          </div>
              
                          <div *ngIf="variacao.DescricaoComponente2" style="font-size: 12px; margin-left: 5px;">
                            <div style="width: 75%; display: inline-block;">
                              {{
                                variacao.DescricaoComponente2
                              }}
                            </div>
                          </div>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>

                <div
                  *ngIf="
                    itemComposicaoPossuiPreparos(composicao.CodBasicoContida)
                  "
                >
                  <div
                    *ngFor="
                      let grupoPreparo of mostraPreparosDaComposicao(
                        composicao.CodBasicoContida
                      );
                      let ig = index;
                      let lastg = last
                    "
                    [id]="'composicao_' + ic + 'grupoPreparo_' + ig"
                  >
                    <div *ngIf="grupoPreparo.Preparo.length > 0">
                      <div class="row grupo-preparo">
                        <div class="titulo-grupo-preparo"
                          [ngClass]="grupoPreparo.QuantidadeMinima > 0 ?  'col-8' : 'col-12'"
                        >
                          <h3 class="nome-curto">{{ grupoPreparo.Pergunta }}</h3>
                        </div>
                        <div
                          *ngIf="
                            grupoPreparo.QuantidadeMinima > 0 &&
                            !verificaSeTodosOsPreparosForamSelecionadosDoGrupo(
                              grupoPreparo,
                              composicao.CodBasicoContida
                            )
                          "
                          class="col-4 grupo-preparo-obrigatorio-container"
                        >
                          <label class="grupo-preparo-obrigatorio">Obrigatório</label>
                        </div>
                        <div
                          *ngIf="
                            grupoPreparo.QuantidadeMinima > 0 &&
                            verificaSeTodosOsPreparosForamSelecionadosDoGrupo(
                              grupoPreparo,
                              composicao.CodBasicoContida
                            )
                          "
                          class="col-4 grupo-preparo-obrigatorio-container"
                        >
                          <span class="grupo-preparo-obrigatorio-completo">
                            &#10003;
                          </span>
                        </div>
                      </div>
                    </div>
                    <label
                      *ngIf="
                        grupoPreparo.QuantidadeMinima > 0 &&
                        grupoPreparo.QuantidadeMaxima > 1
                      "
                      class="limitacao-grupo-preparo"
                    >
                      Escolha de {{ grupoPreparo.QuantidadeMinima }} a
                      {{ grupoPreparo.QuantidadeMaxima }}
                    </label>
                    <label
                      *ngIf="
                        grupoPreparo.QuantidadeMinima == 0 &&
                        grupoPreparo.QuantidadeMaxima > 0 &&
                        grupoPreparo.QuantidadeMaxima < 999
                      "
                      class="limitacao-grupo-preparo"
                    >
                      Escolha até {{ grupoPreparo.QuantidadeMaxima }}
                    </label>

                    <h5
                      *ngIf="
                        grupoPreparo.Observacao && grupoPreparo.Observacao != ''
                      "
                      style="font-weight: bold !important"
                    >
                      {{ grupoPreparo.Observacao }}
                    </h5>

                    <ul
                      *ngIf="
                        itemComposicaoPossuiPreparos(composicao.CodBasicoContida)
                      "
                      class="clearfix"
                    >
                      <li
                        *ngFor="
                          let preparo of grupoPreparo.Preparo;
                          let i = index;
                          let last = last
                        "
                        [id]="'preparo_' + grupoPreparo.Codigo + i"
                      >
                        <div class="row m-0">
                          <div style="padding-left: 0;" [ngClass]="exibirQuantidade(preparo, composicao.CodBasicoContida, ic) && preparo.PrecoVenda ?  'col-5' : !exibirQuantidade(preparo, composicao.CodBasicoContida) && preparo.PrecoVenda ? 'col-8' : exibirQuantidade(preparo, composicao.CodBasicoContida, ic) && !preparo.PrecoVenda ? 'col-9' : 'col-12' ">
                            <label *ngIf="grupoPreparo.QuantidadeMaxima > 1" class="container_check">
                              <input
                                type="checkbox"
                                [disabled]="
                                  processaLimitacoesGrupoPreparo(
                                    grupoPreparo,
                                    preparo,
                                    composicao.CodBasicoContida
                                  )
                                "
                                (change)="
                                  adicionaPreparoCarrinho(
                                    $event.target.checked,
                                    preparo,
                                    composicao.CodBasicoContida,
                                    ig,
                                    grupoPreparo,
                                    grupoPreparos.length,
                                    ic,
                                    lastg
                                  )
                                "
                                [id]="
                                  'qtdPreparo_' +
                                  grupoPreparo.Codigo +
                                  i +
                                  '_' +
                                  preparo.Codigo + 
                                  '_composicao_' + ic
                                "
                                [checked]="
                                  preparoFoiSelecionado(
                                    preparo,
                                    composicao.CodBasicoContida,
                                    ic
                                  )
                                "
                              />
                              <span class="checkmark"></span>
                                
                              <h4>
                                {{ preparo.Nome }}
                              </h4>

                            </label>

                            <label *ngIf="grupoPreparo.QuantidadeMaxima == 1" class="container_radio">
                              <input
                                type="radio"
                                (change)="
                                  adicionaPreparoCarrinho(
                                    $event.target.checked,
                                    preparo,
                                    composicao.CodBasicoContida,
                                    ig,
                                    grupoPreparo,
                                    grupoPreparos.length,
                                    ic,
                                    lastg
                                  )
                                "
                                [id]="
                                  'qtdPreparo_' +
                                  grupoPreparo.Codigo +
                                  i +
                                  '_' +
                                  preparo.Codigo
                                "
                                [checked]="
                                  preparoFoiSelecionado(
                                    preparo,
                                    composicao.CodBasicoContida,
                                    ic
                                  )
                                "
                              />
                              <span class="checkmark"></span>
                              
                              <h4>
                                {{ preparo.Nome }}
                              </h4>

                            </label>
                          </div>
                          <div *ngIf="exibirQuantidade(preparo, composicao.CodBasicoContida, ic)" class="col-3 numbers-div">
                            <div class="numbers-row">
                              <input
                                type="text"
                                type="number"
                                thousandSeparator="."
                                class="qty2 form-control"
                                [(ngModel)]="preparo.Quantidade"
                                (change)="
                                  validaQuantidadePreparo(
                                    preparo,
                                    $event.target.value,
                                    grupoPreparo,
                                    composicao.CodBasicoContida
                                  )
                                "
                              />
                              <div
                                class="inc button_inc"
                                (click)="aumentarQuantidadePreparo(preparo, grupoPreparo, ig, lastg, ic, composicao.CodBasicoContida)"
                              >
                                +
                              </div>
                              <div
                                class="dec button_inc"
                                (click)="diminuirQuantidadePreparo(preparo)"
                              >
                                -
                              </div>
                            </div>
                          </div>
                          <div *ngIf="preparo.PrecoVenda" class="preco-preparo col-4">
                            <span>
                              {{
                                preparo.PrecoVenda > 0
                                  ? "+ " +
                                    (preparo.PrecoVenda *
                                      (preparo.Quantidade
                                        ? preparo.Quantidade
                                        : 1) | currency: "BRL")
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="row m-0"
                          *ngIf="
                            preparoFoiSelecionado(preparo) &&
                            preparo.PermitePersonalizacao
                          "
                        >
                          <div class="col-12">
                            <input
                              *ngIf="preparo.TipoPersonalizacao == 'Number'"
                              type="number"
                              [id]="
                                'personalizacaoPreparo_' + grupoPreparo.Codigo + i
                              "
                              [(ngModel)]="preparo.ValorPersonalizacao"
                              (change)="validaPersonalizacao(preparo)"
                            />
                            <input
                              *ngIf="preparo.TipoPersonalizacao != 'Number'"
                              type="text"
                              [id]="
                                'personalizacaoPreparo_' + grupoPreparo.Codigo + i
                              "
                              [(ngModel)]="preparo.ValorPersonalizacao"
                              (change)="validaPersonalizacao(preparo)"
                              [minlength]="
                                preparo.TamanhoMinimoCampoPersonalizacao
                              "
                              [maxlength]="
                                preparo.TamanhoMaximoCampoPersonalizacao
                              "
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>

            <div *ngIf="grupoPreparos && grupoPreparos.length > 0">
              <div
                *ngFor="
                  let grupoPreparo of grupoPreparos;
                  let ig = index;
                  let lastg = last
                "
                [id]="'grupoPreparo_' + ig"
              >
                <div *ngIf="grupoPreparo.Preparo.length > 0">
                  <div class="row grupo-preparo">
                    <div  
                      [ngClass]="grupoPreparo.QuantidadeMinima > 0 ?  'col-8' : 'col-12'" 
                      style="padding-left: 20px;"
                    >
                      <h3 class="nome-curto">{{ grupoPreparo.Pergunta }}</h3>
                    </div>
                    <div
                      *ngIf="
                        grupoPreparo.QuantidadeMinima > 0 &&
                        !verificaSeTodosOsPreparosForamSelecionadosDoGrupo(
                          grupoPreparo
                        )
                      "
                      class="col-4 grupo-preparo-obrigatorio-container"
                    >
                      <label class="grupo-preparo-obrigatorio">Obrigatório</label>
                    </div>
                    <div
                      *ngIf="
                        grupoPreparo.QuantidadeMinima > 0 &&
                        verificaSeTodosOsPreparosForamSelecionadosDoGrupo(
                          grupoPreparo
                        )
                      "
                      class="col-4 grupo-preparo-obrigatorio-container"
                    >
                      <span class="grupo-preparo-obrigatorio-completo">
                        &#10003;
                      </span>
                    </div>
                  </div>
                  <label
                    *ngIf="
                      grupoPreparo.QuantidadeMinima > 0 &&
                      grupoPreparo.QuantidadeMaxima > 0
                    "
                    class="limitacao-grupo-preparo"
                  >
                    Escolha de {{ grupoPreparo.QuantidadeMinima }} a
                    {{ grupoPreparo.QuantidadeMaxima }}
                  </label>
                  <label
                    *ngIf="
                      grupoPreparo.QuantidadeMinima == 0 &&
                      grupoPreparo.QuantidadeMaxima > 0 &&
                      grupoPreparo.QuantidadeMaxima < 999
                    "
                    class="limitacao-grupo-preparo"
                  >
                    Escolha até {{ grupoPreparo.QuantidadeMaxima }}
                  </label>
                  <ul
                    *ngIf="
                      grupoPreparo.Preparo && grupoPreparo.Preparo.length > 0
                    "
                    class="clearfix"
                  >
                    <li
                      *ngFor="
                        let preparo of grupoPreparo.Preparo;
                        let i = index;
                        let last = last
                      "
                      [id]="'preparo_' + grupoPreparo.Codigo + i"
                    >
                      <div class="row m-0">
                        <div style="padding: 0;" [ngClass]="exibirQuantidade(preparo) ?  'col-5' : 'col-8'">
                          <label class="container_check">
                            <input
                              type="checkbox"
                              [disabled]="
                                processaLimitacoesGrupoPreparo(
                                  grupoPreparo,
                                  preparo,
                                  null
                                )
                              "
                              (change)="
                                adicionaPreparoCarrinho(
                                  $event.target.checked,
                                  preparo,
                                  null,
                                  ig,
                                  grupoPreparo,
                                  grupoPreparos.length,
                                  null,
                                  lastg
                                )
                              "
                              [id]="
                                'qtdPreparo_' +
                                grupoPreparo.Codigo +
                                i +
                                '_' +
                                preparo.Codigo
                              "
                              [checked]="preparoFoiSelecionado(preparo)"
                            />
                            <span class="checkmark"></span>
                            <h4>
                              {{ preparo.Nome }}
                            </h4>
                          </label>
                        </div>
                        <div *ngIf="exibirQuantidade(preparo)" class="col-3 numbers-div">
                          <div class="numbers-row">
                            <input
                              type="text"
                              type="number"
                              thousandSeparator="."
                              class="qty2 form-control"
                              [(ngModel)]="preparo.Quantidade"
                              (change)="
                                validaQuantidadePreparo(
                                  preparo,
                                  $event.target.value,
                                  grupoPreparo
                                )
                              "
                            />
                            <div
                              class="inc button_inc"
                              (click)="aumentarQuantidadePreparo(preparo, grupoPreparo, ig, lastg)"
                            >
                              +
                            </div>
                            <div
                              class="dec button_inc"
                              (click)="diminuirQuantidadePreparo(preparo)"
                            >
                              -
                            </div>
                          </div>
                        </div>
                        <div *ngIf="preparo.PrecoVenda" class="preco-preparo col-4">
                          <span>
                            {{
                              preparo.PrecoVenda > 0
                                ? "+ " +
                                  (preparo.PrecoVenda *
                                    (preparo.Quantidade ? preparo.Quantidade : 1)
                                    | currency: "BRL")
                                : ""
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row m-0"
                        *ngIf="
                          preparoFoiSelecionado(preparo) &&
                          preparo.PermitePersonalizacao
                        "
                      >
                        <div class="col-12">
                          <input
                            *ngIf="preparo.TipoPersonalizacao == 'Number'"
                            type="number"
                            [id]="
                              'personalizacaoPreparo_' + grupoPreparo.Codigo + i
                            "
                            [(ngModel)]="preparo.ValorPersonalizacao"
                            (change)="validaPersonalizacao(preparo)"
                          />
                          <input
                            *ngIf="preparo.TipoPersonalizacao != 'Number'"
                            type="text"
                            [id]="
                              'personalizacaoPreparo_' + grupoPreparo.Codigo + i
                            "
                            [(ngModel)]="preparo.ValorPersonalizacao"
                            [minlength]="preparo.TamanhoMinimoCampoPersonalizacao"
                            (change)="validaPersonalizacao(preparo)"
                            [maxlength]="preparo.TamanhoMaximoCampoPersonalizacao"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="form-group observacao" id="observacao_1">
              <label *ngIf="(restaurante.HabilitarEntrega || restaurante.HabilitarPraRetirar || restaurante.HabilitarAgendamento)">Observações</label>
              <textarea
                class="form-control"
                maxlength="140"
                [(ngModel)]="observacao"
                *ngIf="(restaurante.HabilitarEntrega || restaurante.HabilitarPraRetirar || restaurante.HabilitarAgendamento)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div id="footer" class="footer" style="position: absolute; bottom: 0px; right: 0; width: 100%;">
        <div class="numbers-div" *ngIf="(restaurante.HabilitarEntrega || restaurante.HabilitarPraRetirar || restaurante.HabilitarAgendamento)">
          <div class="quantidade col-3">
            <mat-icon class="icons-quantidade material-icons-outlined" (click)="diminuirQuantidade()">remove</mat-icon>
            <span>{{ quantidade }}</span>
            <mat-icon class="icons-quantidade material-icons-outlined" (click)="aumentarQuantidade()">add</mat-icon>
          </div>
          <div class="col-9" style="padding-left: 1rem;">
            <button
              [disabled]="!podeAdicionarCarrinho"
              type="reset"
              class="btn_1 full-width"
              (click)="adicionarAoCarrinho()"
            >
              <span>Adicionar</span>
              <span style="padding-left: 10px">
                {{ valorTotal | currency: "BRL"}}
              </span>
            </button>
          </div>
        </div>
        <!-- /Row -->
      </div>
    </div>
  </div>
</div>
