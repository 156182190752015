import { Injectable } from '@angular/core';
import { Endereco } from '@app/_models/endereco/endereco';
import { NgxIndexedDBService } from 'ngx-indexed-db';


@Injectable({
  providedIn: 'root'
})
export class ResultadoPesquisaEnderecoService {
  
  coordenadas : google.maps.LatLngLiteral = { lat: 0, lng: 0 };

  retorno: { endereco: Endereco, codEstado: number, estado: string } =
  {
    endereco: new Endereco(),
    codEstado: 0,
    estado: ""
  }

  setResultadoPesquisa(coordenadas: google.maps.LatLngLiteral){
    this.coordenadas = coordenadas;
  }

  getResultadoPesquisa(){
    return this.coordenadas;
  }

  setEndereco(endereco: Endereco, codEstado: number, estado: string){
    this.retorno.endereco = endereco;
    this.retorno.codEstado = codEstado;
    this.retorno.estado = estado;
  }

  getEndereco(){
    return this.retorno;
  }
}
