import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndoorService {

  public indoor: boolean = null;
  public numeroMesa: string = null;
  public usaComanda: boolean = null;
  public comanda: string = null;
  public distanciaPermitida: boolean = null;

  constructor(
    private dbService: NgxIndexedDBService,
  ) { 
    //this.dbService.getAll('comanda')
    //  .subscribe((item: any) => {
    //    this.comanda = JSON.parse(item); 
    //  });

    //this.dbService.getAll('mesa')
    //  .subscribe((item: any) => {
    //    this.numeroMesa = JSON.parse(item); 
    //  });
  };

  vincularMesa(numeroMesa){
    this.dbService
      .add('mesa', { item: JSON.stringify(numeroMesa)})
      .subscribe((mesa: any) => {
        this.numeroMesa = JSON.parse(mesa);
      });
  }

  vincularComanda(comanda){
    this.dbService
      .add('comanda', { item: JSON.stringify(comanda) })
      .subscribe((item: any) => {
        this.comanda = JSON.parse(item);
      });
  }
}
