export class IniciarConsumoMesa {
    CodMesaCartao: number;
    CodLocal: number = 0;
    ClienteNome: string;
    ClienteRGInscricao: string = "";
    ClienteCPFCNPJ: string;
    ClienteDataNascimento: Date = null;
    ClienteFone1: string;
    ClienteFisicoJuridico: string = "";
    Arredondamento: number = 2;
    CasasDecimais: number = 2;
}