import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-mensagem',
  templateUrl: './modal-mensagem.component.html',
  styleUrls: ['./modal-mensagem.component.scss']
})
export class ModalMensagemComponent implements OnInit {

  @Input() titulo: string;
  @Input() mensagem: string;
  @Input() detalhes: string;
  @Input() centralizarTexto: boolean;
  @Input() mostrarBotaoCancelar: boolean = false;
  @Input() mostrarBotaoConfirmar: boolean = true;
  @Input() textoBotaoCancelar: string = "Cancelar"; 
  @Input() textoBotaoConfirmar: string = "Ok"; 
  @Input() template: TemplateRef<any>;
  @Input() imagem: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  fecharModal(confirmar: boolean) {
    this.activeModal.close(confirmar);
  }
}
