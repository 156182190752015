import { Route } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EstadoPedido } from '@app/_enums/estado-pedido';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { NovoPedido } from '@app/_models/pedido/novo-pedido';
import { LoginService } from '@app/_services/login/login.service';
import { PedidoService } from '@app/_services/pedido/pedido.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEntrarContatoRestauranteComponent } from './modal-entrar-contato-restaurante/modal-entrar-contato-restaurante.component';

@Component({
  selector: 'app-acompanhamento-pedido',
  templateUrl: './acompanhamento-pedido.component.html',
  styleUrls: ['./acompanhamento-pedido.component.scss']
})
export class AcompanhamentoPedidoComponent implements OnInit, OnDestroy {

  restaurante: DetalhesLoja;
  pedido: NovoPedido;
  carregando = true;
  codigoPedido: string;
  Estado = EstadoPedido;
  interval;
  pedidoEstaCancelado = false;
  pedidoEstaEnviado = false;
  pedidoEstaPreparando = false;
  PedidoPronto = false;
  pedidoSaiuEntrega = false;
  pedidoEntregue = false;

  constructor(
    private modalService: NgbModal,
    private pedidoService: PedidoService,
    private loginService: LoginService,
    private router: Router,
    route: ActivatedRoute,
  ) {
    route.params.subscribe(e => {
      this.codigoPedido = e.codigo;

      this.pedidoService
        .retornaInformacoesPedido(e.codigo)
        .subscribe(f => {
          this.pedido = f;
          this.getEstadosPedido();
          this.pedido.Itens = f.Itens.sort((a, b) => (a.NumeroItem > b.NumeroItem) ? 1 : -1);

          if (this.pedido.Estado != EstadoPedido.EntregaConcluida && this.pedido.Estado != EstadoPedido.EntregaCancelada) {
            this.interval = setInterval(this.verificaEstado.bind(this), 30000);
          }

          this.carregando = false;
        })
    });

    this.restaurante = loginService.lojaLogada;
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  verificaEstado() {
    const ref = this;
    this.pedidoService.retornaInformacoesPedido(this.codigoPedido)
      .subscribe(f => {
        this.pedido = f;
        this.getEstadosPedido();
        this.pedido.Itens = f.Itens.sort((a, b) => (a.NumeroItem > b.NumeroItem) ? 1 : -1);
      });
  }

  calculaDesconto(precoAtual, porcentagemDesconto) {
    return porcentagemDesconto ?
      precoAtual - precoAtual * (porcentagemDesconto / 100) :
      precoAtual;
  }

  abrirModalInformacoesRestaurante() {
    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalEntrarContatoRestauranteComponent);
    modalRef.componentInstance.restaurante = this.restaurante;

    modalRef.result.then((e) => {
      document.body.style.position = '';
    }).catch((e) => {
      document.body.style.position = '';
    });
  }

  getEstadosPedido() {
    this.pedidoEstaEnviado = this.pedido.Estado == EstadoPedido.Enviado
      || this.pedido.Estado == EstadoPedido.Andamento
      || this.pedido.Estado == EstadoPedido.Pronto
      || this.pedido.Estado == EstadoPedido.SaiuEntrega
      || this.pedido.Estado == EstadoPedido.EntregaConcluida
      || this.pedido.Estado == EstadoPedido.EntregaCancelada;

    this.pedidoEstaPreparando = this.pedido.Estado == EstadoPedido.Andamento
      || this.pedido.Estado == EstadoPedido.Pronto
      || this.pedido.Estado == EstadoPedido.SaiuEntrega
      || this.pedido.Estado == EstadoPedido.EntregaConcluida
      || this.pedido.Estado == EstadoPedido.EntregaCancelada;

    this.PedidoPronto = this.pedido.Estado == EstadoPedido.Pronto
      || this.pedido.Estado == EstadoPedido.SaiuEntrega
      || this.pedido.Estado == EstadoPedido.EntregaConcluida
      || this.pedido.Estado == EstadoPedido.EntregaCancelada; 

    this.pedidoSaiuEntrega = this.pedido.Estado == EstadoPedido.SaiuEntrega
      || this.pedido.Estado == EstadoPedido.EntregaConcluida
      || this.pedido.Estado == EstadoPedido.EntregaCancelada;
      
    this.pedidoEntregue = this.pedido.Estado == EstadoPedido.EntregaConcluida;
    this.pedidoEstaCancelado = this.pedido.Estado == EstadoPedido.EntregaCancelada;
  }

  get usuarioLogado() {
    if(this.loginService.consumidorLogadoToken) {
      return this.loginService.consumidorLogadoToken;
    } else {
      this.router.navigate(['/login']);
    }
  }

  get taxaEntrega() {
    return this.pedido.TaxaEntrega;
  }

  get total() {
    return this.pedido.Total;
  }

  get subTotal() {
    return this.pedido.SubTotal;
  }

  get itensCarrinho() {
    return this.pedido.Itens;
  }
}
