import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalCadastroEnderecoComponent } from '@app/_components/cliente/endereco-entrega/modal-cadastro-endereco/modal-cadastro-endereco.component';
import { ModalImagemComponent } from '@app/_components/compartilhados/modal-imagem/modal-imagem.component';
import { ModalMensagemComponent } from '@app/_components/compartilhados/modal-mensagem/modal-mensagem.component';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { MercadoriaContida } from '@app/_models/mercadoria-composta/mercadoria-contida';
import { DetalhesMercadoria } from '@app/_models/mercadoria/detalhes-mercadoria';
import { GrupoPreparoMercadoria } from '@app/_models/mercadoria/grupo-preparo-mercadoria';
import { PreparoMercadoria } from '@app/_models/mercadoria/preparo-mercadoria';
import { Variacao } from '@app/_models/variacao/variacao';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { LoginService } from '@app/_services/login/login.service';
import { MercadoriaCompostaService } from '@app/_services/mercadoria-composta/mercadoria-composta.service';
import { MercadoriaService } from '@app/_services/mercadoria/mercadoria.service';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { DetalhesPrecoComposta } from '@app/_models/mercadoria/detalhes-preco-composta';
import { DetalhesContida } from '@app/_models/mercadoria/detalhes-contidas';
import { ModalQrcodeComponent } from '../modal-qrcode/modal-qrcode.component';
import { IndoorService } from '@app/_services/indoor.service';
import Swal from 'sweetalert2';
import { MercadoriaComposta } from '@app/_models/mercadoria-composta/mercadoria.composta';

@Component({
  selector: 'app-modal-adicionar-carrinho',
  templateUrl: './modal-adicionar-carrinho.component.html',
  styleUrls: ['./modal-adicionar-carrinho.component.scss']
})
export class ModalAdicionarCarrinhoComponent implements OnInit {

  @Input() mercadoria: DetalhesMercadoria;
  @Input() restauranteAberto: boolean;
  @Input() proximoTurno: boolean;
  @Input() codigoMercadoriaSacola: boolean = null;

  restaurante: DetalhesLoja;
  quantidade: number = 1;
  variacoes: any[];
  grupoPreparos: GrupoPreparoMercadoria[];
  composicoes: MercadoriaContida[];
  carregando = true;
  preparosSelecionados: PreparoMercadoria[] = [];
  variacaoSelecionada: Variacao;
  composicoesSelecionadas: any[] = [];
  variacaoComposicoesSelecionadas: Variacao[] = [];
  preparosPorItemContido = new Map();
  variacoesPorItemContido = new Map();
  observacao: string;
  detalhesPrecoComposta: DetalhesPrecoComposta = new DetalhesPrecoComposta();
  precoComposta: any;
  precoCalculado: boolean = false;

  constructor(
    public sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,
    private sacolaService: SacolaService,
    private clienteService: ClienteService,
    private loginService: LoginService,
    private mercadoriaService: MercadoriaService,
    private mercadoriaCompostaService: MercadoriaCompostaService,
    private modalService: NgbModal,
    private indoorService: IndoorService,
  ) {
  }

  ngOnInit(): void {
    this.mercadoriaService
      .buscaImagemMercadoria(this.mercadoria.Codigo, this.mercadoria.CodBarras || '%20', "Adicional")
      .subscribe(imagens => {
        if (this.mercadoria.Imagens) {
          this.mercadoria.Imagens = this.mercadoria.Imagens.concat(imagens.value.slice(1));
        }
      });

    if (this.mercadoria.Imagens) {
      document.getElementById("footer").classList.add('com-imagens');
      document.getElementById("info").classList.add('com-imagens');
    } else {
      document.getElementById("footer").classList.add('sem-imagens');
      document.getElementById("info").classList.add('sem-imagens');
    }

    let requisicoes = [this.mercadoriaService.listaGrupoPreparosPorCodigoMercadoria(this.mercadoria.Codigo)];

    if (this.mercadoria.TipoItemVenda == "Com variação") {
      requisicoes.push(this.mercadoriaService.listaVariacoesMercadoria(this.mercadoria.Codigo, ' ', this.restaurante.CodTabelaPreco));
    }

    if (this.mercadoria.TipoItemVenda == "Composta") {
      requisicoes.push(this.mercadoriaCompostaService.listaDetalhesMercadoriaComposta(this.mercadoria.Codigo));
    }

    forkJoin(requisicoes)
      .subscribe(response => {
        this.listaGrupoPreparos(response);

        if (this.mercadoria.TipoItemVenda == "Com variação") {
          this.variacoes = response[1].value;
          this.carregando = false;
        }
        else if (this.mercadoria.TipoItemVenda == "Composta") {
          this.composicoes = response[1].value;
          this.buscaComposicoes();
        } else {
          this.carregando = false;
        }
      });
  }

  private buscaComposicoes() {
    this.calcularValorItensContidos(this.composicoes);

    if (this.composicoesSelecionadas.length <= 0) {
      this.composicoesSelecionadas = this.composicoes;
    }

    this.composicoes.forEach(itemContido => {
      var operacoes = [this.mercadoriaService.listaGrupoPreparosPorCodigoMercadoria(itemContido.CodBasicoContida)];

      if (!itemContido.CodBarrasContida || itemContido.CodBarrasContida == "" || itemContido.CodBasicoContida == itemContido.CodBarrasContida) {
        operacoes.push(this.mercadoriaService.listaVariacoesMercadoria(itemContido.CodBasicoContida, " ", this.restaurante.CodTabelaPreco));
      }

      forkJoin(operacoes).subscribe(respostas => {
        let preparos: GrupoPreparoMercadoria[] = respostas[0].value;

        preparos.forEach(g => {
          g.Preparo.forEach(p => {
            var preparoSelecionado = this.preparosSelecionados.filter(s => s.Codigo == p.Codigo && s.CodigoGrupoPreparo == g.Codigo)[0];

            p.Quantidade = preparoSelecionado ? preparoSelecionado.Quantidade : p.QuantidadeMinima ? p.QuantidadeMinima : 1;
            p.ValorPersonalizacao = preparoSelecionado ? preparoSelecionado.ValorPersonalizacao : '';
            p.CodigoGrupoPreparo = g.Codigo;
          });
        });

        this.preparosPorItemContido.set(itemContido.CodBasicoContida, preparos);

        if (respostas[1]) {
          const variacao = respostas[1];
          this.variacoesPorItemContido.set(itemContido.CodBasicoContida, variacao.value);
        }

        this.carregando = false;
      });
    });
  }

  private listaGrupoPreparos(response: any[]) {
    this.grupoPreparos = response[0].value;

    this.grupoPreparos.forEach(g => {
      g.Preparo.forEach(p => {
        var preparoSelecionado = this.preparosSelecionados.filter(s => s.Codigo == p.Codigo && s.CodigoGrupoPreparo == g.Codigo)[0];

        p.Quantidade = preparoSelecionado ? preparoSelecionado.Quantidade : p.QuantidadeMinima ? p.QuantidadeMinima : 1;
        p.ValorPersonalizacao = preparoSelecionado ? preparoSelecionado.ValorPersonalizacao : '';
        p.CodigoGrupoPreparo = g.Codigo;
      });
    });
  }

  mostrarImagem(index) {
    if (this.mercadoria.Imagens && this.mercadoria.Imagens.length > 0) {
      let imagemMercadoria = this.mercadoria.Imagens[index];
      let imagem = '';
      if (imagemMercadoria.ImagemBase64)
        imagem = "data:image/png;base64," + encodeURI(imagemMercadoria.ImagemBase64);
      else
        imagem = imagemMercadoria.Imagem;

      return this.sanitizer.bypassSecurityTrustStyle('url(\'' + imagem + '\')');
    }

    return "";
  }

  aumentarQuantidade() {
    ++this.quantidade;
  }

  diminuirQuantidade() {
    if (this.quantidade > 1) {
      --this.quantidade;
    }
  }

  aumentarQuantidadePreparo(preparo: PreparoMercadoria, grupoPreparo: GrupoPreparoMercadoria, indexAtualGrupoPreparo, last, indexAtualComposicao = null, codBasicoContida = null) {
    if (!preparo.Quantidade)
      preparo.Quantidade = 1;

    if(grupoPreparo != null && grupoPreparo?.QuantidadeMaxima != 999){
      var quantidadeTotal = this.quantidadeTotalPreparoPorGrupo(grupoPreparo, codBasicoContida);
      if(grupoPreparo.QuantidadeMaxima > 0 && grupoPreparo.QuantidadeMaxima >= quantidadeTotal + 1)
        preparo.Quantidade++;

      if((quantidadeTotal + 1) == grupoPreparo.QuantidadeMaxima)
        this.irParaProximoItem('grupoPreparo', indexAtualGrupoPreparo, indexAtualComposicao, last);
    } else {
      if (preparo.QuantidadeMaxima > 0 && preparo.QuantidadeMaxima >= preparo.Quantidade + 1)
        preparo.Quantidade++;
    }

    this.preparosSelecionados.filter(p => p.Codigo == preparo.Codigo && p.CodigoGrupoPreparo == preparo.CodigoGrupoPreparo)[0].Quantidade = preparo.Quantidade;
  }

  diminuirQuantidadePreparo(preparo: PreparoMercadoria) {
    if (preparo.QuantidadeMinima <= preparo.Quantidade - 1 && preparo.Quantidade - 1 > 0)
      preparo.Quantidade--;
    
    this.preparosSelecionados.filter(p => p.Codigo == preparo.Codigo && p.CodigoGrupoPreparo == preparo.CodigoGrupoPreparo)[0].Quantidade = preparo.Quantidade;
  }

  validaQuantidadePreparo(preparo: PreparoMercadoria, quantidade, grupoPreparo: GrupoPreparoMercadoria, codBasicoContida = null) {
    if(grupoPreparo != null){
      var quantidadeTotal = this.quantidadeTotalPreparoPorGrupo(grupoPreparo, codBasicoContida);
      if(grupoPreparo.QuantidadeMaxima > 0 && (quantidadeTotal + 1) > grupoPreparo.QuantidadeMaxima)
        preparo.Quantidade = preparo.Quantidade--;
    } else {
      if (preparo.QuantidadeMaxima > 0 && Number(quantidade) > preparo.QuantidadeMaxima)
        preparo.Quantidade = preparo.QuantidadeMaxima;
    }

    if (Number(quantidade) == 0 || Number(quantidade) < preparo.QuantidadeMinima)
      preparo.Quantidade = preparo.QuantidadeMinima ? preparo.QuantidadeMinima : 1;

    this.preparosSelecionados.filter(p => p.Codigo == preparo.Codigo && p.CodigoGrupoPreparo == preparo.CodigoGrupoPreparo)[0].Quantidade = preparo.Quantidade;
  }

  validaPersonalizacao(preparo: PreparoMercadoria) {
    if (preparo.TipoPersonalizacao == 'Number') {
      if (preparo.TamanhoMaximoCampoPersonalizacao > 0 && Number(preparo.ValorPersonalizacao) > preparo.TamanhoMaximoCampoPersonalizacao)
        preparo.ValorPersonalizacao = preparo.TamanhoMaximoCampoPersonalizacao

      if (Number(preparo.ValorPersonalizacao) < preparo.TamanhoMinimoCampoPersonalizacao)
        preparo.ValorPersonalizacao = '';
    }
    this.preparosSelecionados.filter(p => p.Codigo == preparo.Codigo && p.CodigoGrupoPreparo == preparo.CodigoGrupoPreparo)[0].ValorPersonalizacao = preparo.ValorPersonalizacao;
  }

  exibirQuantidade(preparo: PreparoMercadoria, codBasicoContida = null, codComposicao = null) {
    if (preparo.Quantidade === undefined){
      if (preparo.QuantidadeMinima > 0)
        preparo.Quantidade = preparo.QuantidadeMinima;
      else
        preparo.Quantidade = 1;
    }

    return this.preparoFoiSelecionado(preparo, codBasicoContida, codComposicao) && preparo.QuantidadeMaxima > 1;
  }

  abrirModalSelecaoOpcaoEntrega() {
    document.body.style.position = 'fixed';

    let opcoesModal: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
      scrollable: true
    };

    const modalRef = this.modalService.open(ModalCadastroEnderecoComponent, opcoesModal);
    
    modalRef.result.then((result) => {
      document.body.style.position = '';
    }).catch((error) => {
      document.body.style.position = '';
    });
  }

  get usuarioLogado() {
    return this.loginService.consumidorLogadoToken ? this.loginService.clienteEstaLogado : null;
  }

  get ehRetiraraNoLocal(){
    return this.clienteService.selecionarFormaRetirada != FormaRetiradaPedido.Entrega
  }

  adicionarAoCarrinho() {

    if (this.mostraMensagemErro()) {
      return;
    } else {
      if (this.validarPreparos()) {

        var pedidoNaMesa = this.indoor;

        if( pedidoNaMesa && this.restaurante.HabilitarComanda && !this.comanda ){
          document.body.style.position = 'fixed';

          const modalRef = this.modalService.open(ModalQrcodeComponent);
          modalRef.componentInstance.tipoLeitura = "Comanda";

          modalRef.result.then((e) => {
            
            document.body.style.position = '';
            
            if( this.comanda ) {
              this.finalizarAdicaoAoCarrinho();
            }

          }).catch((e) => {
            document.body.style.position = '';
          });
        } else {
          this.finalizarAdicaoAoCarrinho();
        }
      }
    }

    this.sacolaService.atualizarTaxaEntrega();
  }

  finalizarAdicaoAoCarrinho() {

    if(this.mercadoria.TipoItemVenda == "Composta"){
      this.calculaPrecoComposta().then(( mercadoria: DetalhesMercadoria) => {
        
        mercadoria.Codigo = this.mercadoria.Codigo;
        mercadoria.TipoItemVenda = this.mercadoria.TipoItemVenda;
        mercadoria.NomeCurto = this.mercadoria.NomeCurto;
        mercadoria.NomeLongo = this.mercadoria.NomeLongo;
        mercadoria.PrecoVenda = this.mercadoria.PrecoVenda;
        mercadoria.DescontoVenda = this.mercadoria.DescontoVenda;
        mercadoria.Atributos = this.mercadoria.Atributos;
    
        this.detalhesPrecoComposta.Arredondamento = this.restaurante.Arredondamento;
        this.detalhesPrecoComposta.CalculoPreco = this.restaurante.CalculoPreco;
        this.detalhesPrecoComposta.Composta = {
          CodBarras: this.mercadoria.Codigo,
          TipoItemVenda: this.mercadoria.TipoItemVenda,
          Quantidade: this.quantidade,
          Valor: this.mercadoria.PrecoVenda,
          DescontoValor: this.calculaDesconto(this.mercadoria.PrecoVenda, this.mercadoria.DescontoVenda),
          DescontoPorcentagem: this.mercadoria.DescontoVenda,
          ValorComDesconto: this.calculaValorComDesconto(this.mercadoria.PrecoVenda, this.mercadoria.DescontoVenda),
          Total: this.calculaValorComDesconto(this.mercadoria.PrecoVenda, this.mercadoria.DescontoVenda) * this.quantidade,
          RefCount: this.mercadoria.RefCount
        };
        this.detalhesPrecoComposta.Contidos = []; 

        this.composicoesSelecionadas.forEach(contida => {
          var detalhesContida = new DetalhesContida();

          detalhesContida.CodBarras = contida.CodBarrasContida;
          detalhesContida.TipoItemVenda = contida.TipoItemVenda;
          detalhesContida.Quantidade = contida.Quantidade * this.quantidade;
          detalhesContida.Valor = contida.PrecoVenda;
          detalhesContida.DescontoValor = this.calculaDesconto(contida.PrecoVenda, contida.Porcentagem);
          detalhesContida.DescontoPorcentagem = contida.Porcentagem;
          detalhesContida.ValorComDesconto = this.calculaValorComDesconto(contida.PrecoVenda, contida.Porcentagem);
          detalhesContida.Total = detalhesContida.ValorComDesconto * detalhesContida.Quantidade;
          detalhesContida.RefCount = contida.RefCount;

          this.detalhesPrecoComposta.Contidos.push(detalhesContida);
        });

        if (this.codigoMercadoriaSacola) {
          this.sacolaService.editarMercadoriaPedido(this.codigoMercadoriaSacola, mercadoria, this.quantidade, this.preparosSelecionados, this.variacaoSelecionada, this.composicoesSelecionadas, this.observacao);
        } else {
          this.sacolaService.adicionarMercadoriaPedido(mercadoria, this.quantidade, this.preparosSelecionados, this.variacaoSelecionada, this.composicoesSelecionadas, this.observacao);
        }
        if (!this.clienteService.enderecoSelecionadoSubject && !this.ehRetiraraNoLocal){
          this.abrirModalSelecaoOpcaoEntrega();
        }
      });
    } else {
      if (this.codigoMercadoriaSacola) {
        this.sacolaService.editarMercadoriaPedido(this.codigoMercadoriaSacola, this.mercadoria, this.quantidade, this.preparosSelecionados, this.variacaoSelecionada, this.composicoesSelecionadas, this.observacao);
      } else {
        this.sacolaService.adicionarMercadoriaPedido(this.mercadoria, this.quantidade, this.preparosSelecionados, this.variacaoSelecionada, this.composicoesSelecionadas, this.observacao);
      }
      if (!this.clienteService.enderecoSelecionadoSubject && !this.ehRetiraraNoLocal){
        this.abrirModalSelecaoOpcaoEntrega();
      }
    }

    this.fecharModal();
  }

  private mostraMensagemErro() {

    if (!this.restauranteAberto) {
      Swal.fire({
        title: 'Estamos fechados',
        text: 'Mas você pode olhar o cardápio à vontade e voltar quando a loja estiver aberta.',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then(() => {
      })

      return true;
    }

    if (this.indoor && this.indoorService.distanciaPermitida == null) {

      Swal.fire({
        title: 'Permissão negada!',
        text: 'Ops! Não conseguimos acessar a localização do seu dispositivo. Por favor, ajuste as configurações de permissão do navegador para continuar.',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then(() => {
      })

      return true;
    }

    if (this.indoor && this.indoorService.distanciaPermitida == false) {
      
      Swal.fire({
        title: 'Distância inválida para pedido',
        text: 'Ops! Parece que você está muito longe do nosso estabelecimento para realizar um pedido.',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then(() => {
      })

      return true;
    }

    return false;
  }

  calculaPrecoComposta(){
    return new Promise((resolve, reject) => {
      var mercadoria = new DetalhesMercadoria();
      mercadoria.Codigo = this.mercadoria.Codigo;
      mercadoria.TipoItemVenda = this.mercadoria.TipoItemVenda;
      mercadoria.NomeCurto = this.mercadoria.NomeCurto;
      mercadoria.NomeLongo = this.mercadoria.NomeLongo;
      mercadoria.PrecoVenda = this.mercadoria.PrecoVenda;
      mercadoria.DescontoVenda = this.mercadoria.DescontoVenda;
      mercadoria.Atributos = this.mercadoria.Atributos;
    
      this.detalhesPrecoComposta.Arredondamento = this.restaurante.Arredondamento;
      this.detalhesPrecoComposta.CalculoPreco = this.restaurante.CalculoPreco;
      this.detalhesPrecoComposta.Composta = {
        CodBarras: this.mercadoria.Codigo,
        TipoItemVenda: this.mercadoria.TipoItemVenda,
        Quantidade: this.quantidade,
        Valor: this.mercadoria.PrecoVenda,
        DescontoValor: this.calculaDesconto(this.mercadoria.PrecoVenda, this.mercadoria.DescontoVenda),
        DescontoPorcentagem: this.mercadoria.DescontoVenda,
        ValorComDesconto: this.calculaValorComDesconto(this.mercadoria.PrecoVenda, this.mercadoria.DescontoVenda),
        Total: this.calculaValorComDesconto(this.mercadoria.PrecoVenda, this.mercadoria.DescontoVenda) * this.quantidade,
        RefCount: this.mercadoria.RefCount
      };
      this.detalhesPrecoComposta.Contidos = [];
  
      this.composicoesSelecionadas.forEach(contida => {
        var detalhesContida = new DetalhesContida();
  
        detalhesContida.CodBarras = contida.CodBarrasContida;
        detalhesContida.TipoItemVenda = contida.TipoItemVenda;
        detalhesContida.Quantidade = contida.Quantidade * this.quantidade;
        detalhesContida.Valor = contida.PrecoVenda;
        detalhesContida.DescontoValor = this.calculaDesconto(contida.PrecoVenda, contida.Porcentagem);
        detalhesContida.DescontoPorcentagem = contida.Porcentagem;
        detalhesContida.ValorComDesconto = this.calculaValorComDesconto(contida.PrecoVenda, contida.Porcentagem);
        detalhesContida.Total = detalhesContida.ValorComDesconto * detalhesContida.Quantidade;
        detalhesContida.RefCount = contida.RefCount;
  
        this.detalhesPrecoComposta.Contidos.push(detalhesContida);
      });
  
      this.mercadoriaCompostaService.calculaPrecoComposta(this.detalhesPrecoComposta).subscribe(e => {
        mercadoria.PrecoVenda = e.Composta.Valor;
        mercadoria.DescontoVenda = e.Composta.DescontoPorcentagem;
  
        e.Contidos.forEach(contida => {
          this.composicoesSelecionadas.filter(c => c.CodBarrasContida == contida.CodBarras)[0].DescontoPorcentagem = contida.DescontoPorcentagem;
          this.composicoesSelecionadas.filter(c => c.CodBarrasContida == contida.CodBarras)[0].DescontoValor = contida.DescontoValor;
          this.composicoesSelecionadas.filter(c => c.CodBarrasContida == contida.CodBarras)[0].PrecoVenda = contida.Valor;
          this.composicoesSelecionadas.filter(c => c.CodBarrasContida == contida.CodBarras)[0].Total = contida.Total;
        }); 
  
        this.precoComposta = mercadoria.PrecoVenda;
        resolve ( mercadoria );
      });
      
      resolve ( mercadoria );
    });
  }

  fecharModal() {
    this.activeModal.close();
  }

  adicionaPreparoCarrinho(selecionado: boolean, preparo: PreparoMercadoria, codBasicoContida: string = null, indexAtualGrupoPreparo: number, grupoPreparo: GrupoPreparoMercadoria, totalDeGruposPreparo: number, indexAtualComposicao: number, last: boolean) {
    preparo.CodBasicoContida = codBasicoContida;
    preparo.CodComposicao = indexAtualComposicao;
    
    if(grupoPreparo?.QuantidadeMaxima == 1){
      this.preparosSelecionados = this.preparosSelecionados.filter(p => p.CodigoGrupoPreparo !== preparo.CodigoGrupoPreparo);
    }

    if (!selecionado) {
      preparo.Quantidade = 1;
      preparo.ValorPersonalizacao = '';
      this.preparosSelecionados = this.preparosSelecionados.filter(p => p !== preparo);
    }

    if(selecionado){
      this.preparosSelecionados.push(preparo);
      var preparosSelecionadosDoGrupo = this.quantidadeTotalPreparoPorGrupo(grupoPreparo, codBasicoContida, indexAtualComposicao);
      if(preparosSelecionadosDoGrupo >= grupoPreparo?.QuantidadeMaxima && preparo.CodigoGrupoPreparo == grupoPreparo?.Codigo){
        if(indexAtualGrupoPreparo <= totalDeGruposPreparo)
          this.irParaProximoItem('grupoPreparo', indexAtualGrupoPreparo, indexAtualComposicao, last);
      }
    }
  }

  selecionaVariacao(variacao) {
    this.variacaoSelecionada = variacao;
  }

  calcularValorItensContidos(contidos) {
    const valorTotal = contidos
      .map(e => e.PrecoVenda * e.Quantidade)
      .reduce((total, preco) => total += preco);

    const porcentagemPorItem = new Map<string, number>();

    contidos.forEach(element => {
      let percentual = (element.PrecoVenda * element.Quantidade * 100) / valorTotal;
      porcentagemPorItem.set(element.Codigo, percentual);
    });

    contidos = contidos
      .map(e => {
        const precoItem = (this.mercadoria.PrecoVenda * porcentagemPorItem.get(e.Codigo)) / 100;
        e.PrecoCalculado = this.calculaValorComDesconto(precoItem, this.mercadoria.DescontoVenda);
      });
  }

  mostraPreparosDaComposicao(codBasicoContida) {
    if (this.preparosPorItemContido.has(codBasicoContida)) {
      return this.preparosPorItemContido.get(codBasicoContida);
    }
  }

  itemComposicaoPossuiPreparos(codBasicoContida) {
    return this.preparosPorItemContido.has(codBasicoContida) && this.preparosPorItemContido.get(codBasicoContida).length > 0;
  }

  mostraVariacoesDaComposicao(codBasicoContida) {
    if (this.variacoesPorItemContido.has(codBasicoContida)) {
      return this.variacoesPorItemContido.get(codBasicoContida);
    }
  }

  itemComposicaoPossuiVariacoes(codBasicoContida) {
    return this.variacoesPorItemContido.has(codBasicoContida) && this.variacoesPorItemContido.get(codBasicoContida).length > 0;
  }

  calculaValorComDesconto(precoAtual, porcentagemDesconto) {
    return porcentagemDesconto ?
      precoAtual - precoAtual * (porcentagemDesconto / 100) :
      precoAtual;
  }

  calculaDesconto(precoAtual, porcentagemDesconto) {
    return porcentagemDesconto ?
      precoAtual * (porcentagemDesconto / 100) :
      0;
  }

  selecionaVariacaoItemContido(composicao: MercadoriaContida, variacao: Variacao) {
    const indexComposicao = this.composicoesSelecionadas.findIndex(e => e.Codigo == composicao.Codigo);
    this.composicoesSelecionadas[indexComposicao].PrecoVenda = variacao.PrecoVenda != variacao.PrecoVendaComDesconto ? variacao.PrecoVendaComDesconto : variacao.PrecoVenda;
    this.calcularValorItensContidos(this.composicoesSelecionadas);
    this.composicoesSelecionadas[indexComposicao].IsVariacao = true;
    this.composicoesSelecionadas[indexComposicao].NomeComVariacao = `${composicao.NomeCurto} ${variacao.NomeComponente1} ${variacao.NomeComponente2}`;
    this.composicoesSelecionadas[indexComposicao].Variacao = variacao;
    this.composicoesSelecionadas[indexComposicao].CodBarrasContida = variacao.CodMercVar;
    this.variacaoComposicoesSelecionadas.push(variacao);
  }

  irParaProximoItem(tipo: string, indice: number, indexAtualComposicao: number, last: boolean): boolean {
    var id = ""

    if(indexAtualComposicao != null){
      if(last){
        const proximaComposicao = indexAtualComposicao + 1;
        id = `composicao_${proximaComposicao}${tipo}_0`;
      } else {
        const proximoItem = indice + 1;
        id = `composicao_${indexAtualComposicao}${tipo}_${proximoItem}`;
      }
    } else {
      const proximoItem = indice + 1;
      id = `${tipo}_${proximoItem}`;
    }

    let el = document.getElementById(id);
    if (el) {

      setTimeout(() => {
        el.scrollIntoView({ behavior: 'smooth' });
      }, 500);
      return true;
    }
    return false;
  }

  verificaSeTodosOsPreparosForamSelecionadosDoGrupo(grupoPreparo: GrupoPreparoMercadoria, codBasicoContida = null){
    var quantidadeTotal = this.quantidadeTotalPreparoPorGrupo(grupoPreparo, codBasicoContida);

    if(quantidadeTotal >= grupoPreparo.QuantidadeMaxima){
      return true;
    }

    return false;
  }

  processaLimitacoesGrupoPreparo(grupoPreparo: GrupoPreparoMercadoria, preparo: PreparoMercadoria, codBasicoContida: string = null){

    if(codBasicoContida){
      preparo.CodBasicoContida = codBasicoContida;
      if(this.preparosSelecionados.some(e => e.Codigo == preparo.Codigo && e.CodigoGrupoPreparo == grupoPreparo.Codigo && e.CodBasicoContida == preparo.CodBasicoContida))
        return false;
    } else {
      if(this.preparosSelecionados.some(e => e.Codigo == preparo.Codigo && e.CodigoGrupoPreparo == grupoPreparo.Codigo))
        return false;
    }

    var quantidadeTotal = this.quantidadeTotalPreparoPorGrupo(grupoPreparo, codBasicoContida);

    if(quantidadeTotal >= grupoPreparo.QuantidadeMaxima && preparo.CodigoGrupoPreparo == grupoPreparo.Codigo){
      return true;
    }

    return false;
  }

  quantidadeTotalPreparoPorGrupo(grupoPreparo, codBasicoContida = null, codComposicao = null){
    var quantidadeTotal = 0; 
    var preparosSelecionadosDoGrupo;

    if(codComposicao && codBasicoContida){
      preparosSelecionadosDoGrupo = this.preparosSelecionados.filter(c => c.CodigoGrupoPreparo == grupoPreparo.Codigo && c.CodBasicoContida == codBasicoContida && c.CodComposicao == codComposicao );
    } else {
      preparosSelecionadosDoGrupo = this.preparosSelecionados.filter(c => c.CodigoGrupoPreparo == grupoPreparo.Codigo );
    }
    
    preparosSelecionadosDoGrupo.forEach(preparo => {
      quantidadeTotal = quantidadeTotal + preparo.Quantidade
    });

    return quantidadeTotal;
  }

  get valorTotal() {
    let valorTotal = 0;
    if (this.variacaoSelecionada) {
      valorTotal += (this.variacaoSelecionada.PrecoVenda != this.variacaoSelecionada.PrecoVendaComDesconto ? this.variacaoSelecionada.PrecoVendaComDesconto : this.variacaoSelecionada.PrecoVenda) * this.quantidade;
    } else {
      valorTotal += this.calculaValorComDesconto(this.mercadoria.PrecoVenda, this.mercadoria.DescontoVenda) * this.quantidade;
    }

    //if(this.mercadoria.TipoItemVenda == "Composta" && !this.precoCalculado && this.podeAdicionarCarrinho){
    //  this.precoCalculado = true;
    //  var mercadoria = new DetalhesMercadoria;
    //  mercadoria = this.calculaPrecoComposta();
    //  valorTotal = mercadoria.PrecoVenda;
    //} else 
    if (this.mercadoria.TipoItemVenda == "Composta" && this.precoCalculado && this.podeAdicionarCarrinho){
      valorTotal = this.precoComposta;
    }
    
    if (this.preparosSelecionados && this.preparosSelecionados.length > 0) {
      this.preparosSelecionados.forEach(preparo => {
        let quantidadeDoPreparo = preparo.Quantidade !== undefined ? preparo.Quantidade : 1;
        valorTotal += preparo.PrecoVenda * quantidadeDoPreparo * this.quantidade;
      });
    }

    return valorTotal;
  }

  get podeAdicionarCarrinho() {
    let naoPode = this.carregando || (this.variacoes && this.variacoes.length > 0 && !this.variacaoSelecionada)

    let variacaoSelecionada = false;
    if (!this.composicoes || this.composicoes.length < 1) {
      variacaoSelecionada = true;
    }

    if (this.composicoesSelecionadas.some(e => e.TipoItemVenda == "Com variação")) {
      const composicoesComVariacao = this.composicoesSelecionadas
        .filter(element => element.TipoItemVenda == "Com variação");

      variacaoSelecionada = composicoesComVariacao.every(e => e.Variacao || e.CompVar1);
    } else {
      variacaoSelecionada = true;
    }

    naoPode = naoPode || !variacaoSelecionada;

    if (this?.grupoPreparos?.length > 0){
      this?.grupoPreparos?.forEach(gp => {
        if (gp.QuantidadeMinima > 0){
          var composicao = this.grupoPreparos.filter(g => g.Codigo == gp.Codigo);
          if(composicao.length > 1){
            composicao.forEach(gc => {
              var codBasicoContida = gc.Preparo[0]?.CodBasicoContida;
              if (this.preparosSelecionados.filter(p => p.CodigoGrupoPreparo == gp.Codigo && p.CodBasicoContida == codBasicoContida).length < gp.QuantidadeMinima){
                naoPode = true;
              }
            })
          } else {
            if (this.preparosSelecionados.filter(p => p.CodigoGrupoPreparo == gp.Codigo).length < gp.QuantidadeMinima){
              naoPode = true;
            }
          }
        }
      });
    }

    return !naoPode;
  }

  preparoFoiSelecionado(preparo: PreparoMercadoria, codBasicoContida: string = null, codComposicao: number = null) {
    if(codComposicao != null){
      return this.preparosSelecionados.some(e => e.Codigo == preparo.Codigo
        && e.CodigoGrupoPreparo == preparo.CodigoGrupoPreparo
        && e.CodBasicoContida == codBasicoContida
        && e.CodComposicao == codComposicao);
    } else if(codBasicoContida != null){
      return this.preparosSelecionados.some(e => e.Codigo == preparo.Codigo
        && e.CodigoGrupoPreparo == preparo.CodigoGrupoPreparo
        && e.CodBasicoContida == codBasicoContida);
    } else {
      return this.preparosSelecionados.some(e => e.Codigo == preparo.Codigo
        && e.CodigoGrupoPreparo == preparo.CodigoGrupoPreparo 
        && e.CodComposicao == null);
    }
  }

  abrirModalImagem(indiceImagem) {
    document.body.style.position = 'fixed';
    
    const modalRef = this.modalService.open(ModalImagemComponent);
    if (this.mercadoria.Imagens && this.mercadoria.Imagens.length > 0) {
      let imagem = ''

      if (this.mercadoria.Imagens[indiceImagem].ImagemBase64)
        imagem = "data:image/png;base64," + encodeURI(this.mercadoria.Imagens[indiceImagem].ImagemBase64);
      else
        imagem = this.mercadoria.Imagens[indiceImagem].Imagem

      modalRef.componentInstance.imagem = this.sanitizer.bypassSecurityTrustResourceUrl(imagem);
    }

    modalRef.result.then((e) => {
      document.body.style.position = '';
    }).catch((e) => {
      document.body.style.position = '';
    });
  }

  variacaoDoComposicaoFoiSelecionada(codItem, codVariacao) {
    this.precoCalculado = false;
    return this.composicoesSelecionadas.some(e => e.Codigo == codItem &&
      e.Variacao &&
      e.Variacao.CodMercVar == codVariacao);
  }


  validarPreparos(): boolean {
    let erros = '';

    this.grupoPreparos.forEach(g => {
      g.Preparo.forEach(p => {
        if (p.QuantidadeMinima > 0 && !this.preparosSelecionados.some(s => s.Codigo == p.Codigo))
          erros += `${p.Nome} é obrigatório.\n`;
        else if (p.QuantidadeMaxima > 0 && this.preparosSelecionados.some(s => s.Codigo == p.Codigo)) {
          const preparoSelecionado = this.preparosSelecionados.filter(s => s.Codigo == p.Codigo)[0];

          if (preparoSelecionado.Quantidade > preparoSelecionado.QuantidadeMaxima)
            erros += `${preparoSelecionado.Nome} permite no maximo: ${preparoSelecionado.QuantidadeMaxima}.\n`;
        }
        else if (p.PermitePersonalizacao && this.preparosSelecionados.some(s => s.Codigo == p.Codigo)) {
          const preparoSelecionado = this.preparosSelecionados.filter(s => s.Codigo == p.Codigo)[0];
          const ValorPersonalizacao = preparoSelecionado.ValorPersonalizacao;

          if (preparoSelecionado.TipoPersonalizacao == 'Number') {
            if (preparoSelecionado.TamanhoMinimoCampoPersonalizacao > 0 && (!ValorPersonalizacao || ValorPersonalizacao <= 0)) {
              erros += `Por favor, informe o ${preparoSelecionado.Nome}.\n`
            }
            else if (preparoSelecionado.TamanhoMaximoCampoPersonalizacao > 0 &&
              (ValorPersonalizacao && ValorPersonalizacao > preparoSelecionado.TamanhoMaximoCampoPersonalizacao)) {
              erros += `${preparoSelecionado.Nome} valor máximo: ${preparoSelecionado.TamanhoMaximoCampoPersonalizacao}.\n`;
            }
          } else {
            if (preparoSelecionado.TamanhoMinimoCampoPersonalizacao > 0 && (!ValorPersonalizacao || (ValorPersonalizacao as string).length <= 0)) {
              erros += `Por favor, informe o ${preparoSelecionado.Nome}.\n`
            }
            else if (preparoSelecionado.TamanhoMaximoCampoPersonalizacao > 0 &&
              (ValorPersonalizacao && (ValorPersonalizacao as string).length > preparoSelecionado.TamanhoMaximoCampoPersonalizacao)) {
              erros += `${preparoSelecionado.Nome} valor máximo: ${preparoSelecionado.TamanhoMaximoCampoPersonalizacao}.\n`;
            }
          }
        }

      });
    });

    if (erros != '') {
      document.body.style.position = 'fixed';

      const modalRef = this.modalService.open(ModalMensagemComponent);
      modalRef.componentInstance.titulo = 'Por favor, revise os seguintes itens:';
      modalRef.componentInstance.mensagem = erros;

      modalRef.result.then((e) => {
        document.body.style.position = '';
      }).catch((e) => {
        document.body.style.position = '';
      });

      return false;
    }

    return true;
  }

  get indoor() {
    return this.indoorService.indoor;
  }

  get comanda() {
    return this.indoorService.comanda;
  }

}
