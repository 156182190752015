<div class="container" *ngIf="!carregando && usuarioLogado">
  <div class="row justify-content-center">
    <div class="col-lg-10">
      <div class="box_order_form">
        <div class="head">
          <h3>Acompanhamento do pedido</h3>
        </div>
        <!-- /head -->
        <div class="main">
          <div class="row">
            <div class="col-lg-4 col-xl-4 col-sm-12">

              <mat-stepper orientation="vertical">

                <mat-step>
                </mat-step>

                <mat-step [editable]="false" [completed]="pedidoEstaEnviado"
                  *ngIf="(!pedidoEstaCancelado || pedidoEstaEnviado) && (!pedidoEstaEnviado || pedidoEstaEnviado && pedido.DataCriacao.toString().length > 10)">
                  <ng-template matStepLabel>Pedido enviado</ng-template>
                  <div *ngIf="pedidoEstaEnviado">{{ pedido.DataCriacao | date:'HH:mm'}}</div>
                </mat-step>

                <mat-step [editable]="false" [completed]="pedidoEstaPreparando"
                  *ngIf="(!pedidoEstaCancelado || pedidoEstaPreparando) && (!pedidoEstaPreparando || pedidoEstaPreparando && pedido.DataEmPreparo.toString().length > 10)">
                  <ng-template matStepLabel>Seu pedido está sendo preparado</ng-template>
                  <div *ngIf="pedidoEstaPreparando">{{ pedido.DataEmPreparo | date:'HH:mm' }}</div>
                </mat-step>

                <mat-step [editable]="false" [completed]="PedidoPronto"
                  *ngIf="(!pedidoEstaCancelado || PedidoPronto) && (!PedidoPronto || PedidoPronto && pedido.DataEmPreparo.toString().length > 10)">
                  <ng-template matStepLabel>Seu pedido está pronto</ng-template>
                  <div *ngIf="PedidoPronto">{{ pedido.DataPronto | date:'HH:mm' }}</div>
                </mat-step>

                <mat-step [editable]="false" [completed]="pedidoSaiuEntrega"
                  *ngIf="!pedido.Retira && (!pedidoEstaCancelado || pedidoSaiuEntrega) && (!pedidoSaiuEntrega || pedidoSaiuEntrega && pedido.DataEnviado.toString().length > 10)">
                  <ng-template matStepLabel>Seu pedido saiu para a entrega</ng-template>
                  <div *ngIf="pedidoSaiuEntrega">{{ pedido.DataEnviado | date:'HH:mm'}}</div>
                </mat-step>

                <mat-step [editable]="false" [completed]="pedidoEntregue"
                  *ngIf="(!pedidoEstaCancelado || pedidoEntregue) && (!pedidoEntregue || pedidoEntregue && pedido.DataRetornado.toString().length > 10)">
                  <ng-template matStepLabel>Pedido entregue</ng-template>
                  <div *ngIf="!pedidoEntregue">Previsão: {{ pedido.DataPrevisao | date:'HH:mm' }}</div>
                  <div *ngIf="pedidoEntregue">{{ pedido.DataRetornado | date:'HH:mm' }}</div>
                </mat-step>

                <mat-step [editable]="true" [completed]="pedidoEstaCancelado"
                  *ngIf="pedidoEstaCancelado">
                  <ng-template matStepLabel>Pedido cancelado</ng-template>
                  <div *ngIf="pedidoEstaCancelado">{{ pedido.DataCancelamento | date:'HH:mm' }}</div>
                </mat-step>

                <ng-template matStepperIcon="done">
                  <mat-icon class="icon-stepper material-icons-outlined">done</mat-icon>
                </ng-template>

                <ng-template matStepperIcon="edit">
                  <mat-icon class="icon-stepper material-icons-outlined">close</mat-icon>
                </ng-template>
              </mat-stepper>
            </div>

            <div class="col-lg-8 col-sm-12">
              <h2 style="text-align: center; margin-bottom: 45px;">Detalhes do pedido #{{ pedido.Referencia }}</h2>
              
              <div *ngIf="pedido.SenhaPedido && pedido.Retira" class="detalhes senha" style="margin-bottom: 10px;">
                <div class="titulo font-senha">
                  {{ 
                    "Senha para retirada: " + pedido.SenhaPedido
                  }}
                </div>
              </div>

              <div class="detalhes" style="margin-bottom: 10px;">
                <div *ngFor="let item of itensCarrinho" [ngClass]="item.Contem > 0 ? 'itens-contidos': 'itens'">
                  <div *ngIf="!item.variacao">
                    <div class="containerItem vertical-align-center">
                      <div [ngClass]="item.Contem > 0 ? 'contidos':'titulo'">
                        {{item.Quantidade}}x {{ item.NomeMercadoria}}
                      </div>
                      <span class="precoItem vertical-align-center" *ngIf="item.Contem == -1 || item.Contem == 0">
                        {{ item.Total | currency:'BRL'}}
                      </span>
                    </div>
                    <div *ngIf="item.Preparo" class="preparos">
                      <div>{{item.Preparo}}</div>
                    </div>
                  </div>

                  <div *ngIf="item.variacao">
                    <div class="titulo">
                      {{item.quantidade}}x
                      {{ 
                        item.mercadoria.NomeCurto + " - " + item.variacao.NomeComponente1 +  "  " + item.variacao.NomeComponente2 
                      }}
                    </div>
                    <span *ngIf="item.Contem == -1">
                      {{ 
                        (calculaDesconto(item.variacao.PrecoVenda, item.variacao.PrecoVendaComDesconto) * item.quantidade | currency:'BRL') 
                      }}
                    </span>

                    <div *ngIf="item.variacao.DescricaoComponente1" style="font-size: 12px; margin-left: 5px;">
                      <div style="width: 75%; display: inline-block;">
                        {{
                          item.variacao.DescricaoComponente1
                        }}
                      </div>
                    </div>
          
                    <div *ngIf="item.variacao.DescricaoComponente2" style="font-size: 12px; margin-left: 5px;">
                      <div style="width: 75%; display: inline-block;">
                        {{
                          item.variacao.DescricaoComponente2
                        }}
                      </div>
                    </div>
                  </div>

                  <div *ngIf="item.composicoes">
                    <div *ngFor="let composicao of item.composicoes" class="preparos contidos">
                      <div>
                        {{  
                          composicao.TipoItemVenda != "Com variação" 
                          ? (composicao.NomeCurto) 
                          : (composicao.NomeComVariacao || composicao.NomeCurto) + " " + composicao.CompVar1
                        }}
                      </div>
                    </div>
                  </div>

                  <div *ngFor="let preparo of item.preparos" class="preparos">
                    <div>{{preparo.NomePreparo}}</div>
                    <span>{{ (preparo.ValorMercadoriaPreparo > 0 ? '+ ' + (preparo.ValorMercadoriaPreparo | currency:'BRL') : '') }}</span>
                  </div>
                </div>
              </div>

              <div class="detalhes">
                <div class="subtitulo">Subtotal <span>{{ subTotal | currency:'BRL' }}</span></div>
                <div class="subtitulo">Taxa de entrega <span
                    *ngIf="taxaEntrega > 0">{{ taxaEntrega | currency:'BRL' }}</span><span *ngIf="taxaEntrega <= 0"
                    style="color: green;">Grátis</span></div>

                <div class="titulo">Total <span>{{ total | currency:'BRL' }}</span></div>
              </div>
              
              <div class="acoes">
                <button class="btn_1" (click)="abrirModalInformacoesRestaurante()">Falar com o estabelecimento</button>
              </div>
      
            </div>
          </div>
        </div>
      </div>
      <!-- /box_booking -->
    </div>
    <!-- /col -->
  </div>
  <!-- /row -->
</div>
