import { Component, OnInit } from '@angular/core';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-troco',
  templateUrl: './modal-troco.component.html',
  styleUrls: ['./modal-troco.component.scss']
})
export class ModalTrocoComponent implements OnInit {

  troco: number;
  mensagemErro: string;

  constructor(
    private sacolaService: SacolaService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void { }

  fecharModal() {
    if (this.troco < this.valorPedido) {
      this.mensagemErro = "O valor informado deve ser maior que o valor do pedido.";
    } else {
      this.activeModal.close(this.troco);
    }
  }

  get valorPedido() {
    return this.sacolaService.valorTotal + this.sacolaService.taxaEntrega;
  }
}
