import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-chamar-garcom',
  templateUrl: './chamar-garcom.component.html',
  styleUrls: ['./chamar-garcom.component.scss']
})
export class ChamarGarcomComponent implements OnInit {

    form: UntypedFormGroup;

    constructor(
      private formBuilder: UntypedFormBuilder,
    ) { 
      this.criaForm();
    }

    ngOnInit(): void { 
    }

    private criaForm() {
      this.form = this.formBuilder.group({
        CodMesaCartao: [undefined],
        Motivo: [undefined],
        Observacao: [undefined],
      });
    }
}

