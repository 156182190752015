import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BaseService extends ApiService {

  constructor(protected http: HttpClient) {
    super(http);
  }
}
