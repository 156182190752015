<div id="modal-dialog">
  <div class="modal_header">
		<h3>{{ titulo }}</h3>
	</div>
  
  <div class="modal_body" [class.centralizar]="centralizarTexto">
    <div style="margin-bottom: 10px; white-space: pre-line;">{{ mensagem }}</div>
    <div *ngIf="detalhes" style="margin-bottom: 10px;">{{ detalhes }}</div>

    <ng-container *ngTemplateOutlet="template"></ng-container>

    <div *ngIf="imagem" style="display: flex; justify-content: center;">
      <img style="width: 300px;" [src]="imagem">
    </div>

    <div class="botoes">
      <button *ngIf="mostrarBotaoCancelar" style="background-color: grey; margin-right: 10px;" class="btn_1" (click)="fecharModal(false)">{{ textoBotaoCancelar }}</button>
      <button *ngIf="mostrarBotaoConfirmar" class="btn_1" (click)="fecharModal(true)">{{ textoBotaoConfirmar }}</button>
    </div>
  </div>
</div>
