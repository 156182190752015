import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servidor-indisponivel',
  templateUrl: './servidor-indisponivel.component.html',
  styleUrls: ['./servidor-indisponivel.component.scss']
})
export class ServidorIndisponivelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
