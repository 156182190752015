import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GeraTonsCorService } from './_helpers/gera-tons-cor.service';
import { IndoorService } from './_services/indoor.service';
import { LoginService } from './_services/login/login.service';
import { Meta } from '@angular/platform-browser';
import { SacolaService } from './_services/sacola/sacola.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loja;
  rotaAtual: string;
  voltarAoTopo = false;
  posicaoInicialSacola = -1;

  constructor(
    private loginService: LoginService,
    private IndoorService: IndoorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: Meta,
    private indoorService: IndoorService,
    private sacolaService: SacolaService,
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.rotaAtual = event.url;

        if (this.IndoorService.indoor == null) {
          this.IndoorService.indoor = this.rotaAtual.includes('/indoor');
          if(this.rotaAtual.includes('/indoor')){
            this.sacolaService.esvaziarCarrinho();
          }
        }
      });

    this.loginService.loja.subscribe((lojaLogada) => {
      this.loja = lojaLogada;
      this.atualizarMetaTags();

      if (
        this.loja &&
        !document.documentElement.style.getPropertyValue(
          '--primary-color-light'
        )
      ) {
        let corPrimaria = this.loja.CorPrimaria;
        let corSecundaria = this.loja.CorSecundaria;
        let corPrimariaClara = new GeraTonsCorService().LightenDarkenColor(
          this.loja.CorPrimaria,
          180
        );
        let corPrimariaEscura = new GeraTonsCorService().LightenDarkenColor(
          this.loja.CorPrimaria,
          -40
        );
        let corSecundariaClara = new GeraTonsCorService().LightenDarkenColor(
          this.loja.CorSecundaria,
          180
        );
        let corSecundariaEscura = new GeraTonsCorService().LightenDarkenColor(
          this.loja.CorSecundaria,
          -40
        );
        
        document.documentElement.style.setProperty(
          '--primary-color',
          corPrimaria
        );
        document.documentElement.style.setProperty(
          '--primary-color-light',
          corPrimariaClara
        );
        document.documentElement.style.setProperty(
          '--primary-color-dark',
          corPrimariaEscura
        );
        document.documentElement.style.setProperty(
          '--secondary-color',
          corSecundaria
        );
        document.documentElement.style.setProperty(
          '--secondary-color-light',
          corSecundariaClara
        );
        document.documentElement.style.setProperty(
          '--secondary-color-dark',
          corSecundariaEscura
        );
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const posicaoPagina =
        window.scrollY  ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

    // Obtem a altura do nav (lista de categorias) para sinalizar corretamente o inicio do fixed.
    var alturaNav = 0;
    if (document?.getElementById("gruposNav")?.clientHeight != null)
      alturaNav = document.getElementById("gruposNav").clientHeight;

    var alturaPesquisa = 0;
    if(document?.getElementById("pesquisa")?.clientHeight != null)
      alturaPesquisa = document.getElementById("pesquisa").clientHeight;

    var alturaImagemCapa = 0
    if(document?.getElementById("imagemCapa")?.clientHeight != null)
      alturaImagemCapa = document.getElementById("imagemCapa").clientHeight;

    if(this.rotaAtual == '/'){
      if (alturaNav + alturaImagemCapa <= posicaoPagina) {
        this.voltarAoTopo = true;
      } else {
        this.voltarAoTopo = false;
      }
    } else {
      if (posicaoPagina > 100) {
        this.voltarAoTopo = true;
      } else {
        this.voltarAoTopo = false;
      }
    }
  }

  atualizarMetaTags(): void {
    this.metaService.updateTag({ name: 'description', content: `Faça seu pedido online agora mesmo no ${ this.loja?.Nome }! Acesse a loja através do Cardápio Digital da Nox Automação.`});
  }

  get mostrarNavBar() {
    return (
      this.rotaAtual &&
      !this.rotaAtual.includes('/cadastro') &&
      !this.rotaAtual.includes('/otp') &&
      !this.rotaAtual.includes('/login') &&
      !this.rotaAtual.includes('/servidor-indisponivel') &&
      !this.rotaAtual.includes('/Confirmacao/Consumidor') &&
      !this.rotaAtual.includes('/fila-espera')
    );
  }

  get mostrarFooter() {
    return (
      this.rotaAtual &&
      !this.rotaAtual.includes('/cadastro') &&
      !this.rotaAtual.includes('/otp') &&
      !this.rotaAtual.includes('/login') &&
      !this.rotaAtual.includes('/servidor-indisponivel') &&
      !this.rotaAtual.includes('/Confirmacao/Consumidor')
    );
  }

  get indoor() {
    return this.indoorService.indoor;
  }

  logout() {
    this.loginService.logout();
  }
}
